import { getApiInstance } from 'Online/utilities/getApiInstance';

export const getPolicyDocumentByArchiveKey = async (archiveKey: string, themeName: string): Promise<PolicyDocument> => {
	try {
		const documentAPI = await getApiInstance(themeName, true).get(
			`/api/v1/Document/GetByArchiveKey?archiveKey=${archiveKey}`,
		);
		return documentAPI.data;
	} catch (error) {
		return null;
	}
};
