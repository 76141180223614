const banks = [
	{
		name: 'Dragsholm Sparekasse',
		branches: [
			{
				PARTNERBANK_ID: '537 Dragsholm Sparekasse',
				BRANCH_NAME: 'Asnæs',
			},
			{
				PARTNERBANK_ID: '537 Dragsholm Sparekasse',
				BRANCH_NAME: 'Kalundborg',
			},
		],
	},
	{
		name: 'Faster/Oikos Andelskasse',
		branches: [
			{
				PARTNERBANK_ID: '5958 Faster/Oikos Andelskasse',
				BRANCH_NAME: 'København',
			},
			{
				PARTNERBANK_ID: '5958 Faster/Oikos Andelskasse',
				BRANCH_NAME: 'Astrup',
			},
		],
	},
	{
		name: 'Frørup Andelskasse',
		branches: [
			{
				PARTNERBANK_ID: '5960 Frørup Andelskasse',
				BRANCH_NAME: 'Frørup Andelskasse',
			},
		],
	},
	{
		name: 'Danske Andelskassers Bank',
		branches: [
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Årre',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Grimstrup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Skelund',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Hadsund',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Øster Hurup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Nordborg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Augustenborg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Gråsten',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Oksbøl',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Billum',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Alslev',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Bording',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Ilskov',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Ikast',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Rostrup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Frederiks',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Frederiks & Omegns Andelskasse',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Gislev',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Aarhus',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Hammershøj',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Randers',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Esjberg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Ørum',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Kundeservice',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Tandslet',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Høruphav',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Sønderborg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Sønderjylland Tandslet',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Sønderjylland Høruph',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Hvilsom',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Mejlby',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Hvam',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Aars',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Gredstedbro',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Bramming',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Ribe',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Egebæk-Hviding',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Tønder',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Harridslev',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Kværndrup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Kværndrup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Norddjurs',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Holstebro',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Linde & Omegn',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Vammen',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Rødding',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Løgstrup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Viborg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Næsbjerg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Brøndum',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Hobro',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Onsild',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Nordøstjylland',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Onsild',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Oue',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Arden',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Svendborg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Oure-Vejstrup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Outrup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Rømø',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Skovlund',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Ølgod',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Midtthy',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Stauning',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Ringe',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Odense',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Stenstrup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Egebjerg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Egebjerg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Tinglev',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Tinglev',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Sønderjylland Tinglev',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Sønderjylland Bylder',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Thorsager',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Tirstrup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Vorgod',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Kærup-Janderup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Hovedsæde',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Faaborg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Lysabild',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Rørbæk',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Knudby',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Lundeborg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Fjelstervang',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Starup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Esbjerg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Asserballe',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Agerbæk Glejbjerg',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Horne',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Thorstrup',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Varde',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Nordenskov',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Ansager',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Andelskassen',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Skjern',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Skjern Egnen',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Rækker Mølle',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Troldhede',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Tarm',
			},
			{
				PARTNERBANK_ID: '5999 Danske Andelskassers Bank',
				BRANCH_NAME: 'Egvad',
			},
		],
	},
	{
		name: 'Nordfyns Bank',
		branches: [
			{
				PARTNERBANK_ID: '6868 Nordfyns Bank',
				BRANCH_NAME: 'Bogense',
			},
			{
				PARTNERBANK_ID: '6868 Nordfyns Bank',
				BRANCH_NAME: 'Otterup',
			},
			{
				PARTNERBANK_ID: '6868 Nordfyns Bank',
				BRANCH_NAME: 'Søndersø',
			},
			{
				PARTNERBANK_ID: '6868 Nordfyns Bank',
				BRANCH_NAME: 'Kerteminde',
			},
			{
				PARTNERBANK_ID: '6868 Nordfyns Bank',
				BRANCH_NAME: 'Skibhus',
			},
			{
				PARTNERBANK_ID: '6868 Nordfyns Bank',
				BRANCH_NAME: 'Middelfart',
			},
			{
				PARTNERBANK_ID: '6868 Nordfyns Bank',
				BRANCH_NAME: 'Dalum',
			},
		],
	},
	{
		name: 'Hvidbjerg Bank',
		branches: [
			{
				PARTNERBANK_ID: '7500 Hvidbjerg Bank',
				BRANCH_NAME: 'Hvidbjerg',
			},
			{
				PARTNERBANK_ID: '7500 Hvidbjerg Bank',
				BRANCH_NAME: 'Hurup',
			},
			{
				PARTNERBANK_ID: '7500 Hvidbjerg Bank',
				BRANCH_NAME: 'Struer',
			},
			{
				PARTNERBANK_ID: '7500 Hvidbjerg Bank',
				BRANCH_NAME: 'Viborg',
			},
			{
				PARTNERBANK_ID: '7500 Hvidbjerg Bank',
				BRANCH_NAME: 'Holstebro',
			},
		],
	},
	{
		name: 'Landbobanken & Nordjyske',
		branches: [
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Hjørring',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Brønderslev',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Læsø',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Sæby',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Hjallerup',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'København',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Vejgaard',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Vestbjerg',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Nørresundby',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Aabybro',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Hasseris',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Skagen',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Aarhus',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Herning',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Holstebro',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Vildbjerg',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Kundecenter ',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Viborg',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Tarm',
			},
			{
				PARTNERBANK_ID: '7670 Landbobanken & Nordjyske',
				BRANCH_NAME: 'Frederikshavn',
			},
		],
	},
	{
		name: 'Vestjysk Bank',
		branches: [
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Give',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Ikast',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Hadsten',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Ølgod',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Kolding',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Brande',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Holstebro',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Struer',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Herning',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Viborg',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Esbjerg',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Udlands',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Ringkjøbing',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Videbæk',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Hvide Sande',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Lemvig',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Horsens',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Thyborøn',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Randers',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Aarhus',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Hedensted',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Juelsminde',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Rødekro',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Tinglev',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Farsø',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Jelling',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Vejle',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Grindsted',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Billund',
			},
			{
				PARTNERBANK_ID: '7730 Vestjysk Bank',
				BRANCH_NAME: 'Løgumkloster',
			},
		],
	},
	{
		name: 'Skjern Bank',
		branches: [
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Skjern Privat',
			},
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Varde',
			},
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Esbjerg',
			},
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Bramming',
			},
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Erhverv',
			},
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Landbrug',
			},
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Ølgod',
			},
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Hellerup',
			},
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Virum',
			},
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Hørsholm',
			},
			{
				PARTNERBANK_ID: '7780 Skjern Bank',
				BRANCH_NAME: 'Ribe',
			},
		],
	},
	{
		name: 'Kreditbanken',
		branches: [
			{
				PARTNERBANK_ID: '7930 Kreditbanken',
				BRANCH_NAME: 'Aabenraa - Hovedkontor',
			},
			{
				PARTNERBANK_ID: '7930 Kreditbanken',
				BRANCH_NAME: 'Sønderborg',
			},
			{
				PARTNERBANK_ID: '7930 Kreditbanken',
				BRANCH_NAME: 'Haderslev',
			},
			{
				PARTNERBANK_ID: '7930 Kreditbanken',
				BRANCH_NAME: 'Tønder',
			},
		],
	},
	{
		name: 'Nykredit',
		branches: [
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Amager',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Charlottenlund',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Esbjerg',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Frederiksberg C',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Frederikshavn',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Frederikssund',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Glostrup',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Greve',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Haderslev',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Helsingør',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Herlev',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Herning',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Hillerød',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Holbæk',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Horsens',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Hørsholm',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PB Kalvebod',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Kolding',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Kunderådgivning',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Kundeservice',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Køge',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Kgs. Lyngby',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Middelfart',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Nykøbing F',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Næstved',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Odense',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Outbound',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Personalebanken',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Randers',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Ringsted',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Roskilde',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Rønne',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Silkeborg',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Slagelse',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Svendborg',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Sønderborg',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Thisted',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Tårnby',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Vejle',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Viborg',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Viby',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Østerbro',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Aabenraa',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Aalborg',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Aarhus',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PBE Øst',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PB Herning',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PB Hillerød',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PB Kolding',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PB Kgs. Lyngby',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PB Næstved',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PB Odense',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PB Roskilde',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PB Aalborg',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PB Aarhus',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'PBE Vest',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'København',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'Leasing',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'SK København',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'SK Nord- og Midtjylland',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'SK Sjælland',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'SK Syddanmark',
			},
			{
				PARTNERBANK_ID: '8113 Nykredit',
				BRANCH_NAME: 'SK Aarhus',
			},
		],
	},
	{
		name: 'Andelskassen Fælleskassen',
		branches: [
			{
				PARTNERBANK_ID: '8411 Andelskassen Fælleskassen',
				BRANCH_NAME: 'Frederiksberg',
			},
		],
	},
	{
		name: 'Fynske Bank',
		branches: [
			{
				PARTNERBANK_ID: '844 Fynske Bank',
				BRANCH_NAME: 'Nyborg',
			},
			{
				PARTNERBANK_ID: '844 Fynske Bank',
				BRANCH_NAME: 'Svendborg',
			},
			{
				PARTNERBANK_ID: '844 Fynske Bank',
				BRANCH_NAME: 'Rudkøbing',
			},
			{
				PARTNERBANK_ID: '844 Fynske Bank',
				BRANCH_NAME: 'Assens',
			},
			{
				PARTNERBANK_ID: '844 Fynske Bank',
				BRANCH_NAME: 'Middelfart',
			},
			{
				PARTNERBANK_ID: '844 Fynske Bank',
				BRANCH_NAME: 'Assens',
			},
			{
				PARTNERBANK_ID: '844 Fynske Bank',
				BRANCH_NAME: 'Fredericia',
			},
			{
				PARTNERBANK_ID: '844 Fynske Bank',
				BRANCH_NAME: 'Børkop',
			},
			{
				PARTNERBANK_ID: '844 Fynske Bank',
				BRANCH_NAME: 'Kolding',
			},
			{
				PARTNERBANK_ID: '844 Fynske Bank',
				BRANCH_NAME: 'Odense',
			},
		],
	},
	{
		name: 'Rise Sparekasse',
		branches: [
			{
				PARTNERBANK_ID: '847 Rise Sparekasse',
				BRANCH_NAME: 'Rise',
			},
			{
				PARTNERBANK_ID: '847 Rise Sparekasse',
				BRANCH_NAME: 'Svendborg',
			},
			{
				PARTNERBANK_ID: '847 Rise Sparekasse',
				BRANCH_NAME: 'Haarby',
			},
			{
				PARTNERBANK_ID: '847 Rise Sparekasse',
				BRANCH_NAME: 'Faaborg',
			},
		],
	},
	{
		name: 'Sparekassen Thy',
		branches: [
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Aulum',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Frøstrup',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Hanstholm',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Herning',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Holstebro',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Hurup',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Hvidbjerg',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Nykøbing',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Struer',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Thisted',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Ulfborg',
			},
			{
				PARTNERBANK_ID: '9090 Sparekassen Thy',
				BRANCH_NAME: 'Viborg',
			},
		],
	},
	{
		name: 'Klim Sparekasse',
		branches: [
			{
				PARTNERBANK_ID: '9135 Klim Sparekasse',
				BRANCH_NAME: 'Fjerritslev',
			},
		],
	},
	{
		name: 'Sparekassen Balling',
		branches: [
			{
				PARTNERBANK_ID: '9312 Sparekassen Balling',
				BRANCH_NAME: 'Spøttrup/Oddense',
			},
			{
				PARTNERBANK_ID: '9312 Sparekassen Balling',
				BRANCH_NAME: 'Skive',
			},
			{
				PARTNERBANK_ID: '9312 Sparekassen Balling',
				BRANCH_NAME: 'Spøttrup/Oddense',
			},
			{
				PARTNERBANK_ID: '9312 Sparekassen Balling',
				BRANCH_NAME: 'Roslev/Durup',
			},
			{
				PARTNERBANK_ID: '9312 Sparekassen Balling',
				BRANCH_NAME: 'Roslev/Jebjerg',
			},
			{
				PARTNERBANK_ID: '9312 Sparekassen Balling',
				BRANCH_NAME: 'Holstebro',
			},
			{
				PARTNERBANK_ID: '9312 Sparekassen Balling',
				BRANCH_NAME: 'Skive Erhverv',
			},
		],
	},
	{
		name: 'Sparekassen Kronjylland',
		branches: [
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Stoholm Jyll',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Hammel',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Hinnerup',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Gjern',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Galten',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Odder',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Østbirk',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Brædstrup',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Ry',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Aarhus City',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Skt. Pauls',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Randers C',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Horsens',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Silkeborg',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Horsens',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Frederiksberg',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Herning',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'København V',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Kongens Lyngby',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Holbæk',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Kolding',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Esbjerg',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Slagelse',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'personale ',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Aalborg',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Odense C',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Stoholm',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Skive',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Havndal',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Viborg',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Fårup',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Fårup',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Vestre',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Assens',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Mariager',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Auning',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Dronningborg',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Hadsten',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Ø. Tørslev',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Assentoft',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Nordre',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Vorup',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Randers SØ',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Mørke',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Ebeltoft',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Tved',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Aarhus N',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Spentrup',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Hadsten',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Gjerlev J.',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Grenaa',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Bjerringbro',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Aarhus N',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Egå',
			},
			{
				PARTNERBANK_ID: '9335 Sparekassen Kronjylland',
				BRANCH_NAME: 'Skanderborg',
			},
		],
	},
	{
		name: 'Rønde Sparekasse',
		branches: [
			{
				PARTNERBANK_ID: '9354 Rønde Sparekasse',
				BRANCH_NAME: 'Rønde',
			},
		],
	},
	{
		name: 'Spar Nord Bank',
		branches: [
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Amager',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Dragør',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Kongelundsvej',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Østerbro',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Hvidovre',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Aars',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Nykøbing Mors',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Kongens Lyngby',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Solrød Strand',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Korsør',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Hørsholm',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Lolland Falster',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Allerød',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Viborg',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Frederiksberg',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Herlev',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Hjørring',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Aarhus',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Frederikshavn',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Sæby',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Skagen',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Brønderslev',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Storkunde',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Aalborg Syd',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Holbæk',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'kundecenter',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Randers',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Helsingør',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Hjallerup',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Hirtshals',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Køge',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Løgstør',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Terndrup',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Lillebælt',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Hadsund',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Horsens',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Hillerød',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Odense',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Silkeborg',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Nyborg',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Vejgaard',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Vodskov',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Kolding',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Østeraa',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Hasseris',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Roskilde',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Nørresundby',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Vejle',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Støvring',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Svendborg',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Servicekald',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Esbjerg',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Slagelse',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'København',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Hobro',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Næstved',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Herning',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Haderslev',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Holstebro',
			},
			{
				PARTNERBANK_ID: '9380 Spar Nord Bank',
				BRANCH_NAME: 'Skive',
			},
		],
	},
	{
		name: 'Sparekassen for Nr. Nebel & Om',
		branches: [
			{
				PARTNERBANK_ID: '9682 Sparekassen for Nr. Nebel & Omegn',
				BRANCH_NAME: 'Nørre Nebel',
			},
			{
				PARTNERBANK_ID: '9682 Sparekassen for Nr. Nebel & Omegn',
				BRANCH_NAME: 'Varde',
			},
			{
				PARTNERBANK_ID: '9682 Sparekassen for Nr. Nebel & Omegn',
				BRANCH_NAME: 'Nyminde',
			},
			{
				PARTNERBANK_ID: '9682 Sparekassen for Nr. Nebel & Omegn',
				BRANCH_NAME: 'Outrup',
			},
		],
	},
	{
		name: 'Fanø Sparekasse',
		branches: [
			{
				PARTNERBANK_ID: '9684 Fanø Sparekasse',
				BRANCH_NAME: 'Fanø',
			},
			{
				PARTNERBANK_ID: '9684 Fanø Sparekasse',
				BRANCH_NAME: 'Sønderho',
			},
		],
	},
	{
		name: 'Frøs Sparekasse',
		branches: [
			{
				PARTNERBANK_ID: '9740 Frøs Sparekasse',
				BRANCH_NAME: 'Brørup',
			},
			{
				PARTNERBANK_ID: '9740 Frøs Sparekasse',
				BRANCH_NAME: 'Fredericia',
			},
			{
				PARTNERBANK_ID: '9740 Frøs Sparekasse',
				BRANCH_NAME: 'Vejle',
			},
			{
				PARTNERBANK_ID: '9740 Frøs Sparekasse',
				BRANCH_NAME: 'Sønderborg',
			},
			{
				PARTNERBANK_ID: '9740 Frøs Sparekasse',
				BRANCH_NAME: 'Aabenraa',
			},
			{
				PARTNERBANK_ID: '9740 Frøs Sparekasse',
				BRANCH_NAME: 'Kolding',
			},
			{
				PARTNERBANK_ID: '9740 Frøs Sparekasse',
				BRANCH_NAME: 'Esbjerg',
			},
			{
				PARTNERBANK_ID: 'Frøs Sparekasse',
				BRANCH_NAME: 'Haderslev',
			},
			{
				PARTNERBANK_ID: 'Frøs Sparekasse',
				BRANCH_NAME: 'Ribe',
			},
			{
				PARTNERBANK_ID: 'Frøs Sparekasse',
				BRANCH_NAME: 'Vejen',
			},
			{
				PARTNERBANK_ID: 'Frøs Sparekasse',
				BRANCH_NAME: 'Rødding Hovedkontor',
			},
			{
				PARTNERBANK_ID: 'Frøs Sparekasse',
				BRANCH_NAME: 'Personalebanken',
			},
		],
	},
	{
		name: 'PrivatSikring',
		branches: [
			{
				PARTNERBANK_ID: 'PrivatSikring ',
				BRANCH_NAME: 'Kundecenter',
			},
		],
	},
	{
		name: 'Arbejdernes Landsbank',
		branches: [
			{
				PARTNERBANK_ID: '5440-000',
				BRANCH_NAME: 'Lokal Erhverv Midt',
			},
			{
				PARTNERBANK_ID: '5441-000',
				BRANCH_NAME: 'Lokal Erhverv Syd',
			},
			{
				PARTNERBANK_ID: '5442-000',
				BRANCH_NAME: 'Lokal Erhverv Øst',
			},
			{
				PARTNERBANK_ID: '5443-000',
				BRANCH_NAME: 'Lokal Erhverv Nord',
			},
			{
				PARTNERBANK_ID: '5444-000',
				BRANCH_NAME: 'Lokal Erhverv København',
			},
			{
				PARTNERBANK_ID: '5445-000',
				BRANCH_NAME: 'Lokal Erhverv Fyn',
			},
			{
				PARTNERBANK_ID: '5301-000',
				BRANCH_NAME: 'City',
			},
			{
				PARTNERBANK_ID: '5303-000',
				BRANCH_NAME: 'Private Banking',
			},
			{
				PARTNERBANK_ID: '5309-000',
				BRANCH_NAME: 'Kundeservice/drift',
			},
			{
				PARTNERBANK_ID: '5313-000',
				BRANCH_NAME: 'Personalebank',
			},
			{
				PARTNERBANK_ID: '5319-000',
				BRANCH_NAME: 'Ringsted',
			},
			{
				PARTNERBANK_ID: '5320-000',
				BRANCH_NAME: 'København',
			},
			{
				PARTNERBANK_ID: '5321-000',
				BRANCH_NAME: 'Amager',
			},
			{
				PARTNERBANK_ID: '5324-000',
				BRANCH_NAME: 'Kastrup',
			},
			{
				PARTNERBANK_ID: '5325-000',
				BRANCH_NAME: 'Kongelundsvej',
			},
			{
				PARTNERBANK_ID: '5327-000',
				BRANCH_NAME: 'Valby',
			},
			{
				PARTNERBANK_ID: '5329-000',
				BRANCH_NAME: 'Rødovre',
			},
			{
				PARTNERBANK_ID: '5330-000',
				BRANCH_NAME: 'Vanløse',
			},
			{
				PARTNERBANK_ID: '5331-000',
				BRANCH_NAME: 'Brønshøj',
			},
			{
				PARTNERBANK_ID: '5332-000',
				BRANCH_NAME: 'Gladsaxe',
			},
			{
				PARTNERBANK_ID: '5333-000',
				BRANCH_NAME: 'Taastrup',
			},
			{
				PARTNERBANK_ID: '5336-000',
				BRANCH_NAME: 'Enghave Plads',
			},
			{
				PARTNERBANK_ID: '5338-000',
				BRANCH_NAME: 'Frederiksberg',
			},
			{
				PARTNERBANK_ID: '5339-000',
				BRANCH_NAME: 'Ballerup',
			},
			{
				PARTNERBANK_ID: '5340-000',
				BRANCH_NAME: 'Bornholm',
			},
			{
				PARTNERBANK_ID: '5341-000',
				BRANCH_NAME: 'Herlev',
			},
			{
				PARTNERBANK_ID: '5342-000',
				BRANCH_NAME: 'Hvidovre',
			},
			{
				PARTNERBANK_ID: '5343-000',
				BRANCH_NAME: 'Horsens',
			},
			{
				PARTNERBANK_ID: '5344-000',
				BRANCH_NAME: 'Hjørring',
			},
			{
				PARTNERBANK_ID: '5346-000',
				BRANCH_NAME: 'Kolding',
			},
			{
				PARTNERBANK_ID: '5347-000',
				BRANCH_NAME: 'Silkeborg',
			},
			{
				PARTNERBANK_ID: '5348-000',
				BRANCH_NAME: 'Holstebro',
			},
			{
				PARTNERBANK_ID: '5349-000',
				BRANCH_NAME: 'Frederikshavn',
			},
			{
				PARTNERBANK_ID: '5350-000',
				BRANCH_NAME: 'Viborg',
			},
			{
				PARTNERBANK_ID: '5351-000',
				BRANCH_NAME: 'Skive',
			},
			{
				PARTNERBANK_ID: '5352-000',
				BRANCH_NAME: 'Fredericia',
			},
			{
				PARTNERBANK_ID: '5353-000',
				BRANCH_NAME: 'Aabenraa',
			},
			{
				PARTNERBANK_ID: '5355-000',
				BRANCH_NAME: 'Næstved',
			},
			{
				PARTNERBANK_ID: '5356-000',
				BRANCH_NAME: 'Slagelse',
			},
			{
				PARTNERBANK_ID: '5357-000',
				BRANCH_NAME: 'Helsingør',
			},
			{
				PARTNERBANK_ID: '5358-000',
				BRANCH_NAME: 'Hillerød',
			},
			{
				PARTNERBANK_ID: '5359-000',
				BRANCH_NAME: 'Nykøbing F',
			},
			{
				PARTNERBANK_ID: '5361-000',
				BRANCH_NAME: 'Nørrebro',
			},
			{
				PARTNERBANK_ID: '5363-000',
				BRANCH_NAME: 'Gug',
			},
			{
				PARTNERBANK_ID: '5365-000',
				BRANCH_NAME: 'Frederikssund',
			},
			{
				PARTNERBANK_ID: '5366-000',
				BRANCH_NAME: 'Lyngby',
			},
			{
				PARTNERBANK_ID: '5367-000',
				BRANCH_NAME: 'Kalundborg',
			},
			{
				PARTNERBANK_ID: '5368-000',
				BRANCH_NAME: 'Køge',
			},
			{
				PARTNERBANK_ID: '5370-000',
				BRANCH_NAME: 'Skanderborg',
			},
			{
				PARTNERBANK_ID: '5371-000',
				BRANCH_NAME: 'Sluseholmen',
			},
			{
				PARTNERBANK_ID: '5373-000',
				BRANCH_NAME: 'Holbæk',
			},
			{
				PARTNERBANK_ID: '5375-000',
				BRANCH_NAME: 'Stjernepladsen',
			},
			{
				PARTNERBANK_ID: '5378-000',
				BRANCH_NAME: 'Svendborg',
			},
			{
				PARTNERBANK_ID: '5379-000',
				BRANCH_NAME: 'Østerbro',
			},
			{
				PARTNERBANK_ID: '5380-000',
				BRANCH_NAME: 'Aalborg',
			},
			{
				PARTNERBANK_ID: '5381-000',
				BRANCH_NAME: 'Aarhus',
			},
			{
				PARTNERBANK_ID: '5383-000',
				BRANCH_NAME: 'Herning',
			},
			{
				PARTNERBANK_ID: '5384-000',
				BRANCH_NAME: 'Dalum',
			},
			{
				PARTNERBANK_ID: '5385-000',
				BRANCH_NAME: 'Esbjerg',
			},
			{
				PARTNERBANK_ID: '5386-000',
				BRANCH_NAME: 'Haderslev',
			},
			{
				PARTNERBANK_ID: '5387-000',
				BRANCH_NAME: 'Hunderup',
			},
			{
				PARTNERBANK_ID: '5388-000',
				BRANCH_NAME: 'Højstrup',
			},
			{
				PARTNERBANK_ID: '5389-000',
				BRANCH_NAME: 'Seden',
			},
			{
				PARTNERBANK_ID: '5391-000',
				BRANCH_NAME: 'Odense',
			},
			{
				PARTNERBANK_ID: '5393-000',
				BRANCH_NAME: 'Nyborg',
			},
			{
				PARTNERBANK_ID: '5394-000',
				BRANCH_NAME: 'Åbyhøj',
			},
			{
				PARTNERBANK_ID: '5395-000',
				BRANCH_NAME: 'Randers',
			},
			{
				PARTNERBANK_ID: '5396-000',
				BRANCH_NAME: 'Roskilde',
			},
			{
				PARTNERBANK_ID: '5397-000',
				BRANCH_NAME: 'Sønderborg',
			},
			{
				PARTNERBANK_ID: '5398-000',
				BRANCH_NAME: 'Vejle',
			},
		],
	},
	{
		name: 'Sparekassen Bredebro',
		branches: [
			{
				PARTNERBANK_ID: '9827 Sparekassen Bredebro ',
				BRANCH_NAME: 'Bredebro',
			},
			{
				PARTNERBANK_ID: '9828 Sparekassen Bredebro ',
				BRANCH_NAME: 'Tønder',
			},
			{
				PARTNERBANK_ID: '9839 Sparekassen Bredebro ',
				BRANCH_NAME: 'Skærbæk',
			},
			{
				PARTNERBANK_ID: '9844 Sparekassen Bredebro ',
				BRANCH_NAME: 'Toftlund',
			},
			{
				PARTNERBANK_ID: '9854 Sparekassen Bredebro ',
				BRANCH_NAME: 'Løgumkloster',
			},
			{
				PARTNERBANK_ID: '9855 Sparekassen Bredebro ',
				BRANCH_NAME: 'Tinglev',
			},
		],
	},
];

export default banks;
