import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Greetings } from 'Online/dictionaryItems';
import { useContext } from 'react';
import { LinkButton } from 'Shared';
import styles from './PolicyHeroBanner.module.scss';

export interface PolicyHeroBannerProps {
	firstName?: string;
	quotesCount?: number;
	quotesLink?: Link;
}

export const PolicyHeroBanner: React.FC<PolicyHeroBannerProps> = ({ firstName, quotesCount, quotesLink }) => {
	const dictionary = useContext(DictionaryContext);

	return (
		<div className={styles.PolicyHeroBanner}>
			<h2 className={styles.PolicyHeroBanner_title}>
				{_Greetings(dictionary, 'Hi')} {firstName}
			</h2>
			{quotesCount <= 0 && (
				<p className={styles.PolicyHeroBanner_subtitle}>{_Greetings(dictionary, 'PolicyOverviewGreeting')}</p>
			)}
			{quotesCount > 0 && (
				<>
					<div className={styles.PolicyHeroBanner_quotesContainer}>
						<p
							className={classNames(
								styles.PolicyHeroBanner_subtitle,
								styles.PolicyHeroBanner_subtitle___animation,
							)}
						>
							{_Greetings(dictionary, 'PolicyQuoteGreeting.YouHave')}
							<span className={styles.PolicyHeroBanner_subtitleAttention}> {quotesCount} </span>
							{_Greetings(dictionary, 'PolicyQuoteGreeting.NewPolicyQuotes')}
						</p>
					</div>
					{quotesLink && (
						<LinkButton
							className={styles.PolicyHeroBanner_button}
							style="primary"
							url={quotesLink.url}
							target={quotesLink.target}
						>
							{quotesLink.name}
						</LinkButton>
					)}
				</>
			)}
		</div>
	);
};
