import router from 'next/router';
import { ComponentProps, HeadingListItem } from 'Online/components';
import { PageProps } from 'Online/context/PageContext';
import { selectParty } from 'Online/store/slices/partySlice';
import React from 'react';
import { useSelector } from 'react-redux';
import { ProfileOverviewPage } from './ProfileOverviewPage';

export interface ProfileOverviewPageAPIProps extends PageProps {
	pageHeader: ComponentProps[];
	headingList: HeadingListItem[];
}

export const ProfileOverviewPageAPI: React.FC<ProfileOverviewPageAPIProps> = (props) => {
	const partyData = useSelector(selectParty);
	const activeParty = partyData?.partyObjects?.find((obj) => obj.id === partyData?.activeId);

	if (!activeParty) router.push('/');

	return <ProfileOverviewPage {...props} activeParty={activeParty} />;
};
