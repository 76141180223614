import classNames from 'classnames';
import { Component } from 'Online/components';
import { ComponentProps } from 'Online/components/Component';
import { useEffect, useState } from 'react';
import { Container, Grid, GridCell, Heading } from 'Shared';
import styles from './Footer.module.scss';

export interface FooterProps {
	className?: string;
	components?: ComponentProps[];
	heading?: string;
}

export const Footer: React.FC<FooterProps> = ({ className, components, heading }) => {
	const [secondaryFooterVisible, setSecondaryFooterVisible] = useState(false);
	useEffect(() => {
		const secondaryExists = components?.some((component: ComponentProps) => {
			return component?.settings?.properties?.primary === false;
		});
		setSecondaryFooterVisible(secondaryExists);
	}, [components]);

	return (
		<footer className={classNames(styles.Footer, className)} role="contentinfo">
			<Heading headingLevel="h6" id="primary-footerNavigation-heading" className="u-visually-hidden">
				{heading}
			</Heading>

			<div className={styles.Footer_primary}>
				<Container width="Large">
					<Grid className="u-grid--cell-h-no-padding" wrap>
						{components &&
							components.map((component: ComponentProps, index) => {
								return (
									component?.settings?.properties?.primary && (
										<GridCell desktopWidth="50" key={index} className={styles.Footer_cell}>
											<Component {...component} />
										</GridCell>
									)
								);
							})}
					</Grid>
				</Container>
			</div>

			{secondaryFooterVisible && (
				<div className={styles.Footer_secondary}>
					<Container width="Large">
						{components &&
							components.map((component: ComponentProps, index) => {
								return (
									!component?.settings?.properties?.primary && (
										<Component {...component} key={index} />
									)
								);
							})}
					</Container>
				</div>
			)}
		</footer>
	);
};
