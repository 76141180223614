import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _ProfileOverview } from 'Online/dictionaryItems/ProfileOverview';
import { useContext } from 'react';
import { FormfieldGroup, Heading } from 'Shared';
import { ProfileItem } from './ProfileItem';
import styles from './ProfileOverview.module.scss';

export interface ProfileOverviewProps {
	party: PartyDataItem;
}

export const ProfileOverview: React.FC<ProfileOverviewProps> = ({ party }) => {
	const dictionary = useContext(DictionaryContext);

	const { name, streetAddress, postalAreaAndRegion, cpr, phoneNo, email, idAlt } = party;
	return (
		<div className={classNames(styles.ProfileOverview)}>
			<Heading className={styles.ProfileOverview_heading} headingLevel={'h3'}>
				{_ProfileOverview(dictionary, 'YourProfile')}
			</Heading>

			<>
				<FormfieldGroup
					id="customerInfo"
					className={styles.ProfileOverview_formGroup}
					name="customerInfo"
					style="blocked-no-label"
				>
					<div className={styles.ProfileOverview_formContainer}>
						<ProfileItem
							heading={_ProfileOverview(dictionary, 'NameAndAddress')}
							content={[name, streetAddress, postalAreaAndRegion]}
						/>
						{phoneNo && (
							<ProfileItem
								className={styles.ProfileOverview_phoneNumber}
								heading={_ProfileOverview(dictionary, 'Telephone')}
								content={[phoneNo]}
							/>
						)}
						<ProfileItem heading={_ProfileOverview(dictionary, 'CPRNumber')} content={[cpr]} />
						{email && (
							<ProfileItem
								className={styles.ProfileOverview_email}
								heading={_ProfileOverview(dictionary, 'Email')}
								content={[email]}
							/>
						)}
					</div>
				</FormfieldGroup>

				<FormfieldGroup
					className={styles.ProfileOverview_formGroup}
					id="customerProfileInfo"
					name="customerProfileInfo"
					style="blocked-no-label"
				>
					<div className={styles.ProfileOverview_formContainer}>
						<ProfileItem
							heading={_ProfileOverview(dictionary, 'CustomerNumber')}
							content={[idAlt.toString()]}
						/>
					</div>
				</FormfieldGroup>
			</>
		</div>
	);
};
