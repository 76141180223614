import { DictionaryContext } from 'Online/context/Dictionary';
import { _Greetings } from 'Online/dictionaryItems';
import { setActiveId } from 'Online/store/slices/partySlice';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Container, HeroCard } from 'Shared';
import { CustomerItem, CustomerNumbersItem } from './CustomerNumersItem/CustomerNumbersItem';
import styles from './MultipleCustomerNumbers.module.scss';

export interface MultipleCustomerNumbersProps {
	customerItems: CustomerItem[];
}

export const MultipleCustomerNumbers: React.FC<MultipleCustomerNumbersProps> = ({ customerItems }) => {
	const dictionary = useContext(DictionaryContext);
	const dispatch = useDispatch();

	return (
		<>
			<HeroCard
				heading={_Greetings(dictionary, 'Hi') + ' ' + customerItems[0].name}
				teaser={_Greetings(dictionary, 'MultipleCustomerNoGreeting')}
			/>
			<Container width="Medium">
				<div className={styles.MultipleCustomerNumbers___container}>
					{customerItems.map((customerItem, index) => {
						const { id } = customerItem;

						return (
							<div key={index} onClick={() => dispatch(setActiveId(id))}>
								<CustomerNumbersItem multipleCustomerNumbersItem={customerItem} />
							</div>
						);
					})}
				</div>
			</Container>
		</>
	);
};
