import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Actions, _Units } from 'Online/dictionaryItems';
import { ReactComponent as InfoIcon } from 'Online/public/icons/icon-info.svg';
import { getPolicyContentItemById } from 'Online/utilities/getPolicyContentItemById';
import { useContext, useEffect, useState } from 'react';
import { ReactComponent as CheckmarkIcon } from 'Shared/public/icons/icon-checkmark.svg';
import { ReactComponent as DisabledIcon } from 'Shared/public/icons/icon-disabled.svg';
import { convertNumber } from 'Shared/utilities';
import styles from './ProductDetailsListItem.module.scss';

export interface ProductDetailsListItemProps {
	areaContent: ObjectDetail | Coverage;
	title?: string;
	value?: string;
	area: 'object' | 'coverage';
	state?: 'active' | 'pending';
	callback?: () => void;
	policyTypeContent?: PolicyCoverageItem[] | PolicyObjectDetails[];
	style?: 'emphasis';
}

export const ProductDetailsListItem: React.FC<ProductDetailsListItemProps> = ({
	callback,
	style,
	areaContent,
	area,
	policyTypeContent,
	state,
}) => {
	const dictionary = useContext(DictionaryContext);
	const [policyTypeItem, setPolicyTypeItem] = useState(null);

	useEffect(() => {
		const policyContentItem = getPolicyContentItemById(policyTypeContent, areaContent);
		setPolicyTypeItem(policyContentItem ? policyContentItem : areaContent);
	}, [policyTypeContent, areaContent]);

	const handleNumbers = (areaContent: ObjectDetail | Coverage) => {
		if ('unit' in areaContent) {
			if (areaContent.unit === 'DanishCrowns') return convertNumber(Number(areaContent.value));
			if (areaContent.unit === 'Kilometer') return convertNumber(Number(areaContent.value));
		}
		return areaContent.value;
	};
	const handleUnits = (areaContent: ObjectDetail | Coverage) => {
		return 'unit' in areaContent ? _Units(dictionary, areaContent.unit) : '';
	};

	return (
		<div className={classNames(styles.ProductDetailsListItem, styles[`ProductDetailsListItem___${style}`])}>
			<dt className={classNames(styles.ProductDetailsListItem_text, styles.ProductDetailsListItem_text___term)}>
				<div className={styles.ProductDetailsListItem_textWrapper}>
					<p>{policyTypeItem?.title}</p>
					{policyTypeItem?.description && (
						<button
							className={styles.ProductDetailsListItem_button}
							onClick={callback}
							aria-label={_Actions(dictionary, 'ReadMore')}
						>
							<InfoIcon />
						</button>
					)}
				</div>
			</dt>
			<dd
				className={classNames(
					styles.ProductDetailsListItem_text,
					styles.ProductDetailsListItem_text___description,
				)}
			>
				{area === 'object' && (
					<span className={styles.ProductDetailsListItem_textWrapper}>
						{handleNumbers(areaContent) + ' ' + handleUnits(areaContent)}
					</span>
				)}
				{area === 'coverage' && (
					<span className={styles.ProductDetailsListItem_textWrapper}>
						{state === 'active' ? (
							<CheckmarkIcon className={styles.ProductDetailsListItem_checkIcon} />
						) : (
							<DisabledIcon />
						)}
					</span>
				)}
			</dd>
		</div>
	);
};
