import router from 'next/router';
import { ComponentProps, HeadingListItem } from 'Online/components';
import { PageContext, PageProps } from 'Online/context/PageContext';
import { getDocuments, selectDocuments, setTiaEnrichedDocuments } from 'Online/store/slices/documentsSlice';
import { selectParty } from 'Online/store/slices/partySlice';
import { selectPolicies } from 'Online/store/slices/policySlice';
import { selectPolicyTypeContent } from 'Online/store/slices/policyTypeContentSlice';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentsOverviewPage } from './DocumentsOverviewPage';

export interface DocumentsOverviewPageAPIProps extends PageProps {
	pageHeader: ComponentProps[];
	headingList: HeadingListItem[];
	mainframeDownContent: ComponentProps[];
	policyIcon: Image;
	invoiceIcon: Image;
	letterIcon: Image;
}

export const DocumentsOverviewPageAPI: React.FC<DocumentsOverviewPageAPIProps> = (props) => {
	const page = useContext(PageContext);
	const themeName = page?.theme;
	const dispatch = useDispatch();
	const documentsData = useSelector(selectDocuments);
	const partyData = useSelector(selectParty);
	const policyData = useSelector(selectPolicies);
	const policyTypeContent = useSelector(selectPolicyTypeContent);
	const activeParty = partyData?.partyObjects?.find((obj) => obj.id === partyData?.activeId);

	useEffect(() => {
		dispatch(getDocuments(themeName));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (policyTypeContent != null && policyData?.portfolio?.length > 0 && documentsData?.documents?.length > 0) {
			const { portfolio } = policyData;
			const updatedDocuments: DocumentDataItem[] = [];
			documentsData.documents.map((item) => {
				const portfolioItem = portfolio?.find(
					(portfolioItem) => portfolioItem.policyNumber == item.relatedPolicy,
				);
				const policyTypeItem = policyTypeContent?.find(
					(policyTypeItem) => policyTypeItem?.type == portfolioItem?.type,
				);
				updatedDocuments.push({
					...item,
					description:
						item.relatedPolicy == portfolioItem?.policyNumber ? policyTypeItem?.title : item?.description,
				});
			});
			dispatch(setTiaEnrichedDocuments(updatedDocuments));
		}
	}, [
		dispatch,
		documentsData.documents,
		documentsData.documents?.length,
		policyData,
		policyData?.portfolio?.length,
		policyTypeContent,
	]);

	if (!activeParty) router.push('/');

	return <DocumentsOverviewPage documentsData={documentsData} {...props} />;
};
