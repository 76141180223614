import { Component, Footer, Layout, Main, ProfileOverview } from 'Online/components';
import { DictionaryContext } from 'Online/context/Dictionary';
import { PageContext } from 'Online/context/PageContext';
import { _Errors } from 'Online/dictionaryItems';
import React, { useContext } from 'react';
import { AttentionCard, Container, ScrollTransition } from 'Shared';
import { ProfileOverviewPageAPIProps } from './ProfileOverviewPageAPI';
export interface ProfileOverviewPageProps extends ProfileOverviewPageAPIProps {
	activeParty: PartyDataItem;
}

export const ProfileOverviewPage: React.FC<ProfileOverviewPageProps> = (props) => {
	const page = React.useContext(PageContext);
	const dictionary = useContext(DictionaryContext);

	return (
		<>
			<Layout {...props}>
				<Main
					header={props.pageHeader?.map((headerModule, index) => (
						<Component key={index} {...headerModule} />
					))}
				>
					<Container width="Medium">
						{props?.activeParty ? (
							<ScrollTransition>
								<ProfileOverview party={props?.activeParty} />
							</ScrollTransition>
						) : (
							<AttentionCard
								text={_Errors(dictionary, 'FetchError')}
								settings={{
									properties: {
										type: 'error',
										size: 'large',
									},
								}}
							/>
						)}
					</Container>
				</Main>
				{page?.footer && <Footer components={page.footer} />}
			</Layout>
		</>
	);
};
