import Script from 'next/script';
export interface TeliaChatProps {
	teliaChatUrl: string;
	teliaChatInterfaceName: string;
}

export const TeliaChat: React.FC<TeliaChatProps> = ({ teliaChatUrl, teliaChatInterfaceName }) => {
	return (
		<>
			<Script src={teliaChatUrl + '?version=1'} />
			<a href={teliaChatInterfaceName} />
		</>
	);
};
