import {
	ComponentProps,
	dispatchSleeknoteCustomerId,
	dispatchTagManagerEvent,
	HeadingListItem,
	PolicyUpsellFormProps,
} from 'Online/components';
import { PageContext, PageProps } from 'Online/context/PageContext';
import { getParty, selectParty } from 'Online/store/slices/partySlice';
import { getPersonalPoliciesById, selectPolicies } from 'Online/store/slices/policySlice';
import { updatePolicyTypeContent } from 'Online/store/slices/policyTypeContentSlice';
import { getChildrenById } from 'Online/utilities/getChildrenById';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';
import { PolicyOverviewPage } from './PolicyOverviewPage';

export interface PolicyOverviewPageAPIProps extends PageProps, PolicyUpsellFormProps {
	headingList: HeadingListItem[];
	policyDetailLink: Link;
	policyTypes: {
		id: number;
	};
	upsellTitle: string;
	upsellText: string;
	confirmationImage?: Image;
	confirmationTitle: string;
	confirmationText: string;
	customerContent: ComponentProps[];
	nonCustomerContent: ComponentProps[];
	pageSections: ComponentProps[];
	topContent: ComponentProps[];
	quotesLink: Link;
	policyReadMoreList: UmbracoContent<PolicyReadMoreLink>[];
	policyReadMoreLink: Link;
	pageHeader: ComponentProps[];
}

export const PolicyOverviewPageAPI: React.FC<PolicyOverviewPageAPIProps> = (props) => {
	const dispatch = useDispatch();
	const pageContext = useContext(PageContext);
	const themeName = pageContext.theme;
	const [policyTypeContent, setPolicyTypeContent] = useState<PolicyTypeItem[]>(null);
	const partyData = useSelector(selectParty);
	const policyData = useSelector(selectPolicies);

	useEffect(() => {
		dispatch(getParty(themeName));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (partyData?.status === 'success' && partyData.activeId !== null) {
			dispatch(getPersonalPoliciesById(themeName));
		}
	}, [dispatch, partyData, partyData.status, themeName]);

	const { data: umbracoPolicyTypeContent, error } = useSWR([props?.policyTypes.id], getChildrenById);
	// todo add error design
	if (error) console.log('ERROR', error);

	/** Todo add loading: !umbracoPolicyContent = loading */
	// if (!umbracoPolicyTypeContent) return null;

	const getObjectDetails = (objectDetailsAttributes) => {
		return objectDetailsAttributes.map((objectDetail) => {
			const id = objectDetail.documentType;
			const title = objectDetail.name;
			const { description } = objectDetail.properties ?? {};
			const policyObjectDetails: PolicyObjectDetails = {
				id,
				title,
				description,
			};
			return policyObjectDetails;
		});
	};

	const getCoverages = (coverageAttributes) => {
		return coverageAttributes.map((coverage) => {
			const id = coverage.documentType;
			const title = coverage.name;
			const { description, image } = coverage.properties ?? {};
			const coverageItem: PolicyCoverageItem = {
				id,
				title,
				description,
				image,
			};
			return coverageItem;
		});
	};

	useEffect(() => {
		if (umbracoPolicyTypeContent) {
			const typeContent = [];
			for (let i = 0; i < umbracoPolicyTypeContent.page.children.length; i++) {
				const policyTypes = umbracoPolicyTypeContent.page.children[i].children;

				const objectDetailsPosition = policyTypes.findIndex(
					(child) => child.documentType === 'policyObjectDetails',
				);
				const policyObjectDetails = policyTypes[objectDetailsPosition];
				const objectDetails: PolicyObjectDetails[] =
					policyObjectDetails?.children && getObjectDetails(policyObjectDetails.children);

				const coveragesPosition = policyTypes.findIndex((child) => child.documentType === 'coverages');
				const policyCoverage: PolicyCoverageItem[] =
					policyTypes[coveragesPosition]?.children && getCoverages(policyTypes[coveragesPosition].children);

				for (let i = 0; i < policyTypes.length; i++) {
					let coverages: PolicyCoverageItem[];
					const policyType = policyTypes[i];
					const { title } = policyType.properties;
					const icon =
						themeName === 'Privatsikring'
							? policyType.properties.iconPrivatsikring
							: themeName === 'Codan'
							? policyType.properties.iconCodan
							: null;
					const itemIsPolicyObjectDetails = policyType.documentType === 'policyObjectDetails';
					const itemIsCoverages = policyType.documentType === 'coverages';

					if (!itemIsPolicyObjectDetails && !itemIsCoverages) {
						coverages = policyCoverage;
						const policyTypeItem = {
							type: policyType.documentType,
							title,
							icon,
							coverages,
							objectDetails,
						};
						typeContent.push(policyTypeItem);
					}
				}
			}
			setPolicyTypeContent(typeContent);
		}
	}, [umbracoPolicyTypeContent, dispatch, themeName]);

	useEffect(() => {
		dispatch(updatePolicyTypeContent(policyTypeContent));
	}, [dispatch, policyTypeContent]);

	useEffect(() => {
		if (policyData.status === 'success') {
			dispatchTagManagerEvent('total policies', {
				amount: policyData.portfolio?.length,
				totalQuotes: policyData.quotesCount,
			});

			const activeParty = partyData?.partyObjects?.find((obj) => obj.id === partyData?.activeId);
			dispatchSleeknoteCustomerId(activeParty.idAlt);
		}
	}, [
		dispatch,
		partyData?.activeId,
		partyData?.partyObjects,
		policyData.portfolio?.length,
		policyData.quotesCount,
		policyData.status,
		policyTypeContent,
	]);

	return (
		<PolicyOverviewPage
			policyData={policyData}
			partyData={partyData}
			policyTypeContent={policyTypeContent}
			{...props}
		/>
	);
};
