import { axiosInstance as axios } from 'Online/utilities/axiosInstance';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getChildrenById = async (id: string): Promise<any> => {
	const query = `{
		page(id:${id}) {
			children
			{
				documentType
				properties
				children
				{
					documentType
					properties
					children
					{
						name
						documentType
						properties
					}
				}
			}
		}
	}`;

	const response = await axios({
		url: `/api/graphQLContent`,
		method: 'POST',
		data: {
			graphQuery: query,
		},
	});

	return response.data;
};
