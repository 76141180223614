import useSWR from 'swr';
import { Button } from 'Shared';
import { ArticleList, ArticleListItem } from 'Shared';
import { getArticlesById } from 'Online/utilities/getArticlesById';
import { useEffect, useState } from 'react';
import styles from './ArticleListAPI.module.scss';

export interface ArticleListAPIProps {
	count?: number;
	heading?: string;
	loadMoreButtonText: string;
	rootFolder?: BasePage;
}

export const ArticleListAPI: React.FC<ArticleListAPIProps> = ({ count, heading, loadMoreButtonText, rootFolder }) => {
	const [offset, setOffset] = useState(0);
	const [currentArticles, setCurrentArticles] = useState<ArticleListItem[]>(null);
	const [loadMore, setLoadMore] = useState(true);

	const { data: articles } = useSWR([rootFolder?.id, count + 1, offset], getArticlesById);

	const handleClick = () => {
		setOffset(count + offset);
	};

	const handleNewArticles = () => {
		return articles?.pageSearch?.pages.filter((article) => {
			const articleMatch = currentArticles.find((currentArticle) => {
				if (article.id === currentArticle.id) return true;
				return false;
			});
			if (!articleMatch) return article;
		});
	};

	const returnAllExceptLast = (array) => {
		return array.slice(0, count);
	};

	const handleLoadMore = () => {
		const articleQuery = articles?.pageSearch?.pages;
		if (articleQuery[count]) return;
		return setLoadMore(false);
	};

	useEffect(() => {
		if (!articles?.pageSearch?.pages) return;
		handleLoadMore();
		if (!currentArticles) setCurrentArticles(returnAllExceptLast(articles?.pageSearch?.pages));
		if (currentArticles) {
			const newArticles = handleNewArticles();
			const allArticles = currentArticles.concat(returnAllExceptLast(newArticles));
			setCurrentArticles(allArticles);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [articles]);

	if (!currentArticles) return null;
	return (
		<ArticleList heading={heading} articles={currentArticles}>
			{loadMore && (
				<div className={styles.ArticleListAPI_loadMore}>
					<Button className={styles.ArticleListAPI_loadMoreButton} style="secondary" onClick={handleClick}>
						{loadMoreButtonText}
					</Button>
				</div>
			)}
		</ArticleList>
	);
};
