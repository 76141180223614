import { Dictionary } from 'Online/context/Dictionary';

export type DocumentsOverviewDictionaries =
	| 'YourDocuments'
	| 'DownloadDocument'
	| 'Reset'
	| 'ShowDocuments'
	| 'DocumentType'
	| 'ShowAllDocumentsTypes'
	| 'PoliciesAndOffers'
	| 'OtherLetters'
	| 'Invoices'
	| 'Sorting'
	| 'Invoices'
	| 'NewFirst'
	| 'OldFirst'
	| 'All'
	| 'Earlier';

export const _DocumentsOverview = (dictionary: Partial<Dictionary>, item: DocumentsOverviewDictionaries): string => {
	switch (item) {
		case 'YourDocuments':
			return dictionary.getValue('DocumentsOverview.YourDocuments', null, 'Mine dokumenter');
		case 'DownloadDocument':
			return dictionary.getValue('DocumentsOverview.DownloadDocument', null, 'hent dokument');
		case 'ShowDocuments':
			return dictionary.getValue('DocumentsOverview.ShowDocuments', null, 'vis dokumenter');
		case 'Reset':
			return dictionary.getValue('DocumentsOverview.Reset', null, 'Nulstil');
		case 'DocumentType':
			return dictionary.getValue('DocumentsOverview.DocumentType', null, 'Dokument type');
		case 'ShowAllDocumentsTypes':
			return dictionary.getValue('DocumentsOverview.ShowAllDocumentsTypes', null, 'Vis alle dokument typer');
		case 'PoliciesAndOffers':
			return dictionary.getValue('DocumentsOverview.PoliciesAndOffers', null, 'Policer og tilbud');
		case 'OtherLetters':
			return dictionary.getValue('DocumentsOverview.OtherLetters', null, 'Øvrige Breve');
		case 'Invoices':
			return dictionary.getValue('DocumentsOverview.Invoices', null, 'Betalinger');
		case 'Sorting':
			return dictionary.getValue('DocumentsOverview.Sorting', null, 'Sortering');
		case 'NewFirst':
			return dictionary.getValue('DocumentsOverview.NewFirst', null, 'Nyeste først');
		case 'OldFirst':
			return dictionary.getValue('DocumentsOverview.OldFirst', null, 'Ældste først');
		case 'All':
			return dictionary.getValue('DocumentsOverview.All', null, 'Alle');
		case 'Earlier':
			return dictionary.getValue('DocumentsOverview.Earlier', null, 'Tidligere');
	}
};
