import classNames from 'classnames';
import { Heading, RichText } from 'Shared';
import { ReactComponent as LogoLightCodan } from 'Shared/public/logos/codan-logo-white.svg';
import { ReactComponent as LogoDarkCodan } from 'Shared/public/logos/codan-logo.svg';
import { ReactComponent as LogoLightESK } from 'Shared/public/logos/es-logo-negative.svg';
import { ReactComponent as LogoDarkESK } from 'Shared/public/logos/es-logo.svg';
import { ReactComponent as LogoLightColorPSK } from 'Shared/public/logos/ps-logo-white-color.svg';
import { ReactComponent as LogoLightPSK } from 'Shared/public/logos/ps-logo-white.svg';
import { ReactComponent as LogoDarkPSK } from 'Shared/public/logos/ps-logo.svg';
import styles from './Logo.module.scss';

export interface LogoProps {
	className?: string;
	description?: string;
	style: 'Light' | 'Dark';
	enforceDarkStyleForDesktop?: boolean;
	margined?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ className, description, style, enforceDarkStyleForDesktop, margined }) => {
	return (
		<div
			className={classNames(
				styles.Logo,
				styles[`Logo___${style}`],
				enforceDarkStyleForDesktop && styles.Logo___enforceDarkStyleForDesktop,
				margined && styles.Logo___margined,

				className,
			)}
		>
			<a className={styles.Logo_link} href="/">
				<Heading headingLevel="h6" className="u-visually-hidden">
					test
				</Heading>

				<div className={classNames(styles.Logo_asset, styles.Logo_asset___Psk)}>
					<LogoDarkPSK className={classNames(styles.Logo_icon, styles.Logo_icon___Dark)} />
					<LogoLightPSK className={classNames(styles.Logo_icon, styles.Logo_icon___Light)} />
				</div>
				<div className={classNames(styles.Logo_asset, styles.Logo_asset___Esk)}>
					<LogoDarkESK className={classNames(styles.Logo_icon, styles.Logo_icon___Dark)} />
					<LogoLightESK className={classNames(styles.Logo_icon, styles.Logo_icon___Light)} />
				</div>
				<div className={classNames(styles.Logo_asset, styles.Logo_asset___WorldElite)}>
					<LogoLightColorPSK className={styles.Logo_icon} />
				</div>
				<div className={classNames(styles.Logo_asset, styles.Logo_asset___Codan)}>
					<LogoDarkCodan className={classNames(styles.Logo_icon, styles.Logo_icon___Dark)} />
					<LogoLightCodan className={classNames(styles.Logo_icon, styles.Logo_icon___Light)} />
				</div>
			</a>
			{description && <RichText className={styles.Logo_description} style="large" content={description} />}
		</div>
	);
};
