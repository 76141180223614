import classNames from 'classnames';
import { Component } from 'Online/components';
import { ComponentProps } from 'Online/components/Component';
import { ErrorCodes } from 'Online/constants/errorCodes';
import { selectParty } from 'Online/store/slices/partySlice';
import { selectPolicies } from 'Online/store/slices/policySlice';
import { useSelector } from 'react-redux';
import { toKebabCase } from 'Shared/utilities';
import styles from './ContentSection.module.scss';

interface ContentSectionSettings {
	backgroundColor: string;
	restrictContent?: RestrictSegment;
	paddingTop: 'Small' | 'Large';
	paddingBottom: 'Small' | 'Large';
}

export interface ContentSectionProps {
	children?: React.ReactNode;
	title?: string;
	content?: ComponentProps[];
	header?: ComponentProps[];
	settings?: UmbracoSettings<ContentSectionSettings>;
}

export const ContentSection: React.FC<ContentSectionProps> = (props) => {
	const { backgroundColor, restrictContent, paddingTop, paddingBottom } = props?.settings?.properties ?? {};
	const partyData = useSelector(selectParty);
	const policyData = useSelector(selectPolicies);
	const { status: policyApiStatus, portfolio, customerStatus } = policyData ?? {};
	const { error: partyApiErrorCode } = partyData ?? {};
	const userIsNonCustomer =
		(policyApiStatus === 'success' && !(portfolio?.length > 0)) ||
		partyApiErrorCode === ErrorCodes.customerNotFound;
	const userIsPlusCustomer = policyApiStatus === 'success' && customerStatus?.isPlusCustomer;

	return (
		<section
			className={classNames({
				'u-content-section--background-light-gray': toKebabCase(backgroundColor) === 'light-gray', // Not moved to CSS Module, as _utilities.rte.scss depends on this class name.
				[styles.ContentSection___background]: backgroundColor,
				[styles[`ContentSection___background-${toKebabCase(backgroundColor)}`]]: backgroundColor,
				[styles.ContentSection___basePadding]: backgroundColor,
				[styles[`ContentSection___paddingTop${paddingTop}`]]: paddingTop,
				[styles[`ContentSection___paddingBottom${paddingBottom}`]]: paddingBottom,
			})}
		>
			{props.children}

			{props.content &&
				!restrictContent &&
				props.content.map((singleContent, index) => <Component key={index} {...singleContent} />)}
			{props.content &&
				userIsNonCustomer &&
				restrictContent === 'NonCustomer' &&
				props.content.map((singleContent, index) => <Component key={index} {...singleContent} />)}
			{props.content &&
				userIsPlusCustomer &&
				restrictContent === 'PlusCustomer' &&
				props.content.map((singleContent, index) => <Component key={index} {...singleContent} />)}
			{props.content &&
				!userIsNonCustomer &&
				restrictContent === 'Customer' &&
				props.content.map((singleContent, index) => <Component key={index} {...singleContent} />)}
		</section>
	);
};
