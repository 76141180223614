import { MasterCardTravelFormData, MasterCardUserFormData } from 'Online/components';
import { DictionaryContext } from 'Online/context/Dictionary';
import { PageProps } from 'Online/context/PageContext';
import { SiteContext } from 'Online/context/SiteContext';
import { _MasterCard } from 'Online/dictionaryItems';
import { useAppDispatch, useAppSelector } from 'Online/store/hooks';
import {
	getMasterCardConfig,
	getMasterCardPrice,
	selectMasterCardCalculationStatus,
	selectMasterCardConfig,
	selectMasterCardConfigStatus,
	selectMasterCardPaymentId,
	selectPriceSummary,
	selectTravelFormData,
	selectUserFormData,
	setPaymentId,
	setTravelFormData,
	setUserFormData,
} from 'Online/store/slices/mastercardSlice';
import { getMasterCardPaymentUrl, notifyMasterCardUser } from 'Online/utilities/mastercardQueries';
import React, { useContext, useEffect, useState } from 'react';
import { FactboxItemProps } from 'Shared';
import { toKebabCase } from 'Shared/utilities';
import { MasterCardPage } from './MasterCardPage';

export type MasterCardListType = 'travel' | 'user' | 'price';

export interface PaymentDetails {
	amount: number;
	cardName: string;
	date: Date;
	id: string;
	mailDidSend: boolean;
}
export interface MasterCardPageAPIProps extends PageProps {
	bannerImage: Image;
	conditionsHeading: string;
	conditionsText: string;
	description?: string;
	heading: string;
	image: Image;
	masterCardAttentionIcon: Image;
	masterCardCallIcon: Image;
	masterCardType: MasterCardType;
	paymentProviders: FactboxItemProps[];
	termsUrl: Link;
}

export const MasterCardPageAPI: React.FC<MasterCardPageAPIProps> = (props) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [paymentError, setPaymentError] = useState(false);

	const dispatch = useAppDispatch();
	const dictionary = useContext(DictionaryContext);
	const siteContext = useContext(SiteContext);
	const utilityIcons: UtilityIcons = siteContext.getSettings('utilityIcons') ?? {};
	const { errorIcon } = utilityIcons;
	const cardTypeGroup: MasterCardTypeGroup =
		props.masterCardType === 'MS' || props.masterCardType === 'MSB' || props.masterCardType === 'MSBN'
			? 'standard'
			: props.masterCardType === 'MG' || props.masterCardType === 'MGN' || props.masterCardType === 'MGB'
			? 'gold'
			: props.masterCardType === 'MP' ||
			  props.masterCardType === 'MPN' ||
			  props.masterCardType === 'MPB' ||
			  props.masterCardType === 'MPBN'
			? 'platinum'
			: props.masterCardType === 'WEM' || props.masterCardType === 'WEMN'
			? 'worldelite'
			: null;
	const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>(null);
	const cardConfig = useAppSelector((state) => selectMasterCardConfig(state, props.masterCardType));

	useEffect(() => {
		async function fetchConfig() {
			dispatch(getMasterCardConfig(props.masterCardType));
		}
		!cardConfig && fetchConfig();
	}, [dispatch, cardConfig]);

	const handleFormSubmit = (
		formData: MasterCardTravelFormData | MasterCardUserFormData,
		type: MasterCardListType,
	) => {
		if (type === 'travel') dispatch(setTravelFormData(formData as MasterCardTravelFormData));
		if (type === 'user') dispatch(setUserFormData(formData as MasterCardUserFormData));
	};

	const userFormData = useAppSelector(selectUserFormData);
	const priceSummary = useAppSelector(selectPriceSummary);
	const travelFormData = useAppSelector(selectTravelFormData);
	const configStatus = useAppSelector(selectMasterCardConfigStatus);
	const calculationStatus = useAppSelector(selectMasterCardCalculationStatus);
	const status = {
		configStatus,
		calculationStatus,
	};
	const paymentId = useAppSelector(selectMasterCardPaymentId);

	useEffect(() => {
		if (travelFormData) {
			dispatch(
				getMasterCardPrice({
					travelFormData,
					masterCardType: props.masterCardType,
				}),
			);
		}
	}, [dispatch, travelFormData, props.masterCardType]);

	const handlePayment = async () => {
		const cancelUrl = window.location.href.split('?')[0] + '?s=' + toKebabCase(_MasterCard(dictionary, 'Overview'));
		const returnUrl = window.location.href.split('?')[0] + '?s=' + toKebabCase(_MasterCard(dictionary, 'Receipt'));
		const paymentConfig = await getMasterCardPaymentUrl(
			props.masterCardType,
			returnUrl,
			props.termsUrl?.url,
			cancelUrl,
		);
		if ('paymentId' in paymentConfig) {
			dispatch(setPaymentId(paymentConfig.paymentId));
			if (paymentConfig?.hostedPaymentPageUrl != undefined) {
				window.location.href = paymentConfig.hostedPaymentPageUrl;
			}
		} else {
			setPaymentError(true);
		}
	};
	const handleEmail = async () => {
		const paymentDetails = await notifyMasterCardUser(props.masterCardType, paymentId);
		if ('id' in paymentDetails) {
			setPaymentDetails(paymentDetails);
		}
	};

	return (
		<MasterCardPage
			callbackPayment={handlePayment}
			callbackEmail={handleEmail}
			currentStep={currentStep}
			errorIcon={errorIcon}
			setCurrentStep={setCurrentStep}
			formSubmit={handleFormSubmit}
			paymentDetails={paymentDetails}
			paymentError={paymentError}
			paymentId={paymentId}
			cardType={props.masterCardType}
			cardTypeGroup={cardTypeGroup}
			priceSummary={priceSummary}
			masterCardConfig={cardConfig && cardConfig}
			travelFormData={travelFormData}
			userFormData={userFormData}
			status={status}
			{...props}
		/>
	);
};
