import Head from 'next/head';

export interface CookieBotProps {
	cookieBotUrl: string;
	cookieBotDomainGroupId: string;
}

export const CookieBot: React.FC<CookieBotProps> = (props) => {
	return (
		<Head>
			<script
				id="Cookiebot"
				src={props?.cookieBotUrl}
				data-cbid={props?.cookieBotDomainGroupId}
				type="text/javascript"
				async
			></script>
		</Head>
	);
};
