import { axiosInstance as axios } from 'Online/utilities/axiosInstance';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMasterCardsById = async (id: string, count?: number, offset?: number): Promise<any> => {
	const rootIdQuery = id ? `rootIds:[${id}]` : '';
	const limitQuery = count >= 0 ? `limit:${count}` : '';
	const offsetQuery = offset >= 0 ? `offset:${offset}` : '';

	const query = `{ pageSearch(documentType:["MasterCardPage"],${limitQuery}, ${offsetQuery}, ${rootIdQuery}, orderAsc:"createDate") {
		pages {
			name
			url
			id
			properties
		}
    }}`;
	const response = await axios({
		url: `/api/graphQLContent`,
		method: 'POST',
		data: {
			graphQuery: query,
		},
	});
	return response.data;
};
