import classNames from 'classnames';
import { PageContext } from 'Online/context/PageContext';
import { selectPolicies } from 'Online/store/slices/policySlice';
import { getStoreToken } from './getStoreToken';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Container, LinkButton } from 'Shared';
import styles from './PlusCustomerButton.module.scss';

interface PlusCustomerButtonSettings {
	type: 'primary' | 'secondary' | 'inactive' | 'sm';
	alignment?: 'left' | 'center' | 'right';
	containerWidth: ContainerWidth;
}

export interface PlusCustomerButtonProps {
	title: string;
	settings: UmbracoSettings<PlusCustomerButtonSettings>;
}

export const PlusCustomerButton: React.FC<PlusCustomerButtonProps> = ({ title, settings }) => {
	const customerStatus = useSelector(selectPolicies);
	const pageContext = useContext(PageContext);
	const themeName = pageContext.theme;

	const isPlusCustomer = customerStatus?.customerStatus?.isPlusCustomer;
	//const plusCustomerUrl = 'https://privatsikring.ucount.ro/?t=';
	const plusCustomerUrl = 'https://privatsikring.borgbigum.com/?t=';
	const { type = 'primary', alignment = 'left', containerWidth } = settings?.properties ?? {};

	const handleClick = async () => {
		const data = await getStoreToken(themeName);
		const { accessToken, exp } = data;
		if (isPlusCustomer && (accessToken !== null || Date.now() < Number(exp))) {
			window.location.href = plusCustomerUrl + accessToken;
		}
	};

	return (
		<Container
			width={containerWidth}
			className={classNames(styles.PlusCustomerButton, styles[`PlusCustomerButton___${alignment}`])}
		>
			<LinkButton style={type} onClick={handleClick}>
				{title}
			</LinkButton>
		</Container>
	);
};
