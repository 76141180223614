import { axiosInstance as axios } from 'Online/utilities/axiosInstance';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getArticlesById = async (id: string, count: number, offset: number): Promise<any> => {
	const rootIdQuery = id ? `rootIds:[${id}]` : '';
	const limitQuery = count === 0 ? '' : `limit:${count}`;
	const offsetQuery = offset >= 0 ? `offset:${offset}` : '';

	const query = `{ pageSearch(documentType:["articlePage"], ${limitQuery}, ${offsetQuery}, ${rootIdQuery}, orderDesc:"createDate") {
		pages {
			name
			url
			id
			properties
		}
    }}`;
	const response = await axios({
		url: `/api/graphQLContent`,
		method: 'POST',
		data: {
			graphQuery: query,
		},
	});
	return response.data;
};
