import classNames from 'classnames';
import { Status } from 'Online/components';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Units } from 'Online/dictionaryItems';
import { ReactComponent as ArrowRightIcon } from 'Online/public/icons/esk/icon_arrow_right.svg';
import { useContext } from 'react';
import { Image, LinkButton } from 'Shared';
import styles from './ProductOverviewItem.module.scss';

export type ProductOverviewItemProps = {
	className?: string;
	linkAsButton?: boolean;
	icon: Image;
	title: string;
	link: Link;
	description?: string;
	state?: ProductState;
	price?: string;
	pricePerMonth?: boolean;
	startDate?: DateYMDString;
	tag?: string;
	showArrow?: boolean;
	style?: 'Default' | 'Contrast';
};
export const ProductOverviewItem: React.VFC<ProductOverviewItemProps> = ({
	className,
	linkAsButton = false,
	icon,
	title,
	link,
	description,
	state,
	price,
	pricePerMonth,
	startDate,
	tag,
	showArrow,
	style,
}) => {
	const dictionary = useContext(DictionaryContext);
	const pricePerMonthText = `${_Units(dictionary, 'DanishCrowns')}/${_Units(dictionary, 'AbbreviatedMonth')}`;

	return (
		<div
			className={classNames(
				styles.ProductOverviewItem,
				{ [styles.ProductOverviewItem___grayBackground]: style === 'Contrast' },
				className,
			)}
		>
			<div className={styles.ProductOverviewItem_iconTitleTagContainer}>
				{icon?.url && (
					<div className={styles.ProductOverviewItem_icon} aria-hidden="true">
						<Image
							sizes="3rem"
							isCover={false}
							isCenter={true}
							url={icon.url}
							properties={icon?.properties}
						/>
					</div>
				)}
				<div className={styles.ProductOverviewItem_titleAndTagContainer}>
					<p className={styles.ProductOverviewItem_title}>{title}</p>
					{tag && <div className={styles.ProductOverviewItem_tag}>{tag}</div>}
				</div>
			</div>

			{description && (
				<div className={styles.ProductOverviewItem_info}>
					<p className={styles.ProductOverviewItem_infoText}>{description}</p>
				</div>
			)}

			{showArrow && <ArrowRightIcon />}

			{state && <Status startDate={startDate} state={state} style="contrast" />}

			{price && (
				<p className={styles.ProductOverviewItem_price}>
					{price}
					{pricePerMonth && (
						<span className={styles.ProductOverviewItem_priceDetails}>{pricePerMonthText}</span>
					)}
				</p>
			)}

			{linkAsButton && (
				<LinkButton
					className={styles.ProductOverviewItem_linkButton}
					style="secondary"
					url={link.url}
					target={link.target}
				>
					{link.name}
				</LinkButton>
			)}
		</div>
	);
};
