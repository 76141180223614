export const getPolicyTypeCount = (
	allPolicies: PortfolioItem[],
	currentType: PolicyType,
	currentPolicyNumber: string,
): number => {
	return (
		allPolicies
			.filter((item) => item.type == currentType)
			.findIndex((item) => item.policyNumber == currentPolicyNumber) + 1
	);
};
