import styles from './PolicyUpsellForm.module.scss';
import { Button, Form, FormfieldGroup, FormfieldCheckbox, FormfieldString } from 'Shared';
import { useContext, useState } from 'react';
import { DictionaryContext } from 'Online/context/Dictionary';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { _Errors } from 'Online/dictionaryItems';

export interface PolicyUpsellFormProps {
	closeModal?: () => void;
	personInfo: {
		personInfoTitle: string;
		phoneNumberLabel: string;
		phoneNumber?: string;
		emailLabel: string;
		email?: string;
	};
	submitCallBack?: () => void;
	submitText: string;
	upsellInterests: PolicyType[];
	upsellInterestsTitle: string;
}

interface FormData {
	interests: string;
	email: string;
	phone: string;
	bil: string;
	motorcykel: string;
	knallert: string;
	campingvogn: string;
	hus: string;
	rejse: string;
	hund: string;
}

export const PolicyUpsellForm: React.FC<PolicyUpsellFormProps> = ({
	personInfo,
	submitCallBack,
	submitText,
	upsellInterests,
	upsellInterestsTitle,
}) => {
	// TODO: Dictionary items
	const [allErros, setAllErros] = useState(0);
	const [errorSummary, setErrorSummary] = useState(0);

	const { register, handleSubmit, control } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = (data) => {
		setErrorSummary(0);
		console.log(`Thank for submitting, ${JSON.stringify(data)}`);
		submitCallBack();
	};
	const { errors } = useFormState({
		control,
	});

	const formGroupHasErrors = (formGroupName: string) => {
		const formGroupItems = allErros[formGroupName]?.ref.elements;
		if (formGroupItems) {
			for (let i = 0; i < formGroupItems.length; i++) {
				if (formGroupItems[i].checked) return false;
			}
		}
		if (errors[formGroupName]) return true;
		return false;
	};

	const onError = (data) => {
		const totalErrors = Object.keys(data).length;
		setErrorSummary(totalErrors);
		setAllErros(data);
	};

	const dictionary = useContext(DictionaryContext);
	const { personInfoTitle, phoneNumberLabel, phoneNumber, emailLabel, email } = personInfo ?? {};
	return (
		<Form onSubmit={handleSubmit(onSubmit, onError)} errorSummary={errorSummary}>
			<FormfieldGroup
				style="inline"
				id="policy-dialog-interests"
				name="interests"
				label={upsellInterestsTitle}
				register={register}
				state={formGroupHasErrors('interests') ? { hasError: true, required: true } : { required: true }}
				errorMessage={_Errors(dictionary, 'ChooseAtLeastOneField')}
			>
				{upsellInterests &&
					upsellInterests.map((interest, index) => (
						<FormfieldCheckbox
							id={`id-${interest}`}
							key={index}
							label={interest}
							name="interests"
							defaultValue={interest}
							state={formGroupHasErrors('interests') ? { hasError: true } : null}
							style="button"
							register={register}
						/>
					))}
			</FormfieldGroup>
			<FormfieldGroup
				style="inline-lg"
				id="policy-dialog-details"
				name="policy personal details"
				label={personInfoTitle}
			>
				<FormfieldString
					id="upsellPhoneInputID"
					name="phone"
					type="number"
					label={phoneNumberLabel}
					defaultValue={phoneNumber}
					className={styles.PolicyUpsellForm_input}
					register={register}
					state={errors.phone ? { hasError: true, required: true } : { required: true }}
				/>
				<FormfieldString
					id="upsellEmailInputID"
					name="email"
					type="email"
					defaultValue={email}
					label={emailLabel}
					className={styles.PolicyUpsellForm_input}
					register={register}
					state={errors.email ? { hasError: true, required: true } : { required: true }}
				/>
			</FormfieldGroup>
			<Button style="primary" type="submit" className={styles.PolicyUpsellForm_submit}>
				{submitText}
			</Button>
		</Form>
	);
};
