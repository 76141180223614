import classNames from 'classnames';
import { Component, ComponentProps } from 'Online/components';
import { Container } from 'Shared';
import { toKebabCase } from 'Shared/utilities';
import styles from './ColumnsLayout.module.scss';

export interface ColumnsLayoutProps {
	columns: ComponentProps[];
	settings?: {
		properties: {
			verticallyAlignColumnContent?: boolean;
			backgroundColor?: string;
			switchOrderForMobile?: boolean;
		};
	};
}

export const ColumnsLayout: React.FC<ColumnsLayoutProps> = ({ columns, settings }) => {
	const { verticallyAlignColumnContent, backgroundColor, switchOrderForMobile } = settings?.properties ?? {};

	return (
		<div
			className={classNames(
				styles.ColumnsLayout,
				switchOrderForMobile && styles.ColumnsLayout___switchOrderForMobile,
			)}
		>
			<Container>
				<div
					className={classNames(
						styles.ColumnsLayout_wrapper,
						backgroundColor && `u-header-section--background-${toKebabCase(backgroundColor)}`,
					)}
				>
					{columns.map((column, i) => (
						<div
							key={i}
							className={classNames(styles.ColumnsLayout_column, {
								[styles.ColumnsLayout_column___verticallyAlign]: verticallyAlignColumnContent,
							})}
						>
							<Component {...column} />
						</div>
					))}
				</div>
			</Container>
		</div>
	);
};
