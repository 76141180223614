import {
	ArticlePage,
	ContentPage,
	DocumentsOverviewPageAPI,
	LogOutPage,
	MasterCardPageAPI,
	PolicyDetailsPageAPI,
	PolicyOverviewPageAPI,
	ProfileOverviewPageAPI,
} from 'Online/components';
import { PageTypeProps } from 'Online/context/PageContext';

export const pages = {
	Home: ContentPage,
	Article: ArticlePage,
	Content: ContentPage,
	LogOut: LogOutPage,
	PolicyDetailsPage: PolicyDetailsPageAPI,
	PolicyOverviewPage: PolicyOverviewPageAPI,
	PolicyOverviewPageCustomHeader: PolicyOverviewPageAPI,
	MasterCardPage: MasterCardPageAPI,
	ProfileOverviewPage: ProfileOverviewPageAPI,
	DocumentsOverviewPage: DocumentsOverviewPageAPI,
};

export const PageType: React.FC<PageTypeProps> = (props) => {
	const page = props.page;
	const template = page.template;

	if (typeof pages[template] === 'undefined') {
		// TODO: Should it break in build?
		// No it should not break on build, but throw an error on request if the template does not exist
		return <div>{`Page type [${template}] is missing a React Component. Check /components/Page.tsx`}</div>;
	}

	const DynamicPageTemplate = pages[template];

	return <DynamicPageTemplate {...props.page.properties} />;
};
