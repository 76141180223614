import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Actions, _DocumentsOverview, _Errors } from 'Online/dictionaryItems';
import { ReactComponent as WarningIcon } from 'Online/public/icons/icon-warning.svg';
import { useContext, useEffect, useState } from 'react';
import { Heading, Modal, ScrollTransition } from 'Shared';
import { DocumentItem } from './DocumentItem';
import { DocumentsDateSlider } from './DocumentsDateSlider';
import { DocumentsFilter, FilterOptionType } from './DocumentsFilter';
import styles from './DocumentsOverview.module.scss';

export interface DocumentsOverviewProps {
	documents: DocumentDataItem[];
	policyIcon: Image;
	invoiceIcon: Image;
	letterIcon: Image;
}

export const DocumentsOverview: React.FC<DocumentsOverviewProps> = ({
	documents,
	policyIcon,
	invoiceIcon,
	letterIcon,
}) => {
	const dictionary = useContext(DictionaryContext);
	const [error, setError] = useState(false);
	const [currentDocuments, setCurrentDocuments] = useState<DocumentDataItem[]>(documents);
	const [sortFilterOptions, setSortFilterOptions] = useState<FilterOptionType>({
		sort: 'descending',
		documentCategory: 'ShowAllDocumentsTypes',
	});
	const [selectedYear, setSelectedYear] = useState<string>();
	const [yearsList, setYearsList] = useState<string[]>();
	const all = _DocumentsOverview(dictionary, 'All');
	const earlier = _DocumentsOverview(dictionary, 'Earlier');

	useEffect(() => {
		const allDates = documents?.map((item) => new Date(item.date).getFullYear().toString());

		let uniqueDates = [...allDates.filter((item, index) => allDates.indexOf(item) === index)];

		if (uniqueDates.length > 4) {
			uniqueDates = uniqueDates.slice(0, 4);
			uniqueDates.push(earlier);
		}

		setYearsList([all, ...uniqueDates]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (documents == null || documents.length < 0) return;

		if (selectedYear == all || selectedYear == null || selectedYear == '') {
			return setCurrentDocuments([...applyDocumentsSort(documents, sortFilterOptions)]);
		}

		if (selectedYear == earlier) {
			const selectedDate = new Date(yearsList[yearsList.length - 2]);
			const filteredDocuments = documents.filter(
				(item) => new Date(item.date).getFullYear() < selectedDate.getFullYear(),
			);
			return setCurrentDocuments([...applyDocumentsSort(filteredDocuments, sortFilterOptions)]);
		}

		const selectedDate = new Date(selectedYear);
		const filteredDocuments = documents.filter(
			(item) => new Date(item.date).getFullYear() == selectedDate.getFullYear(),
		);

		return setCurrentDocuments([...applyDocumentsSort(filteredDocuments, sortFilterOptions)]);
	}, [all, documents, earlier, selectedYear, sortFilterOptions, yearsList]);

	const applyDocumentsSort = (
		documentsData: DocumentDataItem[],
		sortOption: FilterOptionType,
	): DocumentDataItem[] => {
		const { sort, documentCategory } = sortOption;
		let sortedDocument: DocumentDataItem[];

		if (sort == 'descending') {
			sortedDocument = [...documentsData].sort((a, b) => {
				return new Date(b.date).getTime() - new Date(a.date).getTime();
			});
		} else if (sort == 'ascending')
			sortedDocument = [...documentsData].sort((a, b) => {
				return new Date(a.date).getTime() - new Date(b.date).getTime();
			});

		if (documentCategory !== 'ShowAllDocumentsTypes')
			return sortedDocument.filter((item) => item.category == documentCategory);

		return sortedDocument;
	};

	const getIconType = (category: string): Image => {
		if (category == 'Policer og tilbud') return policyIcon;
		if (category == 'Øvrige Breve') return letterIcon;
		if (category == 'Betalinger') return invoiceIcon;
		return;
	};

	return (
		<>
			<ScrollTransition>
				<div className={classNames(styles.DocumentsOverview)}>
					<Heading className={styles.DocumentsOverview_heading} headingLevel={'h3'}>
						{_DocumentsOverview(dictionary, 'YourDocuments')}
					</Heading>

					<div className={styles.DocumentsOverview_container}>
						<DocumentsFilter
							submitSortFilters={(options) => setSortFilterOptions(options)}
							clearSortFilters={() =>
								setSortFilterOptions({
									sort: 'descending',
									documentCategory: 'ShowAllDocumentsTypes',
								})
							}
						/>

						<div className={styles.DocumentsOverview_documents}>
							{yearsList && (
								<DocumentsDateSlider
									yearsList={yearsList}
									setSelectedYear={(year) => setSelectedYear(year)}
								/>
							)}

							{currentDocuments.map((item, index) => (
								<DocumentItem
									key={index}
									document={item}
									icon={getIconType(item.category)}
									setError={(error) => setError(error)}
								/>
							))}
						</div>
					</div>
				</div>
			</ScrollTransition>

			{error && (
				<Modal
					handleClose={() => setError(false)}
					style="simple"
					role="alert"
					iconSVG={
						<div className={styles.DocumentItem_errorModalIcon}>
							<WarningIcon className={styles.DocumentItem_warningIcon} />
						</div>
					}
					title={_Errors(dictionary, 'SomethingWentWrong')}
					className={styles.DocumentItem_errorModal}
					closeText={_Actions(dictionary, 'CancelAndClose')}
				>
					<div className={styles.DocumentItem_errorModalContent}>
						<p>{_Errors(dictionary, 'LinkToPolicyError')}</p>
						<p>{_Errors(dictionary, 'TryAgainLater')}</p>
					</div>
				</Modal>
			)}
		</>
	);
};
