import { DictionaryContext } from 'Online/context/Dictionary';
import { _Customer } from 'Online/dictionaryItems';
import { ReactComponent as ArrowIcon } from 'Online/public/icons/icon_arrow.svg';
import { useContext } from 'react';
import { Icon } from 'Shared';
import styles from './CustomerNumbersItem.module.scss';

export interface CustomerItem {
	id: number;
	idAlt?: number;
	name?: string;
	email?: string;
	bank?: string;
	bankBranch?: string;
}

export interface CustomerNumbersItemProps {
	multipleCustomerNumbersItem: CustomerItem;
}

export const CustomerNumbersItem: React.FC<CustomerNumbersItemProps> = ({
	multipleCustomerNumbersItem: { idAlt, bank, bankBranch },
}) => {
	const dictionary = useContext(DictionaryContext);

	return (
		<div className={styles.CardContainer}>
			<p className={styles.CardContainer___customerNumber}>
				{_Customer(dictionary, 'CustomerNumber')} {idAlt}
			</p>
			{
				bank && bankBranch && (
					<p className={styles.CardContainer___bank}>
						{bank}, {bankBranch}
					</p>
				)
				// TO ADD: When bank and bank branch is added, uncomment code below
				// <p className={styles.CardContaier___bank}>{_Customer(dictionary, 'CustomerNoBank')}</p>
			}
			<Icon size="lg" className={styles.CardContainer___icon}>
				<ArrowIcon />
			</Icon>
		</div>
	);
};
