import { useRouter } from 'next/router';
import { PolicyTotal, PolicyUpsellForm, PolicyUpsellFormProps } from 'Online/components';
import { ErrorCodes } from 'Online/constants/errorCodes';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Actions, _Errors } from 'Online/dictionaryItems';
import { useContext, useEffect, useState } from 'react';
import { AttentionCard, LinkButton, Modal, PageLoader, RichText } from 'Shared';
import { ActiveId } from 'store/slices/partySlice';
import { ProductOverview } from '../ProductOverview';
import styles from './PolicyOverview.module.scss';
import {
	mapPolicyReadMoreListToProductOverviewItems,
	mapPortfolioToProductOverviewItems,
} from './productOverviewMapper';

export interface HeadingListItem {
	content: { properties: { heading: string } };
	settings?: { properties: { restrictContent: RestrictSegment } };
}
export interface PolicyOverviewProps extends PolicyUpsellFormProps {
	policyData: PolicyData & StatusHandling;
	partyData: PartyData & StatusHandling & ActiveId;
	policyTypeContent: PolicyTypeItem[];
	headingList: HeadingListItem[];
	policyDetailLink: Link;
	policyReadMoreList: UmbracoContent<ProductListItem>[];
	policyReadMoreLink: Link;
	upsellTitle: string;
	upsellText: string;
	confirmationImage?: Image;
	confirmationTitle: string;
	confirmationText: string;
	userIsNonCustomer: boolean;
}

export const PolicyOverview: React.FC<PolicyOverviewProps> = ({
	headingList,
	personInfo,
	submitText,
	policyData,
	partyData,
	policyDetailLink,
	policyTypeContent,
	policyReadMoreList,
	policyReadMoreLink,
	upsellInterests,
	upsellInterestsTitle,
	upsellText,
	upsellTitle,
	confirmationImage,
	confirmationTitle,
	confirmationText,
	userIsNonCustomer,
}) => {
	const { portfolio, annualTotalPrice } = policyData ?? {};
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [submitConfirmed, setSubmitConfirmed] = useState(false);
	const router = useRouter();

	const dictionary = useContext(DictionaryContext);

	const updateQuery = (query: string) => {
		const basePath = router.asPath.split('?')[0];
		switch (query) {
			case 'false':
				return router.push(`${basePath}?quote=${query}`, undefined, { shallow: true });
			case 'true':
				return router.push(`${basePath}?quote=${query}`, undefined, { shallow: true });
			case null:
				return router.push(basePath, undefined, { shallow: true });
		}
	};

	if (submitConfirmed) {
		updateQuery('true');
	}

	useEffect(() => {
		if (router?.query?.quote === 'false' || router?.query?.quote === 'true') {
			setModalIsOpen(true);
		} else {
			setModalIsOpen(false);
		}
	}, [router?.query]);

	const segmentSelector: RestrictSegment = userIsNonCustomer ? 'NonCustomer' : 'Customer';
	const rteHeading = headingList.find((item) =>
		item?.settings?.properties?.restrictContent
			? item.settings.properties.restrictContent === segmentSelector
			: true,
	)?.content?.properties?.heading;

	return (
		<div className={styles.PolicyOverview}>
			{portfolio?.length > 0 && (
				<>
					<RichText content={rteHeading} style="petite" className={styles.PolicyOverview_title} />
					<PolicyTotal annualTotalPrice={annualTotalPrice} />

					<ProductOverview
						items={mapPortfolioToProductOverviewItems(portfolio, policyDetailLink, policyTypeContent)}
						pricePerMonth
					/>
					{/* 
						Should not be visible for now
						<PolicyCta portfolio={portfolio} openModal={() => updateQuery('false')} />
					*/}
					{modalIsOpen && (
						<Modal
							title={submitConfirmed ? confirmationTitle : upsellTitle}
							closeText={
								submitConfirmed
									? _Actions(dictionary, 'CloseWindow')
									: _Actions(dictionary, 'CancelAndClose')
							}
							handleClose={() => updateQuery(null)}
							style={submitConfirmed ? 'confirmation' : null}
							icon={submitConfirmed && confirmationImage && confirmationImage}
						>
							<RichText
								className={styles.PolicyOverview_richText}
								content={submitConfirmed ? confirmationText : upsellText}
								style={submitConfirmed ? 'center' : null}
							/>
							{!submitConfirmed && (
								<>
									<PolicyUpsellForm
										upsellInterests={upsellInterests}
										upsellInterestsTitle={upsellInterestsTitle}
										personInfo={personInfo}
										submitText={submitText}
										closeModal={() => updateQuery(null)}
										submitCallBack={() => setSubmitConfirmed(true)}
									/>
								</>
							)}
						</Modal>
					)}
				</>
			)}
			{((policyData?.status === 'success' && !(portfolio?.length > 0)) ||
				partyData?.error === ErrorCodes.customerNotFound) && (
				<>
					{policyReadMoreList && (
						<>
							<RichText content={rteHeading} style="petite" className={styles.PolicyOverview_title} />
							<ProductOverview
								items={mapPolicyReadMoreListToProductOverviewItems(policyReadMoreList)}
								linkAsButton
							/>
						</>
					)}

					{policyReadMoreLink && (
						<div className={styles.PolicyOverview_morePoliciesLink}>
							<LinkButton style="primary" url={policyReadMoreLink.url} target={policyReadMoreLink.target}>
								{policyReadMoreLink.name}
							</LinkButton>
						</div>
					)}
				</>
			)}
			{!policyData?.portfolio && policyData?.status === 'pending' && <PageLoader maxHeight="41rem" />}
			{!policyData?.portfolio &&
				policyData?.status === 'error' &&
				partyData?.error !== ErrorCodes.customerNotFound && (
					<AttentionCard
						text={_Errors(dictionary, 'FetchError')}
						settings={{
							properties: {
								type: 'error',
								size: 'large',
							},
						}}
					/>
				)}
		</div>
	);
};
