import { Component, Footer, Layout, Main } from 'Online/components';
import { ComponentProps } from 'Online/components/Component';
import { PageContext, PageProps } from 'Online/context/PageContext';
import React from 'react';
import { ArticleAuthor, ArticleAuthorProps, ArticleByline, ArticleDate, ArticleHeader, Container } from 'Shared';

export interface NewsPageProps extends PageProps {
	newsDate?: Date;
	newsKicker?: string;
	newsHeading?: React.ReactNode;
	newsTeaser?: string;
	newsAuthor?: ArticleAuthorProps;
	pageSections: ComponentProps[];
}

export const NewsPage: React.FC<NewsPageProps> = (props) => {
	const page = React.useContext(PageContext);

	return (
		<Layout {...props}>
			<Main>
				<Container width="Medium">
					<ArticleHeader teaser={props.newsTeaser} heading={props.newsHeading} kicker={props.newsKicker} />
					<ArticleDate date={props.newsDate} />
					{props.newsAuthor && <ArticleByline {...props.newsAuthor} />}
				</Container>
				{props.pageSections.map((sectionModule, index) => (
					<Component key={index} {...sectionModule} />
				))}
				<Container width="Medium">{props.newsAuthor && <ArticleAuthor {...props.newsAuthor} />}</Container>
			</Main>
			{page?.footer && <Footer components={page.footer} />}
		</Layout>
	);
};
