import { DictionaryContext } from 'Online/context/Dictionary';
import React, { useContext } from 'react';
import { Container, RichText } from 'Shared';
import { ProductOverview } from '../ProductOverview';
import styles from './ProductPromo.module.scss';

export interface ProductPromoProps {
	heading?: string;
	text?: string;
	link?: Link;
	products?: Array<
		UmbracoContent<{
			icon: Image;
			title: string;
			description: string;
			link: Link;
			showArrow: boolean;
			statutory: boolean;
			style: 'Default' | 'Contrast';
		}>
	>;
}

export const ProductPromo: React.FC<ProductPromoProps> = ({ heading, text, link, products }) => {
	const dictionary = useContext(DictionaryContext);
	const statutoryTag = dictionary.getValue('Products.StatutoryTag', undefined, '§ LOVPLIGTIG');

	const productsOverviewItems = products.map((product) => {
		const { statutory, ...rest } = product.content.properties;
		return {
			tag: statutory ? statutoryTag : undefined,
			...rest,
		};
	});

	return (
		<Container>
			<div className={styles.ProductPromo}>
				{heading ? <RichText content={heading} className={styles.ProductPromo_heading} /> : null}

				{text ? <RichText content={text} className={styles.ProductPromo_text} /> : null}

				{link ? (
					<a href={link.url} className={styles.ProductPromo_link}>
						{link.name}
					</a>
				) : null}

				{productsOverviewItems.length ? <ProductOverview items={productsOverviewItems} /> : null}
			</div>
		</Container>
	);
};
