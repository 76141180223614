import { Dictionary } from 'Online/context/Dictionary';

export type NavigationDictionaries = 'NavigationIsOpen' | 'NavigationIsClosed';

export const _Navigation = (dictionary: Partial<Dictionary>, item: NavigationDictionaries): string => {
	switch (item) {
		case 'NavigationIsOpen':
			return dictionary.getValue('Navigation.NavigationIsOpen', null, 'Navigationen er åben');
		case 'NavigationIsClosed':
			return dictionary.getValue('Navigation.NavigationIsClosed', null, 'Navigationen er lukket');
	}
};
