import { Dictionary } from 'Online/context/Dictionary';

export type ProfileOverviewDictionaries =
	| 'NameAndAddress'
	| 'CPRNumber'
	| 'CustomerNumber'
	| 'Email'
	| 'Telephone'
	| 'YourProfile';

export const _ProfileOverview = (dictionary: Partial<Dictionary>, item: ProfileOverviewDictionaries): string => {
	switch (item) {
		case 'NameAndAddress':
			return dictionary.getValue(
				'PolicyOverview.NameAndAddress',
				null,
				'Navn og adresse (hentes automatisk fra CPR-registret)',
			);

		case 'CPRNumber':
			return dictionary.getValue('ProfileOverview.CPRNumber', null, 'CPR-nummer');

		case 'CustomerNumber':
			return dictionary.getValue('ProfileOverview.CustomerNumber', null, 'Kundenummer');

		case 'YourProfile':
			return dictionary.getValue('ProfileOverview.YourProfile', null, 'Din profil');

		case 'Telephone':
			return dictionary.getValue('ProfileOverview.Telephone', null, 'Telefon');

		case 'Email':
			return dictionary.getValue('ProfileOverview.Email', null, 'E-mail adresse');
	}
};
