/* eslint-disable @typescript-eslint/no-empty-function */
import { Status } from 'Online/components';
import React from 'react';
import { Icon, Image, Slider } from 'Shared';
import styles from './ProductCarousel.module.scss';

export interface ProductCarouselProps {
	title?: string;
	portfolio: PortfolioItem[];
	policyTypeContent: PolicyTypeItem[];
	handleSlideChange?: (number) => void;
	currentPortfolioItem?: PortfolioItem;
}

export const ProductCarousel: React.FC<ProductCarouselProps> = ({
	portfolio,
	policyTypeContent,
	handleSlideChange = () => {},
	currentPortfolioItem = portfolio[0],
}) => {
	const selectPolicyTypeItem = (type: PolicyType) => {
		const policyTypePosition = policyTypeContent.findIndex((item) => {
			return item.type === type;
		});
		if (policyTypePosition >= 0) return policyTypeContent[policyTypePosition];
	};

	const initialSlide = portfolio.findIndex((x) => x.policyNumber === currentPortfolioItem.policyNumber);

	return (
		<div className={styles.ProductCarousel}>
			<div className={styles.ProductCarousel_content}>
				<Slider
					initialSlide={initialSlide}
					handleSlideChange={(index) => {
						handleSlideChange(index);
					}}
					slides={portfolio.map((item) => {
						const { icon, title } = (policyTypeContent && selectPolicyTypeItem(item.type)) ?? {};
						return (
							<div key={item.policyNumber} className={styles.PolicyCarousel_slide}>
								{icon?.url && (
									<div className={styles.PolicyCarousel_slideIcon} aria-hidden="true">
										<Icon className={styles.PolicyCarousel___iconSize}>
											<Image
												sizes="3rem"
												isCover={false}
												isCenter={true}
												url={icon.url}
												properties={icon.properties}
											/>
										</Icon>
									</div>
								)}

								{title && <h2 className={styles.PolicyItem_slideTitle}>{title}</h2>}
								{item.info && <p className={styles.PolicyItem_slideInfo}>{item.info}</p>}
								{item?.state && (
									<Status startDate={item.startDate} state={item.state} style="default" />
								)}
							</div>
						);
					})}
				/>
			</div>
		</div>
	);
};
