import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import { PageContext } from 'Online/context/PageContext';
import { _Services } from 'Online/dictionaryItems';
import { ReactComponent as ArrowDownIcon } from 'Online/public/icons/Icon_Chevron-down.svg';
import { useContext } from 'react';
import { Image } from 'Shared';
import styles from './ProfileCtaContent.module.scss';

export interface ProfileCtaContentProps {
	isActive?: boolean;
	profileIcon: Image;
	showArrow: boolean;
}

export const ProfileCtaContent: React.FC<ProfileCtaContentProps> = ({ profileIcon, isActive, showArrow }) => {
	const dictionary = useContext(DictionaryContext);
	const pageContext = useContext(PageContext);
	const themeName = pageContext.theme;

	return (
		<div className={classNames(styles.ProfileCtaContent, isActive && styles.ProfileCtaContent___active)}>
			{profileIcon && (
				<div className={styles.ProfileCtaContent_icon} aria-hidden="true">
					<Image
						className={styles.ProfileCtaContent_iconImage}
						url={profileIcon?.url}
						sizes="4rem"
						properties={profileIcon?.properties}
						isCover={false}
					/>
				</div>
			)}

			<div
				className={classNames(styles.ProfileCtaContent_title, !showArrow && styles.ProfileCtaContent_noArrow)}
				id="profile-links-title"
			>
				{(themeName === 'Codan' || themeName === 'Privatsikring') && (
					<p>{_Services(dictionary, `Mit${themeName}`)}</p>
				)}
				{showArrow && (
					<span className={styles.ProfileCtaContent_titleIcon} aria-hidden="true">
						<ArrowDownIcon />
					</span>
				)}
			</div>
		</div>
	);
};
