import { Header, Meta, MetaProps } from 'Online/components';
import { PageContext } from 'Online/context/PageContext';
import { SiteContext } from 'Online/context/SiteContext';
import {
	clearSiteAreas,
	selectCurrentSiteAreaName,
	setCurrentSiteArea,
	setSiteAreas,
} from 'Online/store/slices/siteAreaSlice';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { keyboardFocus } from 'Shared/utilities';
import styles from './Layout.module.scss';

export const Layout: React.FC<MetaProps> = (props) => {
	const [navigationItems, setNavigationItems] = useState(null);
	const dispatch = useDispatch();
	const siteContext = useContext(SiteContext);
	const pageContext = useContext(PageContext);
	const externalNavigation = siteContext.getSettings('externalNavigation') ?? {};
	const utilityIcons: UtilityIcons = siteContext.getSettings('utilityIcons') ?? {};
	const siteAreas: SiteAreas = siteContext.getSettings('siteAreas');
	const currentSiteArea = useSelector(selectCurrentSiteAreaName);
	const theme = pageContext.theme ? pageContext.theme : 'Default';

	const pageType = pageContext?.pageType;
	const rootPageExists = pageType?.root?.header.find((page) => page.id === pageType?.rootPage?.id);

	if (!rootPageExists) {
		pageType?.root?.header.unshift(pageType?.rootPage);
	}

	const userLinks = siteContext.getUserNavigationItems(pageType?.page, pageType?.root?.header);

	useEffect(() => {
		if (!siteAreas?.siteAreaItems) {
			dispatch(clearSiteAreas());
			return;
		}
		if (!currentSiteArea) {
			dispatch(setCurrentSiteArea(siteAreas.siteAreaItems[0].name));
			return;
		}
		dispatch(setSiteAreas(siteAreas.siteAreaItems));
	}, [dispatch, currentSiteArea, siteAreas]);

	useEffect(() => {
		let navItems: NavigationItem[];

		if (!currentSiteArea) navItems = siteContext.getNavigationItems(pageContext?.pageType?.page);
		if (currentSiteArea) navItems = siteContext.getNavigationItems(pageContext?.pageType?.page, currentSiteArea);

		setNavigationItems(navItems);
	}, [currentSiteArea, siteContext, pageContext?.pageType?.page]);

	keyboardFocus();
	return (
		<div className={styles.Layout}>
			<Meta {...props}>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href={`/${theme === 'Dark' ? 'Privatsikring' : theme}/apple-touch-icon.png`}
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href={`/${theme === 'Dark' ? 'Privatsikring' : theme}/favicon-32x32.png`}
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href={`/${theme === 'Dark' ? 'Privatsikring' : theme}/favicon-16x16.png`}
				/>
				<link rel="manifest" href={`/${theme === 'Dark' ? 'Privatsikring' : theme}/site.webmanifest`} />
				<link
					rel="mask-icon"
					href={`/${theme === 'Dark' ? 'Privatsikring' : theme}/safari-pinned-tab.svg" color="#5bbad5`}
				/>
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />
			</Meta>
			<Header
				navigationItems={navigationItems}
				userLinks={userLinks}
				utilityIcons={utilityIcons}
				externalNavigationItems={externalNavigation?.['externalLinkItems']}
				theme={theme}
			/>
			{props.children}
		</div>
	);
};
