import { Component, DocumentsOverview, Footer, Layout, Main } from 'Online/components';
import { DictionaryContext } from 'Online/context/Dictionary';
import { PageContext } from 'Online/context/PageContext';
import { _Errors, _Status } from 'Online/dictionaryItems';
import React, { useContext } from 'react';
import { AttentionCard, Container, PageLoader } from 'Shared';
import { documentsState } from 'store/slices/documentsSlice';
import { DocumentsOverviewPageAPIProps } from './DocumentsOverviewPageAPI';
export interface DocumentsOverviewPageProps extends DocumentsOverviewPageAPIProps {
	documentsData: documentsState;
}

export const DocumentsOverviewPage: React.FC<DocumentsOverviewPageProps> = (props) => {
	const page = React.useContext(PageContext);
	const dictionary = useContext(DictionaryContext);
	const { status: documentsApiStatus, tiaEnrichedDocuments: documents } = props.documentsData;
	const { policyIcon, invoiceIcon, letterIcon, mainframeDownContent } = props ?? {};
	const todaysHour = new Date().toLocaleString('DA-DK', {
		hour: 'numeric',
		timeZone: 'Europe/Copenhagen',
	});
	const mainframeIsDown = Number(todaysHour) >= 23 || Number(todaysHour) <= 7;

	return (
		<>
			{(documentsApiStatus !== 'pending' || mainframeIsDown) && (
				<Layout {...props}>
					<Main
						header={props.pageHeader?.map((headerModule, index) => (
							<Component key={index} {...headerModule} />
						))}
					>
						<Container width="Large">
							{mainframeIsDown && mainframeDownContent ? (
								mainframeDownContent.map((sectionModule, index) => (
									<Component key={index} {...sectionModule} />
								))
							) : documentsApiStatus == 'success' ? (
								<>
									{documents && (
										<DocumentsOverview
											documents={documents}
											policyIcon={policyIcon}
											invoiceIcon={invoiceIcon}
											letterIcon={letterIcon}
										/>
									)}
								</>
							) : (
								<AttentionCard
									text={_Errors(dictionary, 'FetchError')}
									settings={{
										properties: {
											type: 'error',
											size: 'large',
										},
									}}
								/>
							)}
						</Container>
					</Main>
					{page?.footer && <Footer components={page.footer} />}
				</Layout>
			)}

			{documentsApiStatus === 'pending' && <PageLoader title={_Status(dictionary, 'Loading')} />}
		</>
	);
};
