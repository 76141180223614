import { Component, Footer, Layout, Main } from 'Online/components';
import { ComponentProps } from 'Online/components/Component';
import { PageContext, PageProps } from 'Online/context/PageContext';
import React from 'react';
import { HeroCard, HeroCardProps } from 'Shared';

export interface ArticlePageProps extends PageProps, HeroCardProps {
	pageSections: ComponentProps[];
}

export const ArticlePage: React.FC<ArticlePageProps> = (props) => {
	const page = React.useContext(PageContext);

	return (
		<Layout {...props}>
			<Main>
				<HeroCard {...props} settings={{ properties: { style: 'offset' } }} />

				{props.pageSections.map((sectionModule, index) => (
					<Component key={index} {...sectionModule} />
				))}
			</Main>
			{page?.footer && <Footer components={page.footer} />}
		</Layout>
	);
};
