import classNames from 'classnames';
import styles from './ProfileItem.module.scss';

export interface ProfileItemProps {
	className?: string;
	heading: string;
	content: string[];
}

export const ProfileItem: React.FC<ProfileItemProps> = ({ heading, content, className }) => {
	return (
		<div className={classNames(styles.ProfileItem, className)}>
			<p className={styles.ProfileItem_title}>{heading}</p>
			{content.map((item, index) => (
				<p key={index}>{item}</p>
			))}
		</div>
	);
};
