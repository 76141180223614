import { getApiInstance } from 'Online/utilities/getApiInstance';

type PlusCustomerTokenResponse = {
	accessToken: null;
	exp: null;
};

export const getStoreToken = async (themeName: string): Promise<PlusCustomerTokenResponse> => {
	try {
		const storeResponse = await getApiInstance(themeName, true).get(`/api/v1/pluskunde/getStoreToken`);
		return storeResponse.data;
	} catch (error) {
		return null;
	}
};
