/* eslint-disable react-hooks/rules-of-hooks */
import { useRouter } from 'next/router';
import {
	Component,
	Footer,
	Layout,
	Main,
	PolicyDetailsPageAPIProps,
	PolicyModal,
	ProductCarousel,
	ProductDetails,
	ProductStickyBanner,
} from 'Online/components';
import { DictionaryContext } from 'Online/context/Dictionary';
import { PageContext } from 'Online/context/PageContext';
import { _Notices, _Units } from 'Online/dictionaryItems';
import { getPolicyTypeCount } from 'Online/utilities/getPolicyTypeCount';
import { getPolicyTypeItemByType } from 'Online/utilities/getPolicyTypeItemByType';
import { useContext, useEffect, useState } from 'react';
import { AttentionCard, Container } from 'Shared';
import { convertNumber } from 'Shared/utilities';

export interface PolicyDetailsPageProps extends PolicyDetailsPageAPIProps {
	policyData: PolicyData;
	policyTypeContent: PolicyTypeItem[];
	state: PolicyDetailState;
	closeModal: () => void;
}

export const PolicyDetailsPage: React.FC<PolicyDetailsPageProps> = (props) => {
	const { overviewTitle, policyTypeContent, policyData, state, closeModal } = props ?? {};
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [policyIsChanging, setPolicyIsChanging] = useState(false);

	const page = useContext(PageContext);
	const router = useRouter();
	const policyNumberHashUrl = router?.asPath.split('&policy=')[1];
	const selectedPolicyIndex = policyData?.portfolio?.findIndex(
		(item) => item.policyNumberHash === policyNumberHashUrl,
	);
	const [selectedPortfolioItem, setSelectedPortfolioItem] = useState(policyData?.portfolio?.[selectedPolicyIndex]);
	const { areaContent, area } = state ?? {};
	const dictionary = useContext(DictionaryContext);

	useEffect(() => {
		const query = state?.areaContent?.id;
		const basePath = router?.asPath.split('&')[0];
		const { policyNumberHash } = selectedPortfolioItem ?? {};
		switch (query) {
			case null:
			case undefined:
				router?.push(`${basePath}&policy=${policyNumberHash}`, undefined, { shallow: true });
				break;
			default:
				router?.push(`${basePath}&policy=${policyNumberHash}&${area}=${query}`, undefined, {
					shallow: true,
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		setSelectedPortfolioItem(policyData?.portfolio[selectedPolicyIndex]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [policyData?.portfolio]);

	useEffect(() => {
		/*
		const typeHouse = router?.query?.hus as string;
		if (typeHouse) setPolicyQuery(['hus', typeHouse]);
		const typeMoped = router?.query?.moped as string;
		if (typeMoped) setPolicyQuery(['moped', typeMoped]);
		*/
		// areacontent required?
		// check if url query matches with any portfolio item object ids or coverage ids
		if (area === 'object') {
			router?.query?.object === areaContent.id && setModalIsOpen(true);
			router?.query?.object !== areaContent.id && setModalIsOpen(false);
			return;
		}
		if (area === 'coverage') {
			router?.query?.coverage === areaContent.id && setModalIsOpen(true);
			router?.query?.coverage !== areaContent.id && setModalIsOpen(false);
			return;
		}

		/*	if (area === 'addoncoverage') {
			router?.query?.addoncoverage === areaContent.id && setModalIsOpen(true);
			router?.query?.addoncoverage !== areaContent.id && setModalIsOpen(false);
			return;
		}
		*/
		setModalIsOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router?.query, area]);

	useEffect(() => {
		setPolicyIsChanging(false);
	}, [selectedPortfolioItem]);

	const handleCarousel = (index) => {
		setPolicyIsChanging(true);
		const item = policyData?.portfolio?.[index];
		const basePath = router?.asPath.split('?')[0];
		const count = getPolicyTypeCount(policyData?.portfolio, item.type, item.policyNumber);
		setSelectedPortfolioItem(item);
		router?.push(`${basePath}?${item.type}=${count}&policy=${item.policyNumberHash}`, undefined, {
			shallow: true,
		});
	};

	const statusText = `<p><strong>${_Notices(dictionary, 'Note')}</strong></p><p>${_Notices(
		dictionary,
		'ReceivedChangesWillContact',
	)}</p>`;

	return (
		<Layout {...props}>
			<Main>
				<Container width="Full width">
					{
						<ProductCarousel
							policyTypeContent={policyTypeContent}
							portfolio={policyData.portfolio}
							handleSlideChange={(index) => handleCarousel(index)}
							currentPortfolioItem={selectedPortfolioItem}
						/>
					}
				</Container>
				<Container width="Medium">
					{selectedPortfolioItem?.state === 'pending' && (
						<AttentionCard
							text={statusText}
							icon={null}
							settings={{
								properties: {
									type: 'pending',
									size: 'small',
								},
							}}
						/>
					)}
					{selectedPortfolioItem && (
						<ProductDetails
							title={overviewTitle}
							portfolioItem={selectedPortfolioItem}
							policyTypeItem={getPolicyTypeItemByType(policyTypeContent, selectedPortfolioItem.type)}
							policyIsChanging={policyIsChanging}
						/>
					)}
				</Container>
				{props.pageSections &&
					props.pageSections.map((sectionModule, index) => <Component key={index} {...sectionModule} />)}
			</Main>

			{modalIsOpen && (
				<PolicyModal
					closeModal={() => closeModal()}
					policyTypeContent={policyTypeContent}
					selectedPortfolioItem={selectedPortfolioItem}
					state={state}
				/>
			)}

			<ProductStickyBanner
				price={`${convertNumber(selectedPortfolioItem?.price)} ${_Units(dictionary, 'DanishCrowns')}`}
				priceAnnual={` (${convertNumber(selectedPortfolioItem?.price * 12)}  ${_Units(
					dictionary,
					'DanishCrowns',
				)}/${_Units(dictionary, 'Year')}  )`}
				button={false}
			/>
			{page?.footer && <Footer className="u-bottom-margin--7xl" components={page.footer} />}
		</Layout>
	);
};
