import classNames from 'classnames';
import { Grid, Heading, LinkCard, PictureProps } from 'Shared';
import styles from './MasterCardList.module.scss';

type MasterCardItem = {
	name: string;
	url: string;
	id: string;
	properties: {
		type: string;
		image: PictureProps;
		heading: string;
	};
};

export interface MasterCardListProps {
	heading?: string;
	cards: MasterCardItem[];
}

export const MasterCardList: React.FC<MasterCardListProps> = ({ cards, heading }) => {
	return (
		<div className={classNames(styles.MasterCardList, heading && styles.MasterCardList___includesHeading)}>
			{heading && (
				<Heading headingLevel={'h2'} className={styles.MasterCardList_heading}>
					{heading}
				</Heading>
			)}

			<Grid wrap={true} className="u-grid--cell-padding30-sm u-grid--cell-padding50-md">
				{cards &&
					cards.map((result, index) => {
						const picture: PictureProps = {
							...result.properties?.image,
							sizes: '26.3rem',
							properties: { altText: 'mastercard' },
						};
						return (
							<LinkCard
								key={index}
								image={picture}
								link={{ url: result?.url, name: result?.properties?.heading }}
								className=" u-grid__cell--width-50@sm u-grid__cell--width-33@lg"
								desktopWidth="50"
							/>
						);
					})}
			</Grid>
		</div>
	);
};
