import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _DocumentsOverview } from 'Online/dictionaryItems';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, FormfieldSelect } from 'Shared';
import styles from './DocumentsFilter.module.scss';

export interface DocumentsFilterProps {
	className?: string;
	submitSortFilters: (options: FilterOptionType) => void;
	clearSortFilters: () => void;
}

export type FilterOptionType = {
	sort: 'descending' | 'ascending';
	documentCategory: 'ShowAllDocumentsTypes' | 'Policer og tilbud' | 'Øvrige Breve' | 'Betalinger';
};

export const DocumentsFilter: React.FC<DocumentsFilterProps> = ({ className, submitSortFilters, clearSortFilters }) => {
	const dictionary = useContext(DictionaryContext);

	const { handleSubmit, register, reset } = useForm<FilterOptionType>();
	const [errorSummary, setErrorSummary] = useState(0);

	const onSubmit = (data: FilterOptionType) => {
		submitSortFilters(data);
	};

	const onClearForm = () => {
		clearSortFilters();
		reset({ documentCategory: 'ShowAllDocumentsTypes', sort: 'descending' });
	};
	const onError = (data) => {
		const totalErrors = Object.keys(data).length;
		setErrorSummary(totalErrors);
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit, onError)} errorSummary={errorSummary}>
			<div className={classNames(styles.DocumentsFilter, className)}>
				<div className={styles.DocumentsFilter_removeFiltersButtonContainer}>
					<Button
						className={styles.DocumentsFilter_removeFiltersButton}
						style="link"
						onClick={() => onClearForm()}
					>
						<span>{_DocumentsOverview(dictionary, 'Reset')}</span>
					</Button>
				</div>
				<FormfieldSelect
					id={'documentCategory'}
					name="documentCategory"
					className={styles.DocumentsFilter_categoryField}
					label={_DocumentsOverview(dictionary, 'DocumentType')}
					options={[
						{
							text: _DocumentsOverview(dictionary, 'ShowAllDocumentsTypes'),
							value: 'ShowAllDocumentsTypes',
							selected: true,
						},
						{ text: _DocumentsOverview(dictionary, 'PoliciesAndOffers'), value: 'Policer og tilbud' },
						{ text: _DocumentsOverview(dictionary, 'OtherLetters'), value: 'Øvrige Breve' },
						{ text: _DocumentsOverview(dictionary, 'Invoices'), value: 'Betalinger' },
					]}
					register={register}
				/>
				<FormfieldSelect
					id={'sort'}
					name="sort"
					className={styles.DocumentsFilter_sortField}
					label={_DocumentsOverview(dictionary, 'Sorting')}
					options={[
						{ text: _DocumentsOverview(dictionary, 'NewFirst'), value: 'descending', selected: true },
						{ text: _DocumentsOverview(dictionary, 'OldFirst'), value: 'ascending' },
					]}
					register={register}
				/>
				<Button style="primary" className={styles.DocumentsFilter_applyFiltersButton} type={'submit'}>
					<span>{_DocumentsOverview(dictionary, 'ShowDocuments')}</span>
				</Button>
			</div>
		</Form>
	);
};
