import classNames from 'classnames';
import { LogoutButton } from 'Online/components';
import { useEffect, useRef } from 'react';
import { ReactComponent as LopiMastercard } from 'Shared/public/logos/lopi-mastercard.svg';
import { ProfileCtaContent } from './ProfileCtaContent';
import styles from './ProfileLinks.module.scss';

export interface ProfileLinksProps {
	profileIcon?: Image;
	profileLinks: NavigationItem[];
	profileLinksIsOpen?: (open: boolean) => void;
	profileLinksVisible: boolean;
	logoutVisible?: boolean;
	style?: 'desktop';
}

export const ProfileLinks: React.FC<ProfileLinksProps> = ({
	profileIcon,
	profileLinks,
	profileLinksIsOpen,
	profileLinksVisible,
	logoutVisible,
	style,
}) => {
	const handleClick = (showProfileLinks: boolean) => {
		if (profileLinks?.length > 1 || logoutVisible) {
			profileLinksIsOpen(showProfileLinks);
		} else {
			window.location.href = '/';
		}
	};
	const profileLinksWrapper = useRef(null);
	const useOutsideClick = (ref) => {
		useEffect(() => {
			const handleClickOutside = (event) => {
				if (ref.current && !ref.current.contains(event.target)) {
					style === 'desktop' && profileLinksIsOpen(false);
				}
			};
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	};
	useOutsideClick(profileLinksWrapper);

	return (
		<div
			className={classNames(
				styles.ProfileLinks,
				styles[`ProfileLinks___${style}`],
				profileLinksVisible && styles.ProfileLinks___visible,
			)}
			ref={profileLinksWrapper}
		>
			{
				<>
					{
						<button
							className={styles.ProfileLinks_button}
							onClick={() => handleClick(!profileLinksVisible)}
							aria-haspopup="listbox"
						>
							<ProfileCtaContent
								profileIcon={profileIcon}
								showArrow={profileLinks?.length > 1 || logoutVisible}
								isActive={profileLinksVisible}
							/>
						</button>
					}

					{profileLinks?.length === 1 ? (
						<a className={styles.ProfileLinks_link} href={profileLinks[0].url}>
							<ProfileCtaContent profileIcon={profileIcon} showArrow={false} />
						</a>
					) : null}

					<div className={styles.ProfileLinks_mastercard}>
						<LopiMastercard />
					</div>
				</>
			}

			{profileLinksVisible && (profileLinks?.length || logoutVisible) ? (
				<ul className={styles.ProfileLinks_list} role="listbox">
					{profileLinks?.length &&
						profileLinks?.map((profileLink: NavigationItem, index) => (
							<li key={index}>
								<a
									className={classNames(
										styles.ProfileLinks_listItem,
										profileLink.state === 'isActive' && styles.ProfileLinks_listItem___active,
									)}
									href={profileLink.url}
								>
									{profileLink.title}
								</a>
							</li>
						))}

					{style === 'desktop' ? (
						<li>
							<LogoutButton className={styles.ProfileLinks_listItem} />
						</li>
					) : null}
				</ul>
			) : null}
		</div>
	);
};
