import classNames from 'classnames';
import { MasterCardCta } from 'Online/components';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Actions, _Errors, _MasterCard, _Policy, _Units } from 'Online/dictionaryItems';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import {
	AttentionCard,
	Container,
	DescriptionList,
	DescriptionListProps,
	Form,
	FormfieldCheckbox,
	FormfieldGroup,
	FormfieldSelect,
	FormfieldString,
} from 'Shared';
import { getInputDateLimit, getSixMonthBackDate, getTomorrowDate } from 'Shared/utilities/date';
import { getPartnerByCardType } from 'Online/utilities/getPartnerByCadType';
import { MasterCardContent } from './MasterCardContent';
import styles from './MasterCardTravel.module.scss';

export interface MasterCardTravelProps {
	callbackContinue: (data: MasterCardTravelFormData) => void;
	callbackTravelForm: (data: MasterCardTravelFormData) => void;
	className?: string;
	errorIcon: Image;
	governmentInsurance: string;
	masterCardAttentionIcon?: Image;
	maxMonthsCovered: number;
	maxNumberOfInsured: number;
	cardType: MasterCardType;
	cardTypeGroup: MasterCardTypeGroup;
	hasExtendedPeriodOption: boolean;
	priceDetailsList: DescriptionListProps;
	travelFormData: MasterCardTravelFormData;
	status: Status;
}

export interface MasterCardTravelFormData {
	insuredCount?: string;
	isExtension?: boolean;
	startDate?: string;
	endDate?: string;
	price?: string;
	priceTravelCompensation?: string;
	extendedTravelCoverageSelected?: boolean;
}

type Option = {
	text: string;
	value: string;
	selected?: boolean;
};

export const MasterCardTravel: React.FC<MasterCardTravelProps> = ({
	callbackContinue,
	callbackTravelForm,
	className,
	errorIcon,
	governmentInsurance,
	masterCardAttentionIcon,
	maxMonthsCovered,
	maxNumberOfInsured,
	cardType,
	cardTypeGroup,
	hasExtendedPeriodOption,
	priceDetailsList,
	travelFormData,
	status,
}) => {
	const dictionary = useContext(DictionaryContext);
	const { control, handleSubmit, register, watch, setValue } = useForm<MasterCardTravelFormData>({
		mode: 'onChange',
		defaultValues: { insuredCount: '1' },
	});

	const [formData, setFormData] = useState<MasterCardTravelFormData>(travelFormData);
	const [errorSummary, setErrorSummary] = useState(0);

	const [isExtendedCoverage, setIsExtendedCoverage] = useState(
		travelFormData?.extendedTravelCoverageSelected != null ? travelFormData?.extendedTravelCoverageSelected : false,
	);

	const [insuredCountOptions, setInsuredCountOptions] = useState<Option[]>([]);

	const { errors, isValid } = useFormState({
		control,
	});

	const partner = getPartnerByCardType(cardType);

	useEffect(() => {
		const startDate = new Date(formData?.startDate);
		const endDate = new Date(formData?.endDate);

		const timeDifference = endDate.getTime() - startDate.getTime();
		const dayDifference = timeDifference / (1000 * 3600 * 24);

		if (dayDifference > 60) setIsExtendedCoverage(true);
		if (dayDifference <= 60 && formData.isExtension) {
			setIsExtendedCoverage(true);
		}
		if (dayDifference <= 60 && !formData.isExtension) {
			setIsExtendedCoverage(false);
		}
	}, [formData]);

	useEffect(() => {
		const options = [];
		for (let i = 1; i <= maxNumberOfInsured; i++) {
			if (i == 1) options.push({ text: `${i} ${_Units(dictionary, 'Person')}`, value: `${i}` });
			else
				options.push({
					text: `${i} ${_Units(dictionary, 'Persons')}`,
					value: `${i}`,
				});
		}
		setInsuredCountOptions(options);
	}, [dictionary, maxNumberOfInsured]);

	const onSubmit: SubmitHandler<MasterCardTravelFormData> = (data) => {
		callbackContinue(data);
	};

	useEffect(() => {
		const subscription = watch((value) => {
			setFormData(value);

			if (isValid) callbackTravelForm(value as MasterCardTravelFormData);
		});

		return () => subscription.unsubscribe();
	}, [callbackTravelForm, isValid, watch]);

	const onError = (data) => {
		const totalErrors = Object.keys(data).length;
		setErrorSummary(totalErrors);
	};

	return (
		<div className={classNames(styles.MasterCardTravel, className)}>
			<Form onSubmit={handleSubmit(onSubmit, onError)} errorSummary={errorSummary}>
				<Container width="Medium">
					<FormfieldGroup
						id="insuredCountGroup"
						className="u-top-margin--none"
						name="insuredCountGroup"
						label={_MasterCard(dictionary, 'TheTravelers')}
						style="blocked"
					>
						<FormfieldSelect
							className={styles.MasterCardTravel_countField}
							id="insuredCount"
							name="insuredCount"
							label={_Policy(dictionary, 'NumberOfInsured')}
							defaultValue={travelFormData?.insuredCount ? travelFormData.insuredCount : '1'}
							helpCtaText={_MasterCard(dictionary, 'NumberOfInsuredHelpText')}
							closeCtaText={_Actions(dictionary, 'Close')}
							options={insuredCountOptions}
							register={register}
							state={errors.insuredCount ? { hasError: true, required: true } : { required: true }}
						/>
					</FormfieldGroup>

					<FormfieldGroup
						id="travelPeriodGroup"
						name="travelPeriodGroup"
						label={_MasterCard(dictionary, 'TravelPeriod')}
						style="blocked"
					>
						{cardTypeGroup != 'standard' && (
							<MasterCardContent heading={_MasterCard(dictionary, 'ExtendedTravelInsurance')} />
						)}
						<MasterCardContent
							text={_MasterCard(dictionary, `TravelPeriodSubtitle${partner}`)}
							additionalText={
								cardTypeGroup === 'worldelite'
									? _MasterCard(dictionary, `TravelPeriodSubtitleWorldElite${partner}`)
									: cardTypeGroup != 'standard' &&
									  _MasterCard(dictionary, 'TravelPeriodSubtitlePremium')
							}
						/>

						{cardTypeGroup != 'standard' && (
							<FormfieldCheckbox
								id="extendedTravelCoverageSelected"
								name="extendedTravelCoverageSelected"
								label={_MasterCard(dictionary, 'IWantToExtendedTravelInsurance')}
								defaultChecked={true}
								register={register}
							/>
						)}

						<FormfieldString
							id="startDate"
							name="startDate"
							className={styles.MasterCardTravel_dateField}
							label={_MasterCard(dictionary, 'DepartureDate')}
							defaultValue={travelFormData?.startDate}
							type="date"
							register={register}
							min={getSixMonthBackDate()}
							state={errors.startDate ? { hasError: true, required: true } : { required: true }}
						/>

						<FormfieldString
							id="endDate"
							name="endDate"
							className={styles.MasterCardTravel_dateField}
							label={_MasterCard(dictionary, 'ReturnDate')}
							defaultValue={travelFormData?.endDate}
							type="date"
							min={getTomorrowDate()}
							max={formData?.startDate && getInputDateLimit(formData.startDate, maxMonthsCovered)}
							register={register}
							state={errors.endDate ? { hasError: true, required: true } : { required: true }}
						/>

						{hasExtendedPeriodOption && (
							<>
								<FormfieldCheckbox
									className={styles.MasterCardTravel_checkbox}
									id="isExtension"
									name="isExtension"
									label={_MasterCard(dictionary, 'ExtensionOfAlreadyPurchasedAdditionalPeriod')}
									defaultChecked={travelFormData?.isExtension}
									register={register}
									callback={(checked) =>
										checked ? setValue('extendedTravelCoverageSelected', true) : null
									}
									helpCtaCloseText={_Actions(dictionary, 'Close')}
									helpCtaText={_MasterCard(
										dictionary,
										'ExtensionOfAlreadyPurchasedAdditionalPeriodHelpText',
									)}
								/>

								<MasterCardContent
									text={_MasterCard(
										dictionary,
										'ExtensionOfAlreadyPurchasedAdditionalPeriodDescription',
									)}
								/>
							</>
						)}
					</FormfieldGroup>

					<FormfieldGroup
						id="PriceGroup"
						name="PriceGroup"
						label={_MasterCard(dictionary, 'Coverages')}
						style="blocked"
					>
						{cardType === 'MPN' || cardType === 'WEMN' ? (
							<>
								<MasterCardContent
									heading={_MasterCard(dictionary, 'ExtendedCancellationFeeBrokenHoliday')}
									text={
										cardType === 'MPN'
											? _MasterCard(
													dictionary,
													'ExtendedCancellationFeeBrokenHolidaySubtitlePlatinum',
											  )
											: cardType === 'WEMN'
											? _MasterCard(
													dictionary,
													'ExtendedCancellationFeeBrokenHolidaySubtitleWorldElite',
											  )
											: null
									}
								/>
								<FormfieldString
									id="priceTravelCompensation"
									name="priceTravelCompensation"
									label={_MasterCard(dictionary, 'ThePriceOfTheTrip')}
									defaultValue={travelFormData?.priceTravelCompensation}
									type="number"
									register={register}
									helpCtaText={_MasterCard(dictionary, 'ThePriceOfTheTripHelpText')}
									closeCtaText={_Actions(dictionary, 'Close')}
									className={styles.MasterCardTravel_priceField}
									min={25}
									max={cardType === 'MPN' ? 575000 : Infinity}
								/>
							</>
						) : null}

						<MasterCardContent
							heading={_MasterCard(dictionary, 'ExtendedCancellationFee')}
							text={
								cardTypeGroup === 'worldelite'
									? _MasterCard(dictionary, `ExtendedCancellationFeeSubtitleWorldElite${partner}`)
									: cardTypeGroup === 'standard'
									? _MasterCard(dictionary, `ExtendedCancellationFeeSubtitle${partner}`)
									: cardTypeGroup === 'platinum'
									? _MasterCard(
											dictionary,
											`ExtendedCancellationFeeSubtitlePlatinum${
												partner === 'Nykredit' && cardType === 'MPN'
													? 'NykreditMPN'
													: partner === 'Nykredit' && cardType === 'MPBN'
													? 'NykreditMPBN'
													: 'Lopi'
											}`,
									  )
									: _MasterCard(dictionary, `ExtendedCancellationFeeSubtitlePremium${partner}`)
							}
						/>

						<FormfieldString
							id="price"
							name="price"
							label={_MasterCard(dictionary, 'ThePriceOfTheTrip')}
							defaultValue={travelFormData?.price}
							type="number"
							register={register}
							helpCtaText={_MasterCard(dictionary, 'ThePriceOfTheTripHelpText')}
							closeCtaText={_Actions(dictionary, 'Close')}
							className={styles.MasterCardTravel_priceField}
							min={25}
							max={
								cardType === 'MSBN'
									? 310000
									: cardTypeGroup === 'standard'
									? 50000
									: cardType === 'MGN'
									? 340000
									: cardType === 'MPN'
									? 575000
									: cardType === 'MPBN'
									? 560000
									: cardTypeGroup === 'platinum'
									? 300000
									: cardType === 'WEMN'
									? Infinity
									: 250000
							}
							state={
								errors.price
									? { hasError: true, required: cardTypeGroup === 'standard' }
									: { required: cardTypeGroup === 'standard' }
							}
						/>
					</FormfieldGroup>
					{isValid && priceDetailsList && status != 'error' && (
						<Container width="Medium">
							<DescriptionList {...priceDetailsList} />
						</Container>
					)}
					{status === 'error' && (
						<Container width="Medium">
							<p className={styles.MasterCardTravel_errorLabel}>{_MasterCard(dictionary, 'YourPrice')}</p>
							<AttentionCard
								className={styles.MasterCardTravel_errorCard}
								icon={errorIcon}
								text={`<p>` + _Errors(dictionary, 'FetchError') + `</p>`}
								settings={{ properties: { type: 'error', size: 'large' } }}
							/>
						</Container>
					)}

					{isValid && priceDetailsList && status != 'error' && (
						<AttentionCard
							text={
								`<p>` +
								_MasterCard(dictionary, 'PriceIncludesInsuranceToGovernment') +
								` ${governmentInsurance} ` +
								_Units(dictionary, 'DanishCrowns') +
								`</p>`
							}
							icon={masterCardAttentionIcon}
							settings={{ properties: { type: 'disclaimer', size: 'small' } }}
						/>
					)}

					<MasterCardCta
						className={styles.MasterCardUserForm_cta}
						previous={false}
						continueText={_Actions(dictionary, 'Next')}
						submit
						continueDisabled={
							!isValid ||
							priceDetailsList?.descriptionList?.[priceDetailsList?.descriptionList?.length - 1]
								?.description === '0' ||
							!priceDetailsList
						}
					/>
				</Container>
			</Form>
		</div>
	);
};
