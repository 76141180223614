import useSWR from 'swr';
import { getMasterCardsById } from 'Online/utilities/getMasterCardsById';
import { useEffect } from 'react';
import { MasterCardList } from './MasterCardList';

export interface MasterCardListAPIProps {
	heading?: string;
	rootFolder: BasePage;
}

export const MasterCardListAPI: React.FC<MasterCardListAPIProps> = ({ heading, rootFolder }) => {
	const { data: MasterCards } = useSWR([rootFolder?.id], getMasterCardsById);

	useEffect(() => {
		if (!MasterCards?.pageSearch?.pages) return;
	}, [MasterCards]);

	if (!MasterCards) return null;

	return <MasterCardList cards={MasterCards?.pageSearch?.pages} heading={heading} />;
};
