import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Services } from 'Online/dictionaryItems';
import { useContext } from 'react';
import { Image } from 'Shared';
import styles from './LogoutButton.module.scss';

export interface LogoutButtonProps {
	className?: string;
	icon?: Image;
}

export const LogoutButton: React.FC<LogoutButtonProps> = ({ className, icon }) => {
	const dictionary = useContext(DictionaryContext);

	const handleLogout = () => {
		window.location.href = '/logud';
	};

	return (
		<>
			<button className={classNames(styles.LogoutButton, className)} onClick={handleLogout}>
				{icon && (
					<Image
						className={styles.LogoutButton_icon}
						url={icon.url}
						properties={icon.properties}
						sizes="3rem"
					/>
				)}
				{_Services(dictionary, 'LogOut')}
			</button>
		</>
	);
};
