import Head from 'next/head';
export interface RaffleScriptProps {
	raffleScriptUrl: string;
	raffleScriptDataUid: string;
}

export const RaffleScript: React.FC<RaffleScriptProps> = ({ raffleScriptUrl, raffleScriptDataUid }) => {
	return (
		<Head>
			<script src={raffleScriptUrl} id="raffle-sdk" data-uid={raffleScriptDataUid} type="text/javascript" async />
		</Head>
	);
};
