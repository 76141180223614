import { Dictionary } from 'Online/context/Dictionary';

export type MasterCardDictionaries =
	| 'AddonBuyForYourTravelInsurance'
	| 'CardType'
	| 'CoInsured'
	| 'Coverages'
	| 'ConfirmationPayment'
	| 'ConfirmationEmail'
	| 'DescriptionPremium'
	| 'DescriptionNonPremiumLopi'
	| 'DescriptionNonPremiumNykredit'
	| 'DescriptionWorldEliteLopi'
	| 'DescriptionWorldEliteNykredit'
	| 'DepartureDate'
	| 'DibsTransactionNumber'
	| 'EnterTheEntirePeriodOfTheTrip'
	| 'EnterTheEntirePeriodOfTheTripHelpText'
	| 'ExtensionOfAlreadyPurchasedAdditionalPeriod'
	| 'ExtensionOfAlreadyPurchasedAdditionalPeriodHelpText'
	| 'ExtensionOfAlreadyPurchasedAdditionalPeriodDescription'
	| 'ExtendedTravelInsurance'
	| 'ExtendedTravelInsuranceSubtitle'
	| 'ExtendedTravelInsuranceSubtitleWorldElite'
	| 'ExtendedCancellationFee'
	| 'ExtendedCancellationFeeAmount'
	| 'ExtendedTravelCompensationFeeAmount'
	| 'ExtendedCancellationFeeBrokenHoliday'
	| 'ExtendedCancellationFeeBrokenHolidaySubtitlePlatinum'
	| 'ExtendedCancellationFeeBrokenHolidaySubtitleWorldElite'
	| 'ExtendedCancellationFeeSubtitlePremiumLopi'
	| 'ExtendedCancellationFeeSubtitlePremiumNykredit'
	| 'ExtendedCancellationFeeSubtitlePlatinumLopi'
	| 'ExtendedCancellationFeeSubtitlePlatinumNykreditMPN'
	| 'ExtendedCancellationFeeSubtitlePlatinumNykreditMPBN'
	| 'ExtendedCancellationFeeSubtitleLopi'
	| 'ExtendedCancellationFeeSubtitleNykredit'
	| 'ExtendedCancellationFeeSubtitleWorldEliteLopi'
	| 'ExtendedCancellationFeeSubtitleWorldEliteNykredit'
	| 'IAcceptTheConditions'
	| 'IWantToExtendedTravelInsurance'
	| 'NumberOfInsuredHelpText'
	| 'Payment'
	| 'PaymentDetails'
	| 'PolicyOwner'
	| 'PriceIncludesInsuranceToGovernment'
	| 'ReturnDate'
	| 'RightOfWithdrawalExplainer'
	| 'RightOfWithdrawalExpirationText'
	| 'TheTravelers'
	| 'ThePriceOfTheTrip'
	| 'ThePriceOfTheTripHelpText'
	| 'TravelPeriod'
	| 'TravelPeriodSubtitleLopi'
	| 'TravelPeriodSubtitleNykredit'
	| 'TravelPeriodSubtitlePremium'
	| 'TravelPeriodSubtitleWorldEliteLopi'
	| 'TravelPeriodSubtitleWorldEliteNykredit'
	| 'TravelPolicyPurchaseAcknowledgement'
	| 'AboutYou'
	| 'Overview'
	| 'Receipt'
	| 'CoInsuredHelpText'
	| 'CoInsuredHelpTextWorldelite'
	| 'YourJourney'
	| 'YourOrderNumberIs'
	| 'YourPrice';

export const _MasterCard = (dictionary: Partial<Dictionary>, item: MasterCardDictionaries): string => {
	switch (item) {
		case 'AboutYou':
			return dictionary.getValue('MasterCard.AboutYou', null, 'Om dig');

		case 'AddonBuyForYourTravelInsurance':
			return dictionary.getValue(
				'MasterCard.AddonBuyForYourTravelInsurance',
				null,
				'Tilkøb til din rejseforsikring',
			);
		case 'CardType':
			return dictionary.getValue('MasterCard.CardType', null, 'Korttype');

		case 'CoInsured':
			return dictionary.getValue('MasterCard.CoInsured', null, 'Medforsikrede');
		case 'ConfirmationPayment':
			return dictionary.getValue('MasterCard.ConfirmationPayment', null, 'Vi har modtaget din bestilling');
		case 'ConfirmationEmail':
			return dictionary.getValue(
				'MasterCard.ConfirmationEmail',
				null,
				'Vi har sendt dig en email med bekræftelse',
			);
		case 'Coverages':
			return dictionary.getValue('MasterCard.Coverages', null, 'Dækninger');
		case 'DepartureDate':
			return dictionary.getValue('MasterCard.DepartureDate', null, 'Afrejsedato');
		case 'DibsTransactionNumber':
			return dictionary.getValue('MasterCard.DibsTransactionNumber', null, 'DIBS Transaktionsnummer');
		case 'EnterTheEntirePeriodOfTheTrip':
			return dictionary.getValue('MasterCard.EnterTheEntirePeriodOfTheTrip', null, 'Angiv hele rejsens periode');
		case 'ExtensionOfAlreadyPurchasedAdditionalPeriod':
			return dictionary.getValue(
				'MasterCard.ExtensionOfAlreadyPurchasedAdditionalPeriod',
				null,
				'Forlængelse af allerede tilkøbt ekstra periode',
			);
		case 'ExtensionOfAlreadyPurchasedAdditionalPeriodHelpText':
			return dictionary.getValue(
				'MasterCard.ExtensionOfAlreadyPurchasedAdditionalPeriodHelpText',
				null,
				'I afrejse dato skal du indtaste den dato hvortil der allerede er foretaget udvidelse, mens du under hjemkomstdato skal notere den nye hjemkomstdato',
			);
		case 'ExtensionOfAlreadyPurchasedAdditionalPeriodDescription':
			return dictionary.getValue(
				'MasterCard.ExtensionOfAlreadyPurchasedAdditionalPeriodDescription',
				null,
				'Hvis du allerede har købt en ekstra periode, og har behov for at udvide denne yderligere, så skal du her vælge forlængelses perioden',
			);
		case 'ExtendedTravelInsurance':
			return dictionary.getValue('MasterCard.ExtendedTravelInsurance', null, 'Udvidet rejseforsikring');
		case 'ExtendedTravelInsuranceSubtitle':
			return dictionary.getValue(
				'MasterCard.ExtendedTravelInsuranceSubtitle',
				null,
				'Udvidet rejseforsikring kan vælges når rejseperioden overstiger 60 dage eller hvis du ønsker at udvide en allerede forsikret periode.',
			);
		case 'ExtendedTravelInsuranceSubtitleWorldElite':
			return dictionary.getValue(
				'MasterCard.ExtendedTravelInsuranceSubtitleWorldElite',
				null,
				'Udvidet rejseforsikring kan vælges når rejseperioden overstiger 90 dage eller hvis du ønsker at udvide en allerede forsikret periode.',
			);
		case 'ExtendedCancellationFee':
			return dictionary.getValue('MasterCard.ExtendedCancellationFee', null, 'Udvidet afbestillingssum');
		case 'ExtendedCancellationFeeAmount':
			return dictionary.getValue('MasterCard.ExtendedCancellationFeeAmount', null, 'Udvidet afbestillingssum af');
		case 'ExtendedTravelCompensationFeeAmount':
			return dictionary.getValue(
				'MasterCard.ExtendedTravelCompensationFeeAmount',
				null,
				'Udvidet erstatningsrejsesum af',
			);
		case 'ExtendedCancellationFeeBrokenHoliday':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeBrokenHoliday',
				null,
				'Udvidet erstatningsrejse/ødelagt feriedøgn',
			);
		case 'ExtendedCancellationFeeBrokenHolidaySubtitlePlatinum':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeBrokenHolidaySubtitlePlatinum',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort inkluderer op til 25.000 DKK pr. person, dog maksimum 75.000 DKK pr. rejse. Som kortholder kan du maksimum tilkøbe 500.000 DKK oveni kortets standarddækning.

				Når du tilkøber udvidelse af erstatningsrejse/ødelagt feriedøgn bortfalder begrænsningen pr. person jf. vilkårene punkt 17. Udvidelen du køber er sålede den maksimale dækning pr. rejse for hele den sikrede personkreds.`,
			);
		case 'ExtendedCancellationFeeBrokenHolidaySubtitleWorldElite':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeBrokenHolidaySubtitleWorldElite',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort inkluderer op til 50.000 DKK pr. person, dog maksimum 200.000 DKK pr. rejse. 
				
				Når du tilkøber udvidelse af erstatningsrejse/ødelagt feriedøgn bortfalder begrænsningen pr. person jf. vilkårene punkt 18. Udvidelen du køber er sålede den maksimale dækning pr. rejse for hele den sikrede personkreds.`,
			);
		case 'ExtendedCancellationFeeSubtitlePremiumLopi':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeSubtitlePremiumLopi',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort inkludere op til 10.000,00 DKK pr. person, dog maksimum 50.000,00 DKK i alt. Som kortholder kan du maksimum tilkøbe 200.000,00 DKK oveni kortets standarddækning.
				
				Du skal være opmærksom på, at du ikke kan forhøje afbestillingssummen efter at rejsen er bestilt/købt.
				`,
			);

		case 'ExtendedCancellationFeeSubtitlePremiumNykredit':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeSubtitlePremiumNykredit',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort inkluderer op til 20.000,00 DKK pr. person, dog maksimum 40.000 DKK pr. rejse. Som kortholder kan du maksimum tilkøbe 300.000 DKK oveni kortets standarddækning.
				
				Du skal være opmærksom på, at du ikke kan forhøje afbestillingssummen efter at rejsen er bestilt/købt.

				Når du tilkøber udvidelse af afbestillingssummen bortfalder begrænsningen pr. person jf. vilkårene punkt 10. Udvidelen du køber er sålede den maksimale dækning pr. rejse for hele den sikrede personkreds.`,
			);
		case 'ExtendedCancellationFeeSubtitlePlatinumLopi':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeSubtitlePlatinumLopi',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort dækker op til 100.000 DKK i alt. Som kortholder kan du maksimum tilkøbe 200.000 DKK oveni kortets standarddækning.
				
				Du skal være opmærksom på, at du ikke kan forhøje afbestillingssummen efter at rejsen er bestilt/købt.
				`,
			);
		case 'ExtendedCancellationFeeSubtitlePlatinumNykreditMPN':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeSubtitlePlatinumNykreditMPN',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort inkluderer op til 25.000 DKK pr. person, dog maksimum 75.000 DKK pr. rejse. Som kortholder kan du maksimum tilkøbe 500.000 DKK oveni kortets standarddækning. 
				
				Du skal være opmærksom på, at du ikke kan forhøje afbestillingssummen efter at rejsen er bestilt/købt.

				Når du tilkøber udvidelse af afbestillingssummen bortfalder begrænsningen pr. person jf. vilkårene punkt 10.Udvidelen du køber er sålede den maksimale dækning pr. rejse for hele den sikrede personkreds.`,
			);
		case 'ExtendedCancellationFeeSubtitlePlatinumNykreditMPBN':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeSubtitlePlatinumNykreditMPBN',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort inkluderer op til 25.000 DKK pr. person, dog maksimum 60.000 DKK pr. rejse. Som kortholder kan du maksimum tilkøbe 500.000 DKK oveni kortets standarddækning. 
				
				Du skal være opmærksom på, at du ikke kan forhøje afbestillingssummen efter at rejsen er bestilt/købt.

				Når du tilkøber udvidelse af afbestillingssummen bortfalder begrænsningen pr. person jf. vilkårene punkt 4. Udvidelen du køber er sålede den maksimale dækning pr. rejse for hele den sikrede personkreds.`,
			);
		case 'ExtendedCancellationFeeSubtitleWorldEliteLopi':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeSubtitleWorldEliteLopi',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort inkluderer op til 160.000 DKK pr. rejse
				
				Du skal være opmærksom på, at du ikke kan forhøje afbestillingssummen efter at rejsen er bestilt/købt.
				`,
			);
		case 'ExtendedCancellationFeeSubtitleWorldEliteNykredit':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeSubtitleWorldEliteNykredit',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort inkluderer op til 50.000 DKK pr. person, dog maksimum 200.000 DKK pr. rejse. 

				Du skal være opmærksom på, at du ikke kan forhøje afbestillingssummen efter at rejsen er bestilt/købt.
				
				Når du tilkøber udvidelse af afbestillingssummen bortfalder begrænsningen pr. person jf. vilkårene punkt 20. Udvidelen du køber er sålede den maksimale dækning pr. rejse for hele den sikrede personkreds.`,
			);
		case 'ExtendedCancellationFeeSubtitleLopi':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeSubtitleLopi',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort inkluderer op til 10.000,00 DKK pr. person. Som kortholder kan du maksimum tilkøbe 40.000,00 DKK oveni kortets standarddækning.
				
				Du skal være opmærksom på, at du ikke kan forhøje afbestillingssummen efter at rejsen er bestilt/købt.
				`,
			);
		case 'ExtendedCancellationFeeSubtitleNykredit':
			return dictionary.getValue(
				'MasterCard.ExtendedCancellationFeeSubtitleNykredit',
				null,
				`Udfyldes kun hvis rejsens pris overstiger dækningen på dit kort. Dit kort inkluderer op til 10.000 DKK pr. person, dog maksimum 10.000 DKK pr. rejse. Som kortholder kan du maksimum tilkøbe 300.000 DKK oveni kortets standarddækning.
				
				Du skal være opmærksom på, at du ikke kan forhøje afbestillingssummen efter at rejsen er bestilt/købt.

				Når du tilkøber udvidelse af afbestillingssummen bortfalder begrænsningen pr. person jf. vilkårene punkt 4. Udvidelen du køber er sålede den maksimale dækning pr. rejse for hele den sikrede personkreds.`,
			);
		case 'DescriptionPremium':
			return dictionary.getValue(
				'MasterCard.DescriptionPremium',
				null,
				'Forsikringen dækker oprindeligt i 60 dage. Vil du forlænge dækningen, så vælg, hvor mange personer forsikringen skal dække, og i hvilken periode den skal dække. Indtast din rejses samlede varighed – så regner vi selv ud, hvor mange ekstra dage du har brug for.',
			);
		case 'DescriptionNonPremiumLopi':
			return dictionary.getValue(
				'MasterCard.DescriptionNonPremiumLopi',
				null,
				'Forsikringen dækker oprindeligt i 45 dage og med op til 10.000 kr. ved afbestilling. Du har her mulighed for at udvide din afbestillingssum.',
			);
		case 'DescriptionNonPremiumNykredit':
			return dictionary.getValue(
				'MasterCard.DescriptionNonPremiumNykredit',
				null,
				'Forsikringen dækker automatisk i 60 dage og med op til 10.000 kr. ved afbestilling. Du har her mulighed for at udvide dækningsperioden og din afbestillingssum.',
			);
		case 'DescriptionWorldEliteLopi':
			return dictionary.getValue(
				'MasterCard.DescriptionWorldEliteLopi',
				null,
				'Forsikringen dækker oprindeligt i 90 dage. Vil du forlænge dækningen, så vælg, hvor mange personer forsikringen skal dække, og i hvilken periode den skal dække. Indtast din rejses samlede varighed – så regner vi selv ud, hvor mange ekstra dage du har brug for.',
			);
		case 'DescriptionWorldEliteNykredit':
			return dictionary.getValue(
				'MasterCard.DescriptionWorldEliteNykredit',
				null,
				'Forsikringen dækker oprindeligt i 60 dage. Vil du forlænge dækningen, så vælg, hvor mange personer forsikringen skal dække, og i hvilken periode den skal dække. Indtast din rejses samlede varighed – så regner vi selv ud, hvor mange ekstra dage du har brug for.',
			);
		case 'IAcceptTheConditions':
			return dictionary.getValue(
				'MasterCard.IAcceptTheConditions',
				null,
				'Jeg har læst og accepteret betingelserne',
			);
		case 'IWantToExtendedTravelInsurance':
			return dictionary.getValue(
				'MasterCard.IWantToExtendedTravelInsurance',
				null,
				'Ja, jeg ønsker at tilvælge udvidet rejseforsikring',
			);
		case 'NumberOfInsuredHelpText':
			return dictionary.getValue(
				'MasterCard.NumberOfInsuredHelpText',
				null,
				'Her skal du indtaste det antal personer der ønskes forsikret på rejsen. Der henvises i øvrigt til afsnittet ”Hvem dækker forsikringen” i forsikringsbetingelserne.',
			);
		case 'Overview':
			return dictionary.getValue('MasterCard.Overview', null, 'Oversigt');
		case 'Payment':
			return dictionary.getValue('MasterCard.Payment', null, 'Betaling');
		case 'PaymentDetails':
			return dictionary.getValue('MasterCard.PaymentDetails', null, 'Betalingsoplysninger');
		case 'PolicyOwner':
			return dictionary.getValue('MasterCard.PolicyOwner', null, 'Forsikringstager');
		case 'PriceIncludesInsuranceToGovernment':
			return dictionary.getValue(
				'MasterCard.PriceIncludesInsuranceToGovernment',
				null,
				'Prisen er inklusive 1,1 procent i skadeforsikringsafgift til staten på',
			);
		case 'ReturnDate':
			return dictionary.getValue('MasterCard.ReturnDate', null, 'Hjemkomstdato');
		case 'Receipt':
			return dictionary.getValue('MasterCard.Receipt', null, 'Kvittering');
		case 'RightOfWithdrawalExplainer':
			return dictionary.getValue(
				'MasterCard.RightOfWithdrawalExplainer',
				null,
				'Fortrydelsesfristen begynder fra du har modtaget din police, som i dette tilfælde er den bekræftelse, som udstedes umiddelbart efter betalingen pr. mail og i skærmbilledet efter betalingen. Hvis fristen udløber på en helligdag, lørdag, grundlovsdag, juleaftensdag eller nytårsaftensdag, kan du vente med at fortryde til den følgende hverdag',
			);
		case 'RightOfWithdrawalExpirationText':
			return dictionary.getValue(
				'MasterCard.RightOfWithdrawalExpirationText',
				null,
				'Du har 14 dages fuld fortrydelsesret',
			);
		case 'ThePriceOfTheTrip':
			return dictionary.getValue('MasterCard.ThePriceOfTheTrip', null, 'Rejsens pris');
		case 'ThePriceOfTheTripHelpText':
			return dictionary.getValue(
				'MasterCard.ThePriceOfTheTripHelpText',
				null,
				'Du skal indtaste det beløb, som er svarende til rejsens totale pris. inkl. eventuelle allerede afholdte udgifter til depositum.',
			);
		case 'TheTravelers':
			return dictionary.getValue('MasterCard.TheTravelers', null, 'De rejsende');
		case 'TravelPeriod':
			return dictionary.getValue('MasterCard.TravelPeriod', null, 'Rejseperiode');
		case 'TravelPeriodSubtitleLopi':
			return dictionary.getValue(
				'MasterCard.TravelPeriodSubtitleLopi',
				null,
				'Indtast rejseperiode. Den totale rejseperiode, må ikke overstige 6 måneder.',
			);
		case 'TravelPeriodSubtitleNykredit':
			return dictionary.getValue(
				'MasterCard.TravelPeriodSubtitleNykredit',
				null,
				'Indtast rejseperiode. Den totale rejseperiode, må ikke overstige 12 måneder.',
			);
		case 'TravelPeriodSubtitlePremium':
			return dictionary.getValue(
				'MasterCard.TravelPeriodSubtitlePremium',
				null,
				'Forsikringen dækker oprindeligt i 60 dage. Hvis rejseperiode overstiger dækningsperioden, vil systemet beregne prisen for den ekstra periode.',
			);
		case 'TravelPeriodSubtitleWorldEliteLopi':
			return dictionary.getValue(
				'MasterCard.TravelPeriodSubtitleWorldEliteLopi',
				null,
				'Forsikringen dækker oprindeligt i 90 dage. Hvis rejseperiode overstiger dækningsperioden, vil systemet beregne prisen for den ekstra periode.',
			);
		case 'TravelPeriodSubtitleWorldEliteNykredit':
			return dictionary.getValue(
				'MasterCard.TravelPeriodSubtitleWorldEliteNykredit',
				null,
				'Forsikringen dækker oprindeligt i 60 dage. Hvis rejseperiode overstiger dækningsperioden, vil systemet beregne prisen for den ekstra periode.',
			);
		case 'TravelPolicyPurchaseAcknowledgement':
			return dictionary.getValue(
				'MasterCard.TravelPolicyPurchaseAcknowledgement',
				null,
				'Tak for dit køb af rejseforsikring',
			);
		case 'CoInsuredHelpText':
			return dictionary.getValue(
				'MasterCard.CoInsuredHelpText',
				null,
				`Medrejsende ægtefælle/samlever er:
				Kortholders og ægtefælles/samlevers børn under 21 år uanset børnenes bopæl.
				Kortholders og ægtefælle/samlevers børn dækkes, når børnene har samme folkeregisteradresse som kortholder uanset alder.

				Der henvises i øvrigt til afsnittet  'Hvem dækker forsikringen' i forsikringsbetingelserne.`,
			);
		case 'CoInsuredHelpTextWorldelite':
			return dictionary.getValue(
				'MasterCard.CoInsuredHelpTextWorldelite',
				null,
				`Medrejsende ægtefælle/samlever er:
				 Kortholders og ægtefælles/samlevers børn under 21 år uanset børnenes bopæl.
				 Kortholders og ægtefælle/samlevers børn dækkes, når børnene har samme folkeregisteradresse som kortholder uanset alder.
				 Kortholders og ægtefælle/samlevers medrejsende børnebørn under 18 år.
				 
				 Der henvises i øvrigt til afsnittet 'Hvem dækker forsikringen' i forsikringsbetingelserne.`,
			);
		case 'YourJourney':
			return dictionary.getValue('MasterCard.YourJourney', null, 'Din rejse');
		case 'YourOrderNumberIs':
			return dictionary.getValue('MasterCard.YourOrderNumberIs', null, 'Dit ordrenummer er');
		case 'YourPrice':
			return dictionary.getValue('MasterCard.YourPrice', null, 'Din pris');
	}
};
