import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import { useContext } from 'react';
import { convertNumber } from 'Shared/utilities';
import { _Units, _Price } from 'Online/dictionaryItems';
import styles from './PolicyTotal.module.scss';

interface PolicyTotalProps {
	annualTotalPrice: number;
}

export const PolicyTotal: React.FC<PolicyTotalProps> = ({ annualTotalPrice }) => {
	const dictionary = useContext(DictionaryContext);
	return (
		<div className={styles.PolicyTotal}>
			<div className={styles.PolicyTotal_wrapper}>
				<p className={styles.PolicyTotal_text}>{_Price(dictionary, 'TotalPricePerMonth')}</p>
			</div>
			<div className={styles.PolicyTotal_wrapper}>
				<p className={classNames(styles.PolicyTotal_price, styles.PolicyTotal_price___month)}>
					{annualTotalPrice ? convertNumber(annualTotalPrice / 12) : 0} {_Units(dictionary, 'DanishCrowns')}
				</p>
				<p className={classNames(styles.PolicyTotal_price, styles.PolicyTotal_price___annual)}>
					({annualTotalPrice ? convertNumber(annualTotalPrice) : 0}{' '}
					{`${_Units(dictionary, 'DanishCrowns')}/${_Units(dictionary, 'Year')}`})
				</p>
			</div>
		</div>
	);
};
