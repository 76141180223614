import { convertNumber } from 'Shared/utilities';
import { ProductOverviewItemProps } from '../ProductOverview/ProductOverviewItem';

export const mapPolicyReadMoreListToProductOverviewItems = (
	policyReadMoreList: UmbracoContent<ProductListItem>[],
): ProductOverviewItemProps[] => {
	const list: ProductListItem[] = policyReadMoreList.map((umbracoLink) => {
		return {
			link: umbracoLink.content.properties.link,
			icon: umbracoLink.content.properties.icon,
			title: umbracoLink.content.properties.title,
		};
	});

	return list;
};

const getPolicyTypeCount = (allTypes: { assigned: boolean; type: PolicyType }[], currentType: PolicyType): string => {
	const firstMatchIndex = allTypes.findIndex((x) => x.type === currentType && !x.assigned);
	allTypes[firstMatchIndex].assigned = true;
	return allTypes.filter((x) => x.type === currentType && x.assigned).length.toString();
};

export const mapPortfolioToProductOverviewItems = (
	portfolio: PortfolioItem[],
	baseLink: Link,
	policyTypeContent: PolicyTypeItem[],
): ProductOverviewItemProps[] => {
	const allTypes: { assigned: boolean; type: PolicyType }[] = [];
	portfolio.forEach((item) => allTypes.push({ assigned: false, type: item.type }));

	const list: ProductListItem[] = portfolio.map((portfolioItem) => {
		const policyTypePosition = policyTypeContent?.findIndex((item) => {
			return item.type === portfolioItem.type;
		});

		const typeContent: PolicyTypeItem = policyTypePosition >= 0 && policyTypeContent[policyTypePosition];

		const link = {
			url: `${baseLink.url}?${portfolioItem.type}=${getPolicyTypeCount(allTypes, portfolioItem.type)}&policy=${
				portfolioItem.policyNumberHash
			}`,
			name: portfolioItem.type,
		};

		return {
			link: link,
			icon: typeContent.icon,
			title: typeContent.title,
			description: portfolioItem.info,
			state: portfolioItem.state,
			price: convertNumber(portfolioItem.price),
			startDate: portfolioItem.startDate,
		};
	});

	return list;
};
