import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Status } from 'Online/dictionaryItems';
import { useContext } from 'react';
import { convertToUSDate } from 'Shared/utilities';
import styles from './Status.module.scss';
export interface StatusProps {
	className?: string;
	startDate: string;
	state: ProductState;
	style?: 'default' | 'contrast';
}

export const Status: React.FC<StatusProps> = ({ className, startDate, state, style = 'default' }) => {
	const dictionary = useContext(DictionaryContext);
	const handleStatusText = (): string => {
		if (state === 'pending') return _Status(dictionary, 'Pending');
		const todayDate = new Date();
		const activeDate = convertToUSDate(startDate);

		if (activeDate > todayDate) {
			return `${_Status(dictionary, 'ActiveFrom')} ${startDate}`;
		}
		return _Status(dictionary, 'Active');
	};

	return (
		<div className={classNames(styles.Status, styles[`Status___${style}`], className)}>
			<div className={styles.Status_wrapper}>
				<span className={classNames(styles.Status_color, styles[`Status_color___${state}`])}></span>
				<p className={styles.Status_text}>{handleStatusText()}</p>
			</div>
		</div>
	);
};
