const handleMissingUmbracoContent = (areaContent: ObjectDetail | Coverage) => {
	return {
		id: areaContent.id,
		title: `missing ${areaContent.id} - title: ${areaContent.title}`,
		description: null,
	};
};

export const getPolicyContentItemById = (
	policyTypeContent: PolicyCoverageItem[] | PolicyObjectDetails[],
	areaContent: ObjectDetail | Coverage,
): PolicyCoverageItem | PolicyObjectDetails => {
	if (!policyTypeContent) return null; // This is the policy number
	const match = policyTypeContent.filter((item) => {
		return item.id === areaContent.id;
	})[0];
	if (match) return match;
	return handleMissingUmbracoContent(areaContent);
};
