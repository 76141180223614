import bankList from 'Online/constants/banks';
import { DictionaryContext } from 'Online/context/Dictionary';
import { SiteContext } from 'Online/context/SiteContext';
import { _CallMeBackForm, _Errors } from 'Online/dictionaryItems';
import { getOpeningHours, OpeningHoursProps, registerCallBack, RequestState } from 'Online/utilities/requestCallBack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AttentionCard } from 'Shared';
import { extractTime, formatTimeOption } from 'Shared/utilities/date';
import { CallBackForm, Option } from './CallBackForm';

export interface CallBackFormPropsAPI {
	callSubjectList: string[];
	days: number;
	descriptionText?: string;
	title?: string;
}

export const CallBackFormAPI: React.FC<CallBackFormPropsAPI> = ({ days, callSubjectList, descriptionText, title }) => {
	const [openingHours, setOpeningHours] = useState<OpeningHoursProps[]>(null);
	const [timeOptions, setTimeOptions] = useState<Option[]>(null);
	const [selectedDate, setSelectedDate] = useState<OpeningHoursProps>(null);
	const [branchesOptions, setBranchesOptions] = useState<Option[]>(null);
	const [requestState, setRequestState] = useState<RequestState>(null);
	const dictionary = useContext(DictionaryContext);
	const siteContext = useContext(SiteContext);
	const utilityIcons: UtilityIcons = siteContext.getSettings('utilityIcons') ?? {};

	useEffect(() => {
		const fetchOpeningHours = async () => {
			const response = await getOpeningHours(days);
			setOpeningHours(response);
		};
		fetchOpeningHours();
	}, [days]);

	const handleSelectedDateIndex = useCallback(
		(dateIndex: string) => {
			if (!openingHours) return;
			const selectedDate = openingHours[dateIndex];
			const options = [];
			let sessionOpening = extractTime(selectedDate.open);
			const sessionClosing = extractTime(selectedDate.close);
			let timeOptionIndex = 0;
			while (sessionOpening < sessionClosing) {
				options.push({
					value: timeOptionIndex,
					text: formatTimeOption(sessionOpening) + ' - ' + formatTimeOption(sessionOpening + 1),
				});
				timeOptionIndex++;
				sessionOpening++;
			}
			options[0].selected = true;
			setTimeOptions(options);
			setSelectedDate(selectedDate);
		},
		[openingHours],
	);

	useEffect(() => {
		handleSelectedDateIndex('0');
	}, [handleSelectedDateIndex, openingHours]);

	const getCallSubjectList = (): Option[] => {
		return callSubjectList.map((subject) => {
			return { value: subject, text: subject };
		});
	};

	const getBankList = (): Option[] => {
		const bankListOptions = bankList.map((bank) => {
			return { value: bank.name, text: bank.name };
		});
		return bankListOptions.sort((a, b) => {
			return a.value.localeCompare(b.value);
		});
	};

	const handleSelectedBank = useCallback((selectedBank: string) => {
		const branches = bankList
			?.find((bank) => bank.name === selectedBank)
			?.branches.map((branch) => {
				return { value: branch.BRANCH_NAME, text: branch.BRANCH_NAME };
			});
		branches.sort((a, b) => {
			return a.value.localeCompare(b.value);
		});
		setBranchesOptions(branches);
	}, []);

	const handleRegisterCallBack = async (formData) => {
		const result = await registerCallBack(formData, selectedDate);
		setRequestState(result);
	};

	const handleAttentionCardText = (): string => {
		if (requestState == 'error') return _Errors(dictionary, 'IssuesWithOneOfOurSystems');
		if (requestState == 'info') return _CallMeBackForm(dictionary, 'AlreadyRegisteredNumber');
		return _CallMeBackForm(dictionary, 'YouWillBeCalledWithinTheDesiredTime');
	};

	const handleAttentionCardIcon = (): Image => {
		if (requestState == 'error') return utilityIcons.errorIcon;
		if (requestState == 'info') return utilityIcons.warningIcon;
		return utilityIcons.successIcon;
	};

	return requestState ? (
		<AttentionCard
			text={handleAttentionCardText()}
			settings={{
				properties: {
					type: requestState,
					size: 'medium',
				},
			}}
			icon={handleAttentionCardIcon()}
		/>
	) : (
		<CallBackForm
			banksList={getBankList()}
			bankBranchesList={branchesOptions}
			callSubjectList={getCallSubjectList()}
			descriptionText={descriptionText}
			openingHours={openingHours}
			registerCallBack={(formData) => handleRegisterCallBack(formData)}
			selectedDate={selectedDate}
			handleSelectedDateIndex={handleSelectedDateIndex}
			handleSelectedBank={handleSelectedBank}
			timeOptions={timeOptions}
			title={title}
		/>
	);
};
