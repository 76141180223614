import { ComponentProps } from 'Online/components/Component';
import { PageProps } from 'Online/context/PageContext';
import { PolicyDetailsPage } from 'Online/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectPolicies } from 'Online/store/slices/policySlice';
import { selectPolicyDetailState, setPolicyDetailModal } from 'Online/store/slices/policyDetailSlice';
import { selectPolicyTypeContent } from 'Online/store/slices/policyTypeContentSlice';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export interface PolicyDetailsPageAPIProps extends PageProps {
	pageSections: ComponentProps[];
	overviewTitle: string;
	downloadText: string;
}

export const PolicyDetailsPageAPI: React.FC<PolicyDetailsPageAPIProps> = (props) => {
	const dispatch = useDispatch();
	const router = useRouter();

	const policyData = useSelector(selectPolicies);
	const policyDetailState = useSelector(selectPolicyDetailState);
	const policyTypeContent = useSelector(selectPolicyTypeContent);
	const handleClose = () => {
		dispatch(setPolicyDetailModal(null));
	};
	useEffect(() => {
		dispatch(setPolicyDetailModal(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const basePath = router?.asPath.split('?')[1];

	if (!policyData || !basePath) router?.push('/');

	return (
		<>
			{policyData && basePath && (
				<PolicyDetailsPage
					{...props}
					policyData={policyData}
					policyTypeContent={policyTypeContent}
					state={policyDetailState}
					closeModal={handleClose}
				/>
			)}
		</>
	);
};
