import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';

export interface AdobeTagManagerProps {
	tagManagerUrl: string;
}

type tagManagerProperty = 'page view' | 'download file' | 'total policies';

interface tagManagerObject {
	page_name?: string;
	amount?: number;
	totalQuotes?: number;
	policyType?: string;
}

declare global {
	interface Window {
		_satellite: {
			track: (propertyName: tagManagerProperty, propertyObject: tagManagerObject) => void;
		};
	}
}

export const AdobeTagManager: React.FC<AdobeTagManagerProps> = (props) => {
	const router = useRouter();
	const [tagManagerLoaded, setTagManagerLoaded] = useState(false);
	const [lastTrack, setLastTrack] = useState('');

	useEffect(() => {
		if (window._satellite || tagManagerLoaded) {
			const page = router?.asPath;

			if (page && page !== lastTrack) {
				try {
					window._satellite.track('page view', { page_name: page });
				} catch (error) {
					console.log('Tracking could not be initialized for page ', page);
				}

				setLastTrack(page);
			}
		}
	}, [lastTrack, router?.asPath, tagManagerLoaded]);

	return (
		<>
			{props?.tagManagerUrl && (
				<Script
					strategy="afterInteractive"
					src={props?.tagManagerUrl}
					onLoad={() => {
						setTagManagerLoaded(true);
					}}
				/>
			)}
		</>
	);
};

export function dispatchTagManagerEvent(propertyName: tagManagerProperty, propertyObject: tagManagerObject): void {
	if (window._satellite)
		try {
			window._satellite.track(propertyName, propertyObject);
		} catch (error) {
			console.log('Could not track ', propertyName, propertyObject);
		}
}
