import {
	Component,
	ComponentProps,
	Footer,
	HeadingListItem,
	Layout,
	Main,
	MultipleCustomerNumbers,
	PolicyHeroBanner,
	PolicyOverview,
} from 'Online/components';
import { ErrorCodes } from 'Online/constants/errorCodes';
import { DictionaryContext } from 'Online/context/Dictionary';
import { PageContext } from 'Online/context/PageContext';
import { _Errors, _Greetings, _Status } from 'Online/dictionaryItems';
import { ActiveId } from 'Online/store/slices/partySlice';
import React, { useContext } from 'react';
import { AttentionCard, Container, HeroCard, PageLoader, ScrollTransition } from 'Shared';
import { PolicyOverviewPageAPIProps } from './PolicyOverviewPageAPI';

export interface PolicyOverviewPageProps extends PolicyOverviewPageAPIProps {
	headingList: HeadingListItem[];
	policyDetailLink: Link;
	upsellTitle: string;
	upsellText: string;
	confirmationImage?: Image;
	confirmationTitle: string;
	confirmationText: string;
	pageSections: ComponentProps[];
	topContent: ComponentProps[];
	policyData: PolicyData & StatusHandling;
	partyData: PartyData & StatusHandling & ActiveId;
	policyTypeContent: PolicyTypeItem[];
}

export const PolicyOverviewPage: React.FC<PolicyOverviewPageProps> = (props) => {
	const page = React.useContext(PageContext);
	const dictionary = useContext(DictionaryContext);

	const { status: partyApiStatus, error: partyApiErrorCode, partyObjects, activeId } = props?.partyData;
	const { status: policyApiStatus, quotesCount: numberOfQuotes, portfolio } = props?.policyData;

	const multipleCustomerNumbers = partyApiStatus === 'success' && props?.partyData?.partyObjects?.length > 1;
	const numberOfPolicies = props?.policyData?.portfolio?.length;
	const quotesLink = props?.quotesLink;
	const userIsNonCustomer =
		(policyApiStatus === 'success' && !(portfolio?.length > 0)) ||
		partyApiErrorCode === ErrorCodes.customerNotFound;

	return (
		<>
			{partyApiStatus !== 'pending' && (
				<Layout {...props}>
					<Main>
						{multipleCustomerNumbers && activeId === null ? (
							<MultipleCustomerNumbers customerItems={partyObjects} />
						) : partyApiStatus !== 'error' ||
						  numberOfPolicies > 0 ||
						  partyApiErrorCode === ErrorCodes.customerNotFound ? (
							<>
								{props?.pageHeader?.length &&
									props.pageHeader.map((headerModule, index) => (
										<Component key={index} {...headerModule} />
									))}

								{!props?.pageHeader?.length && (
									<>
										{(numberOfQuotes > 0 ||
											numberOfPolicies > 0 ||
											policyApiStatus !== 'success') &&
										partyApiErrorCode !== ErrorCodes.customerNotFound ? (
											<PolicyHeroBanner
												firstName={partyObjects[0].name}
												quotesCount={numberOfQuotes}
												quotesLink={quotesLink}
											/>
										) : (
											<HeroCard
												settings={{ properties: { style: 'minimal' } }}
												heading={_Greetings(dictionary, 'Hi')}
												teaser={_Greetings(dictionary, 'PolicyOverviewGreetingNoCustomer')}
											/>
										)}
									</>
								)}
								{props?.topContent &&
									activeId !== null &&
									policyApiStatus === 'success' &&
									props.topContent.map((sectionModule, index) => (
										<Component key={index} {...sectionModule} />
									))}
								<Container width="Medium">
									<ScrollTransition>
										<PolicyOverview {...props} userIsNonCustomer={userIsNonCustomer} />
									</ScrollTransition>
								</Container>
							</>
						) : (
							<AttentionCard
								text={_Errors(dictionary, 'FetchError')}
								settings={{
									properties: {
										type: 'error',
										size: 'large',
									},
								}}
							/>
						)}

						{props?.pageSections &&
							activeId !== null &&
							policyApiStatus === 'success' &&
							props.pageSections.map((sectionModule, index) => (
								<Component key={index} {...sectionModule} />
							))}
					</Main>

					{page?.footer && <Footer components={page.footer} />}
				</Layout>
			)}
			{partyApiStatus === 'pending' && <PageLoader title={_Status(dictionary, 'Loading')} />}
		</>
	);
};
