import { Component, Footer, Layout, Main } from 'Online/components';
import { ComponentProps } from 'Online/components/Component';
import { PageContext, PageProps } from 'Online/context/PageContext';
import React, { useContext } from 'react';

export interface ContentPageProps extends PageProps {
	pageSections: ComponentProps[];
	pageHeader: ComponentProps[];
}

export const ContentPage: React.FC<ContentPageProps> = (props) => {
	const page = useContext(PageContext);
	return (
		<Layout {...props}>
			<Main
				header={props.pageHeader.map((headerModule, index) => (
					<Component key={index} {...headerModule} />
				))}
			>
				{props.pageSections.map((sectionModule, index) => (
					<Component key={index} {...sectionModule} />
				))}
			</Main>
			{page?.footer && <Footer components={page.footer} />}
		</Layout>
	);
};
