import classNames from 'classnames';
import styles from './SiteAreas.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentSiteAreaName, setCurrentSiteArea } from 'Online/store/slices/siteAreaSlice';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { capitalize } from 'Shared/utilities';

export interface SiteAreasProps {
	className?: string;
	siteAreas: Link[];
}

export const SiteAreas: React.FC<SiteAreasProps> = ({ className, siteAreas }) => {
	const currentSiteArea = useSelector(selectCurrentSiteAreaName);
	const dispatch = useDispatch();

	const router = useRouter();
	const paths = router?.asPath.split('/');
	const topPath = paths[1];

	useEffect(() => {
		if (topPath === '' && currentSiteArea != siteAreas[0].name) {
			dispatch(setCurrentSiteArea(siteAreas[0].name));
			return;
		}
		siteAreas.forEach((siteArea) => {
			if (topPath === siteArea.name.toLowerCase() && currentSiteArea.toLowerCase() != topPath) {
				dispatch(setCurrentSiteArea(capitalize(topPath)));
			}
		});
	}, [topPath, siteAreas, currentSiteArea, dispatch]);

	return (
		<div className={classNames(styles.SiteAreas, className)}>
			{siteAreas.map((siteAreaItem, index) => {
				return (
					<a
						className={classNames(
							styles.SiteAreas_item,
							currentSiteArea === siteAreaItem.name ? styles.SiteAreas_item___current : '',
						)}
						href={siteAreaItem.url}
						key={index}
					>
						{siteAreaItem.name}
					</a>
				);
			})}
		</div>
	);
};
