import classNames from 'classnames';
import { Label } from 'Shared';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './DocumentsDateSlider.module.scss';

export interface DocumentsDateSliderProps {
	className?: string;
	yearsList: string[];
	setSelectedYear: (selectedYear: string) => void;
}

export const DocumentsDateSlider: React.FC<DocumentsDateSliderProps> = ({ yearsList, setSelectedYear, className }) => {
	return (
		<Swiper
			speed={800}
			spaceBetween={40}
			navigation
			className={classNames(styles.DocumentsDateSlider, className)}
			slidesPerView={'auto'}
		>
			<form className={styles.DocumentsDateSlider_dateFilters}>
				{yearsList.map((item, index) => {
					return (
						<SwiperSlide key={index} className={styles.DocumentsDateSlider_dateSlide}>
							<input
								id={item}
								type="radio"
								name="selectedDate"
								value={item}
								onChange={() => setSelectedYear(item)}
								className={classNames(styles.DocumentsDateSlider_dateInput, 'u-visually-hidden')}
								defaultChecked={index == 0 && true}
							/>
							<Label className={styles.DocumentsDateSlider_dateInputLabel} id={item}>
								{item}
							</Label>
						</SwiperSlide>
					);
				})}
			</form>
		</Swiper>
	);
};
