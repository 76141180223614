/* eslint-disable @typescript-eslint/no-empty-function */
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Price } from 'Online/dictionaryItems';
import React, { useContext } from 'react';
import { Button } from 'Shared';
import styles from './ProductStickyBanner.module.scss';

export interface ProductStickyBannerProps {
	price: string;
	priceAnnual: string;
	button: boolean;
}

export const ProductStickyBanner: React.FC<ProductStickyBannerProps> = ({ price, priceAnnual, button }) => {
	const dictionary = useContext(DictionaryContext);
	return (
		<>
			<div className={styles.ProductStickyBanner}>
				<div className={styles.ProductStickyBanner_Inner}>
					<p className={styles.ProductStickyBanner_PriceInfo}>{_Price(dictionary, 'YourMonthlyPrice')}</p>
					<div className={styles.ProductStickyBanner_PriceContainer}>
						<p className={styles.ProductStickyBanner_Price}>{price}</p>
						<span className={styles.ProductStickyBanner_PriceAnnual}>
							{priceAnnual}
							{/* ToDo Button functionality, umbraco content, mobile view */}
							{button && (
								<Button
									style="primary"
									type="submit"
									aria-label="Bestil tid"
									className={styles.ProductStickyBanner_Button}
								>
									Bestil Tid
								</Button>
							)}
						</span>
					</div>
				</div>
			</div>
			<style global jsx>
				{`
					button[data-testid='raffle-search-launcher-button'] {
						bottom: 130px !important;
					}
					@media (min-width: 500px) {
						div[data-testid='raffle-search-launcher'] {
							bottom: 202px !important;
							max-height: calc(100vh - 14em) !important;
						}
					}
				`}
			</style>
		</>
	);
};
