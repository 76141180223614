import getConfig from 'next/config';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Status } from 'Online/dictionaryItems';
import { selectParty } from 'Online/store/slices/partySlice';
import { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageLoader } from 'Shared';
import { debounce } from 'Shared/utilities';
import styles from './OutsystemsIframe.module.scss';

export interface OutsystemsIframeProps {
	className?: string;
	iframePath: string;
	addCustomerNumber: boolean;
	description: string;
}

export const OutsystemsIframe: React.FC<OutsystemsIframeProps> = ({ iframePath, addCustomerNumber, description }) => {
	const dictionary = useContext(DictionaryContext);

	const [iFrameHeight, setIframeHeight] = useState(null);
	const [iFrameLoaded, setIframeLoaded] = useState(false);

	const FRAME_HEIGHT = 'FrameHeight';
	const REDIRECT_TO = 'RedirectTo';
	const SCROLL_TO = 'ScrollTo';

	const { publicRuntimeConfig } = getConfig();
	const outsystemsBaseUrl = publicRuntimeConfig.NEXT_PUBLIC_OUTSYSTEMS_BASE_URL;
	let iframeUrl = outsystemsBaseUrl + iframePath;

	const partyData = useSelector(selectParty);

	const activeParty = partyData?.partyObjects?.find((obj) => obj.id === partyData?.activeId);

	if (addCustomerNumber && activeParty?.idAlt) {
		if (iframePath.includes('?')) {
			const pathSplit = iframePath?.split('?');
			iframeUrl = outsystemsBaseUrl + pathSplit[0] + '?customers=' + activeParty?.idAlt + '&' + pathSplit[1];
		} else {
			iframeUrl += '?customers=' + activeParty?.idAlt;
		}
	}

	const ifrm = useRef();

	window.addEventListener(
		'resize',
		debounce(() => {
			postIframeHeight(ifrm);
		}),
	);

	window.addEventListener('message', function (event) {
		const { data } = event;
		if (Object.prototype.hasOwnProperty.call(data, FRAME_HEIGHT)) {
			setIframeHeight(event.data.FrameHeight);
		}

		if (Object.prototype.hasOwnProperty.call(data, REDIRECT_TO)) {
			setRedirectTo(event.data.RedirectTo);
		}

		if (Object.prototype.hasOwnProperty.call(data, SCROLL_TO)) {
			setScrollTo(event.data.ScrollTo);
		}
	});

	function postIframeHeight(ifrm) {
		if (ifrm != null && ifrm.contentWindow != null) {
			ifrm.contentWindow.postMessage(FRAME_HEIGHT, '*');
		}
	}

	function setRedirectTo(url) {
		window.location.replace(url);
	}

	function setScrollTo(element) {
		document.querySelector(element).scrollIntoView({
			behavior: 'smooth',
		});
	}

	const style = {
		height: iFrameHeight + 'px',
	};

	return (
		<div className={styles.Iframe}>
			{!iFrameLoaded && (
				<PageLoader maxHeight="41rem" title={_Status(dictionary, 'Loading')} className={styles.Iframe_loader} />
			)}
			<iframe
				ref={ifrm}
				className={styles.Iframe_asset}
				src={iframeUrl}
				onLoad={() => setIframeLoaded(true)}
				title={description}
				width="100%"
				style={style}
			></iframe>
		</div>
	);
};
