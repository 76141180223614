import { signOut, useSession } from 'next-auth/react';
import getConfig from 'next/config';
import { Component, Footer, Layout, Main } from 'Online/components';
import { ComponentProps } from 'Online/components/Component';
import { DictionaryContext } from 'Online/context/Dictionary';
import { PageContext, PageProps } from 'Online/context/PageContext';
import { _Status } from 'Online/dictionaryItems';
import React, { useContext, useEffect } from 'react';
import { PageLoader } from 'Shared';
import { getCookieByName } from 'Shared/utilities/getCookieByName';
export interface LogOutPageProps extends PageProps {
	pageSections: ComponentProps[];
	pageHeader: ComponentProps[];
}

export const LogOutPage: React.FC<LogOutPageProps> = (props) => {
	const page = useContext(PageContext);
	const dictionary = useContext(DictionaryContext);
	const { status } = useSession();
	const { publicRuntimeConfig } = getConfig();
	const themeName = page.theme;
	const baseUrl = publicRuntimeConfig.NEXT_PUBLIC_NEXTAUTH_URL;
	const outsystemsBaseUrl = publicRuntimeConfig.NEXT_PUBLIC_OUTSYSTEMS_BASE_URL;

	useEffect(() => {
		const expirationDate = Number(getCookieByName('X-Expiration-Date'));
		if (!isNaN(expirationDate)) {
			signOut({
				callbackUrl: baseUrl + '/api/auth/logout',
			});
		}
	}, [baseUrl]);

	if (status === 'authenticated' || status === 'loading') {
		return <PageLoader title={_Status(dictionary, 'Loading')} />;
	} else {
		return (
			<Layout {...props}>
				<Main
					header={props.pageHeader.map((headerModule, index) => (
						<Component key={index} {...headerModule} />
					))}
				>
					{props.pageSections.map((sectionModule, index) => (
						<Component key={index} {...sectionModule} />
					))}
				</Main>
				{page?.footer && <Footer components={page.footer} />}
				<iframe
					className="u-hidden"
					src={outsystemsBaseUrl + '/IdPChannels/Logout.aspx?Tenant=Mit' + themeName}
				></iframe>
			</Layout>
		);
	}
};
