import { Dictionary } from 'Online/context/Dictionary';

export type GreetingsDictionaries =
	| 'Hi'
	| 'PolicyOverviewGreeting'
	| 'PolicyOverviewGreetingNoCustomer'
	| 'MultipleCustomerNoGreeting'
	| 'PolicyQuoteGreeting.YouHave'
	| 'PolicyQuoteGreeting.NewPolicyQuotes';

export const _Greetings = (dictionary: Partial<Dictionary>, item: GreetingsDictionaries): string => {
	switch (item) {
		case 'Hi':
			return dictionary.getValue('Greetings.Hi', null, 'Hej');
		case 'PolicyOverviewGreeting':
			return dictionary.getValue(
				'Greetings.PolicyOverviewGreeting',
				null,
				'Velkommen til din forsikringsoversigt',
			);
		case 'PolicyOverviewGreetingNoCustomer':
			return dictionary.getValue(
				'Greetings.PolicyOverviewGreetingNoCustomer',
				null,
				'Du er endnu ikke registreret som kunde hos os.',
			);
		case 'MultipleCustomerNoGreeting':
			return dictionary.getValue(
				'Greetings.MultipleCustomerNoGreeting',
				null,
				'Hov! Det lader til, at du har flere kundenumre hos os.',
			);
		case 'PolicyQuoteGreeting.YouHave':
			return dictionary.getValue('Greetings.PolicyQuoteGreeting.YouHave', null, 'Du har');

		case 'PolicyQuoteGreeting.NewPolicyQuotes':
			return dictionary.getValue(
				'Greetings.PolicyQuoteGreeting.NewPolicyQuotes',
				null,
				'nye forsikringstilbud...',
			);
	}
};
