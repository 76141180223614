import getConfig from 'next/config';
import Head from 'next/head';
import { AdobeTagManager, CookieBot, RaffleScript, Sleeknote, TeliaChat } from 'Online/components';
import { PageContext, PageProps } from 'Online/context/PageContext';
import React, { useContext } from 'react';

export interface MetaProps extends PageProps {
	noFollow?: boolean;
	noIndex?: boolean;
	title?: string;
	description?: string;
	url?: string;
	children?: React.ReactNode;
}

export const Meta: React.FC<MetaProps> = (props) => {
	const { publicRuntimeConfig } = getConfig();
	const pageContext = useContext(PageContext);

	const tagManagerUrl = publicRuntimeConfig.NEXT_PUBLIC_ADOBE_TRACKING_URL;
	const cookieBotUrl = publicRuntimeConfig.NEXT_PUBLIC_COOKIEBOT_URL;
	const cookieBotDomainGroupId = publicRuntimeConfig.NEXT_PUBLIC_COOKIEBOT_DOMAINID;

	const teliaChatUrl = publicRuntimeConfig.NEXT_PUBLIC_TELIACHAT_URL;
	const teliaChatInterfaceName = publicRuntimeConfig.NEXT_PUBLIC_TELIACHAT_INTERFACE_NAME;
	const raffleScriptUrl = publicRuntimeConfig.NEXT_PUBLIC_RAFFELSCRIPT_URL;
	const raffleScriptDataUid = publicRuntimeConfig.NEXT_PUBLIC_RAFFELSCRIPT_DATAUID;
	const sleeknoteUrl = publicRuntimeConfig.NEXT_PUBLIC_SLEEKNOTE_URL;

	return (
		<>
			<Head>
				<title>{props.title ? props.title : pageContext.name}</title>
				<meta
					name="robots"
					content={`${props.noFollow ? 'nofollow' : 'follow'}, ${props.noIndex ? 'noindex' : 'index'}`}
				/>
				<meta name="description" content={props.description} />
				{props?.children}
			</Head>
			{tagManagerUrl && <AdobeTagManager tagManagerUrl={tagManagerUrl} />}
			{sleeknoteUrl && <Sleeknote sleeknoteUrl={sleeknoteUrl} />}
			{cookieBotUrl && cookieBotDomainGroupId && (
				<CookieBot cookieBotUrl={cookieBotUrl} cookieBotDomainGroupId={cookieBotDomainGroupId} />
			)}

			{teliaChatUrl && teliaChatInterfaceName && (
				<TeliaChat teliaChatUrl={teliaChatUrl} teliaChatInterfaceName={teliaChatInterfaceName} />
			)}
			{raffleScriptUrl && raffleScriptDataUid && (
				<RaffleScript raffleScriptUrl={raffleScriptUrl} raffleScriptDataUid={raffleScriptDataUid} />
			)}
		</>
	);
};
