import { Component } from 'Online/components';
import { ComponentProps } from 'Online/components/Component';
import { toKebabCase } from 'Shared/utilities';

export interface HeaderSectionProps {
	headerSection?: ComponentProps[];
	settings?: {
		properties: {
			backgroundColor?: string;
		};
	};
}

export const HeaderSection: React.FC<HeaderSectionProps> = ({ headerSection, settings }) => {
	const { backgroundColor } = settings?.properties ?? {};
	return (
		<div className={backgroundColor && `u-header-section--background-${toKebabCase(backgroundColor)}`}>
			{headerSection && headerSection.map((singleHeader, index) => <Component key={index} {...singleHeader} />)}
		</div>
	);
};
