import classNames from 'classnames';
import { useRouter } from 'next/router';
import { dispatchTagManagerEvent, ProductDetailsListItem } from 'Online/components';
import { DictionaryContext } from 'Online/context/Dictionary';
import { PageContext } from 'Online/context/PageContext';
import { _Actions, _Errors, _Policy, _Units } from 'Online/dictionaryItems';
import { ReactComponent as DownloadIcon } from 'Online/public/icons/icon-download.svg';
import { ReactComponent as WarningIcon } from 'Online/public/icons/icon-warning.svg';
import { setPolicyDetailModal } from 'Online/store/slices/policyDetailSlice';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Heading, Modal, Spinner } from 'Shared';
import { getPolicyDocument } from './getPolicyDocument';
import styles from './ProductDetails.module.scss';
export interface ProductDetailsProps {
	title: string;
	portfolioItem: PortfolioItem;
	policyTypeItem: PolicyTypeItem;
	policyIsChanging: boolean;
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
	title,
	portfolioItem,
	policyTypeItem,
	policyIsChanging,
}) => {
	const dictionary = useContext(DictionaryContext);
	const policyNumberDetail = {
		id: 'policyNumber',
		title: _Units(dictionary, 'Policy') + _Units(dictionary, 'NumberAbbreviation'),
		value: portfolioItem.policyNumber,
	};
	const dispatch = useDispatch();
	const router = useRouter();
	const pageContext = useContext(PageContext);
	const themeName = pageContext.theme;

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const linkRef = useRef(null);

	const handleDownloadButton = async () => {
		setLoading(true);
		const policyNumber = policyNumberDetail.value;
		const policyTitle = policyTypeItem?.title;

		let document = await getPolicyDocument(policyNumber, themeName);
		if (!document) {
			setLoading(false);
			return setError(true);
		}
		linkRef.current.download = `${policyTitle} ${_Units(dictionary, 'Policy')}${_Units(
			dictionary,
			'Number',
		)} ${policyNumber}`;
		linkRef.current.href = 'data:application/pdf;base64,' + document.fileContents;
		linkRef.current.click();
		setLoading(false);
		document = null;

		dispatchTagManagerEvent('download file', { policyType: policyTitle });
	};

	useEffect(() => {
		const basePath = router?.asPath;
		if (error) {
			router?.push(`${basePath}&error=policydownload`, undefined, { shallow: true });
		}
		if (!error) {
			const basePath = router?.asPath.split('&')[0];
			router?.push(`${basePath}`, undefined, { shallow: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	useEffect(() => {
		if (policyIsChanging) setLoading(false);
	}, [policyIsChanging]);

	const errorModal = () => (
		<Modal
			handleClose={() => setError(false)}
			style="simple"
			role="alert"
			iconSVG={
				<div className={styles.ProductDetails_errorModalIcon}>
					<WarningIcon className={styles.ProductDetails_warningIcon} />
				</div>
			}
			title={_Errors(dictionary, 'SomethingWentWrong')}
			className={styles.ProductDetails_errorModal}
			closeText={_Actions(dictionary, 'CancelAndClose')}
		>
			<div className={styles.ProductDetails_errorModalContent}>
				<p>{_Errors(dictionary, 'LinkToPolicyError')}</p>
				<p>{_Errors(dictionary, 'TryAgainLater')}</p>
			</div>
		</Modal>
	);

	const handleModal = (areaContent: ObjectDetail | Coverage, area: 'object' | 'coverage') => {
		const payload = {
			areaContent,
			area,
			active: true,
		};
		dispatch(setPolicyDetailModal(payload));
	};

	return (
		<div className={styles.ProductDetails}>
			<div className={styles.ProductDetails_top}>
				<Heading headingLevel="h2" className={styles.Productdetails_title}>
					{title}
				</Heading>
				{portfolioItem.isDownloadable && (
					<Button
						className={styles.ProductDetails_downloadButton}
						style="secondary"
						size="medium"
						onClick={handleDownloadButton}
						disabled={loading}
					>
						{loading ? (
							<div
								className={classNames(
									styles.ProductDetails_downloadButtonSpinner,
									styles.ProductDetails_downloadButtonIcon,
								)}
							>
								<Spinner type={'dottedCircle'} style="small" />
							</div>
						) : (
							<DownloadIcon className={styles.ProductDetails_downloadButtonIcon} />
						)}

						<p className={styles.ProductDetails_buttonText}>
							{_Policy(dictionary, 'DownloadPolicy').toLocaleUpperCase()}
						</p>
					</Button>
				)}
				<a className="u-hidden" aria-hidden="true" ref={linkRef} />
			</div>

			<dl className={classNames(styles.ProductDetails_list, styles.ProductDetails_list___objectDetails)}>
				<ProductDetailsListItem
					style="emphasis"
					areaContent={policyNumberDetail as ObjectDetail}
					area="object"
				/>
				{portfolioItem.objectDetails.map((detail, index) => {
					const numberValue = Number(detail.value);
					let negativeValue: boolean;
					if (numberValue) {
						negativeValue = numberValue < 0 ? false : true;
					}
					if (negativeValue || !numberValue) {
						return (
							<ProductDetailsListItem
								style="emphasis"
								key={index}
								areaContent={detail}
								area="object"
								callback={() => handleModal(detail, 'object')}
								policyTypeContent={policyTypeItem?.objectDetails}
							/>
						);
					}
				})}
			</dl>
			<dl className={classNames(styles.ProductDetails_list, styles.ProductDetails_list___coverages)}>
				{portfolioItem.coverages.map((coverage, index) => {
					return (
						coverage?.active && (
							<ProductDetailsListItem
								key={index}
								areaContent={coverage}
								area="coverage"
								state={portfolioItem.state}
								callback={() => handleModal(coverage, 'coverage')}
								policyTypeContent={policyTypeItem?.coverages}
							/>
						)
					);
				})}
			</dl>
			{error && errorModal()}
		</div>
	);
};
