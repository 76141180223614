import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import { PageContext } from 'Online/context/PageContext';
import { _DocumentsOverview, _Units } from 'Online/dictionaryItems';
import { ReactComponent as DownloadIcon } from 'Online/public/icons/icon-download.svg';
import { useContext, useRef, useState } from 'react';
import { Button, Image, Spinner } from 'Shared';
import { getDanishDate } from 'Shared/utilities/date';
import { getPolicyDocumentByArchiveKey } from '../getPolicyDocumentByArchiveKey';
import styles from './DocumentItem.module.scss';

export interface DocumentItemProps {
	className?: string;
	document: DocumentDataItem;
	icon?: Image;
	setError: (error: boolean) => void;
}

export const DocumentItem: React.FC<DocumentItemProps> = ({ document, icon, setError, className }) => {
	const pageContext = useContext(PageContext);
	const themeName = pageContext.theme;
	const [loading, setLoading] = useState(false);
	const linkRef = useRef(null);
	const dictionary = useContext(DictionaryContext);
	const { title, id, category, description, date, relatedPolicy } = document;

	const handleDownloadButton = async () => {
		setLoading(true);
		let document = await getPolicyDocumentByArchiveKey(id, themeName);
		if (!document) {
			setLoading(false);
			return setError(true);
		}
		linkRef.current.download = `${title} #${relatedPolicy}`;
		linkRef.current.download = `${description} ${_Units(dictionary, 'Policy')}${_Units(
			dictionary,
			'Number',
		)} ${relatedPolicy}`;
		linkRef.current.href = 'data:application/pdf;base64,' + document.fileContents;
		linkRef.current.click();
		setLoading(false);
		document = null;
	};

	return (
		<div className={classNames(styles.DocumentItem, className)}>
			<div className={classNames(styles.DocumentItem_column, styles.DocumentItem_IconWrapper)}>
				{icon && (
					<Image
						className={styles.DocumentItem_icon}
						url={icon?.url}
						sizes="3rem"
						properties={icon?.properties}
						isCover={false}
					/>
				)}
				<p>
					<span>{title}</span>
					{category == 'Policer og tilbud' && <span> #{relatedPolicy}</span>}
				</p>
			</div>
			<p className={styles.DocumentItem_column}>{description}</p>
			<p className={styles.DocumentItem_column}>{getDanishDate(new Date(date))}</p>
			<Button
				className={styles.DocumentItem_downloadButton}
				style="secondary"
				size="medium"
				onClick={() => handleDownloadButton()}
				disabled={loading}
			>
				{loading ? (
					<div
						className={classNames(
							styles.DocumentItem_downloadButtonSpinner,
							styles.DocumentItem_downloadButtonIcon,
						)}
					>
						<Spinner type={'dottedCircle'} style="small" />
					</div>
				) : (
					<DownloadIcon className={styles.DocumentItem_downloadButtonIcon} />
				)}
				<p className={styles.DocumentItem_buttonText}>{_DocumentsOverview(dictionary, 'DownloadDocument')}</p>
			</Button>
			<a className="u-hidden" aria-hidden="true" ref={linkRef} />
		</div>
	);
};
