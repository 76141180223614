import {
	ArticleListAPI,
	CallBackFormAPI,
	ColumnsLayout,
	ContentSection,
	HeaderSection,
	LinkSelectorModule,
	Logo,
	MasterCardListAPI,
	OutsystemsIframe,
	PlusCustomerButton,
	ProductPromo,
	USPBlock,
	USPItem,
} from 'Online/components';
import { DictionaryContext } from 'Online/context/Dictionary';
import { SiteContext } from 'Online/context/SiteContext';
import { useContext } from 'react';
import {
	Accordion,
	Anchor,
	ArticleByline,
	ArticleMedia,
	ArticleText,
	AttentionCard,
	BlurbCard,
	CheckmarkListModule,
	ContentCard,
	ContentTeaser,
	Download,
	Factbox,
	FooterCard,
	FooterNavigation,
	FullGridWidthImage,
	HeroCard,
	Iframe,
	InfoboxModule,
	List,
	Newsletter,
	ProductSection,
	RichText,
	ScrollTransition,
	SimplePicture,
	Slider,
	TestimonialCard,
	Video,
	VideoCard,
} from 'Shared';
import { logger } from 'Shared/utilities';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ComponentProps<TContent = any, TSettings = any> {
	documentType?: string;
	content: {
		properties: TContent;
	};
	settings?: {
		documentType?: string;
		properties?: TSettings;
	};
}

const GlobalElements = () => {
	const siteContext = useContext(SiteContext);
	return siteContext.getSettings('globalElements') ?? {};
};

const GlobalDictionary = (valueKey: string, defaultValue: string): string => {
	const dictionary = useContext(DictionaryContext);

	const dictionaryString = dictionary.getValue(valueKey, null, defaultValue);

	return dictionaryString;
};

export const Component: React.FC<ComponentProps> = (props) => {
	const components = {
		anchor: Anchor,
		accordion: Accordion,
		articleByline: ArticleByline,
		articleMedia: ArticleMedia,
		articleText: ArticleText,
		articleList: ArticleListAPI,
		attentionCard: AttentionCard,
		blurbCard: BlurbCard,
		callBackForm: CallBackFormAPI,
		columnsLayout: ColumnsLayout,
		columnsRichText: RichText,
		columnsImage: SimplePicture,
		contentCard: ContentCard,
		contentSectionPSK: ContentSection,
		contentSectionESK: ContentSection,
		contentTeaser: ContentTeaser,
		checkmarkListModule: CheckmarkListModule,
		download: Download,
		factbox: Factbox,
		footerCard: FooterCard,
		footerNavigation: FooterNavigation,
		fullGridWidthImage: FullGridWidthImage,
		infoboxModule: InfoboxModule,
		headerSectionESK: HeaderSection,
		headerSectionPSK: HeaderSection,
		heroCard: HeroCard,
		iframe: Iframe,
		list: List,
		linkSelectorModule: LinkSelectorModule,
		logo: Logo,
		masterCardList: MasterCardListAPI,
		newsletterSignup: Newsletter,
		outsystemsIframe: OutsystemsIframe,
		plusCustomerButton: PlusCustomerButton,
		productPromo: ProductPromo,
		productSection: ProductSection,
		slider: Slider,
		testimonialCard: TestimonialCard,
		uspBlock: USPBlock,
		uspItem: USPItem,
		video: Video,
		videoCard: VideoCard,
	};

	const DynamicComponent = components[props.documentType];
	if (typeof DynamicComponent === 'undefined') {
		// TODO: Should it break in build?
		logger.error(`Component [${props.documentType}] is missing a React Component. Check /components/Component.tsx`);
		return (
			<div>{`Component [${props.documentType}] is missing a React Component. Check /components/Component.tsx`}</div>
		);
	}

	const noTransitionComponents = [
		Slider,
		ContentSection,
		FooterCard,
		FooterNavigation,
		Logo,
		HeaderSection,
		HeroCard,
	];

	const component = () => {
		if (noTransitionComponents.includes(DynamicComponent)) {
			return (
				<DynamicComponent
					{...props.content.properties}
					settings={props.settings}
					globalElements={GlobalElements()}
					dictionaryContext={GlobalDictionary}
				/>
			);
		} else {
			return (
				<ScrollTransition>
					<DynamicComponent
						{...props.content.properties}
						settings={props.settings}
						globalElements={GlobalElements()}
						dictionaryContext={GlobalDictionary}
					/>
				</ScrollTransition>
			);
		}
	};

	return component();
};
