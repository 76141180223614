import classNames from 'classnames';
import { useSession } from 'next-auth/react';
import { Logo, LogoutButton, ProfileLinks, SiteAreas } from 'Online/components';
import { ErrorCodes } from 'Online/constants/errorCodes';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Navigation } from 'Online/dictionaryItems';
import { selectCartVisibility, toggleCartVisiblity } from 'Online/store/slices/cartSlice';
import { selectParty } from 'Online/store/slices/partySlice';
import { selectSiteAreas } from 'Online/store/slices/siteAreaSlice';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridCell, Navigation, SkipToMain } from 'Shared';
import styles from './Header.module.scss';

export interface HeaderProps {
	className?: string;
	navHeading?: string;
	navigationItems?: NavigationItem[];
	externalNavigationItems?: Link[];
	userLinks?: NavigationItem[];
	utilityIcons?: UtilityIcons;
	theme?: ThemeName;
}

export const Header: React.FC<HeaderProps> = ({
	className,
	navHeading,
	navigationItems,
	externalNavigationItems,
	userLinks,
	utilityIcons,
	theme,
}) => {
	const { userIcon, logoutIcon } = utilityIcons ?? {};
	const dispatch = useDispatch();
	const { data: session, status } = useSession();
	const dictionary = useContext(DictionaryContext);

	const shoppingCartIsOpen = useSelector(selectCartVisibility);
	const siteAreas = useSelector(selectSiteAreas);

	const [navigationIsOpen, setNavigationIsOpen] = useState(false);
	const [profileLinksIsOpen, setProfileLinksIsOpen] = useState(false);
	const [userLinksIsOpen, setUserLinksIsOpen] = useState(false);

	const partyData = useSelector(selectParty);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleCartVisibility = (hide: boolean) => {
		dispatch(toggleCartVisiblity(hide));
	};

	useEffect(() => {
		// Close ProfileLinks if shopping cart is open
		shoppingCartIsOpen && setProfileLinksIsOpen(false);
	}, [shoppingCartIsOpen]);

	useEffect(() => {
		for (let i = 0; i < userLinks?.length; i++) {
			if (userLinks[i].state === 'isActive') {
				setUserLinksIsOpen(true);
			}
		}
	}, [userLinks]);

	return (
		<>
			<header
				className={classNames(
					styles.Header,
					styles[`Header___${theme}`],
					navigationIsOpen && styles.Header___isOpen,
					className,
				)}
				role="banner"
			>
				<SkipToMain link="#main" title="Click to skip navigations and go directly to content section">
					Skip to content
				</SkipToMain>

				{externalNavigationItems?.length && !navigationIsOpen && (
					<div className={styles.Header_external}>
						<Navigation
							className={styles.Header_external___navbar}
							externalNavigationItems={externalNavigationItems}
						/>
					</div>
				)}

				<Grid nogutter={true} center={true} className={styles.Header_container}>
					<Logo style={navigationIsOpen ? 'Light' : 'Dark'} enforceDarkStyleForDesktop margined />

					{/*Not part of 1st delivery
						<Search style="expanded" navigationIsOpen={navigationIsOpen} searchIcon={searchIcon} />
					*/}

					{navigationItems ? (
						<div className={classNames(styles.Header_navigation)}>
							<button
								type="button"
								aria-label={
									navigationIsOpen
										? _Navigation(dictionary, 'NavigationIsOpen')
										: _Navigation(dictionary, 'NavigationIsClosed')
								}
								className={classNames(
									styles.Header_navToggle,
									navigationIsOpen && styles.Header_navToggle___isOpen,
								)}
								onClick={() => setNavigationIsOpen(!navigationIsOpen)}
							>
								<span className={styles.Header_icon}>
									<span className={styles.Header_iconBar}></span>
									<span className={styles.Header_iconBar}></span>
									<span className={styles.Header_iconBar}></span>
									<span className={styles.Header_iconBar}></span>
								</span>
							</button>

							<div
								className={classNames(
									styles.Header_nav,
									navigationIsOpen && styles.Header_nav___isOpen,
								)}
							>
								<Grid nogutter={true} wrap={true}>
									<GridCell>
										<Navigation heading={navHeading} navigationItems={navigationItems}>
											{siteAreas ? <SiteAreas siteAreas={siteAreas} /> : null}

											{userLinks?.length ? (
												<ProfileLinks
													profileLinks={session && status !== 'unauthenticated' && userLinks}
													profileLinksIsOpen={(isOpen) => setUserLinksIsOpen(isOpen)}
													profileLinksVisible={userLinksIsOpen}
												/>
											) : null}

											<div className={styles.Header_navMobileBottomSection}>
												{session && status !== 'unauthenticated' ? (
													<div className={styles.Header_navMobileBottomSectionItem}>
														<LogoutButton
															className={styles.Header___mobileLogout}
															icon={logoutIcon ? logoutIcon : null}
														/>
													</div>
												) : null}
											</div>
										</Navigation>
									</GridCell>
								</Grid>
							</div>
						</div>
					) : null}

					<div
						className={classNames(
							styles.Header_navBackground,
							navigationIsOpen && styles.Header_navBackground___isOpen,
						)}
					/>

					{/* Not part of 1st delivery
						{basketIcon && (
							<div
								className={classNames(styles.Header_cart, navigationIsOpen && styles.Header_cart___isOpen)}
							>
								<ShoppingCart icon={basketIcon} />
							</div>
						)}

						{searchIcon && (
							<Search searchIcon={searchIcon} />
						)}
					*/}

					{userLinks?.length ? (
						<ProfileLinks
							style="desktop"
							profileLinks={
								session &&
								status !== 'unauthenticated' &&
								partyData?.error !== ErrorCodes.customerNotFound &&
								userLinks
							}
							profileIcon={userIcon}
							profileLinksIsOpen={(isOpen) => setProfileLinksIsOpen(isOpen)}
							profileLinksVisible={profileLinksIsOpen}
							logoutVisible={
								session &&
								status !== 'unauthenticated' &&
								partyData?.error === ErrorCodes.customerNotFound
							}
						/>
					) : null}
				</Grid>
			</header>
		</>
	);
};
