import { useContext, useEffect, useState } from 'react';
import { Modal, RichText } from 'Shared';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Actions } from 'Online/dictionaryItems';
import { useRouter } from 'next/router';
export interface PolicyModalProps {
	closeModal: () => void;
	policyTypeContent: PolicyTypeItem[];
	selectedPortfolioItem: PortfolioItem;
	state: PolicyDetailState;
}

export const PolicyModal: React.FC<PolicyModalProps> = ({
	closeModal,
	policyTypeContent,
	selectedPortfolioItem,
	state,
}) => {
	const { area } = state ?? {};
	const [policyTypeInfo, setPolicyTypeInfo] = useState<PolicyCoverageItem | PolicyObjectDetails>(null);
	const [policyContent, setPolicyContent] = useState(null);
	const [policyTypeItem, setPolicyTypeItem] = useState<PolicyTypeItem>(null);
	const router = useRouter();
	const dictionary = useContext(DictionaryContext);

	useEffect(() => {
		if (!policyTypeContent) return;
		const currentDetailType = policyTypeContent.filter((x) => {
			if (x.type === selectedPortfolioItem.type) return x;
		})[0];
		setPolicyTypeItem(currentDetailType);
	}, [policyTypeContent, selectedPortfolioItem]);

	useEffect(() => {
		if (area === 'object' && policyTypeItem) setPolicyContent(policyTypeItem.objectDetails);
		if (area === 'coverage' && policyTypeItem) setPolicyContent(policyTypeItem.coverages);
	}, [area, policyTypeItem]);

	useEffect(() => {
		if (!policyContent) return;
		if (area === 'object') setPolicyTypeInfo(matchRouterParameter(policyContent, router?.query?.object));
		if (area === 'coverage') setPolicyTypeInfo(matchRouterParameter(policyContent, router?.query?.coverage));
	}, [policyContent, area, router]);

	const matchRouterParameter = (policyContent, queryParameter): PolicyCoverageItem | PolicyObjectDetails => {
		return policyContent?.filter((detail) => {
			if (detail.id === queryParameter) {
				return detail;
			}
		})[0];
	};

	const handleImage = () => {
		if (!policyTypeInfo) return null;
		if ('image' in policyTypeInfo) {
			return (
				policyTypeInfo.image && {
					properties: policyTypeInfo.image?.properties,
					url: policyTypeInfo.image?.url,
				}
			);
		}
		return null;
	};

	return (
		<Modal
			title={policyTypeInfo?.title}
			titleImage={handleImage()}
			closeText={_Actions(dictionary, 'Close')}
			handleClose={closeModal}
			style={area !== 'addoncoverage' ? 'withoutForm' : null}
		>
			{policyTypeInfo?.description && <RichText content={policyTypeInfo.description} />}
		</Modal>
	);
};
