import Script from 'next/script';

export interface SleeknoteProps {
	sleeknoteUrl: string;
}

declare global {
	interface Window {
		sleeknoteSiteData: { TiaCustomerId: string }[];
	}
}

let sleekNoteLoaded = false;

export const Sleeknote: React.FC<SleeknoteProps> = (props) => {
	return (
		<>
			<Script
				strategy="afterInteractive"
				id="sleeknoteScript"
				src={props?.sleeknoteUrl}
				onLoad={() => {
					sleekNoteLoaded = true;
				}}
			/>
		</>
	);
};

export function dispatchSleeknoteCustomerId(idAlt: number): void {
	if (sleekNoteLoaded)
		try {
			window.sleeknoteSiteData || (window.sleeknoteSiteData = []);
			window.sleeknoteSiteData.push({ TiaCustomerId: idAlt.toString() });
		} catch (error) {
			console.log('Sleeknote - Could not track customer number', idAlt);
		}
}
