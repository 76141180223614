import classNames from 'classnames';
import { MasterCardCta } from 'Online/components';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Actions, _MasterCard, _Units } from 'Online/dictionaryItems';
import { useContext, useState } from 'react';
import { AttentionCard, Container, DescriptionList, DescriptionListProps, FactboxItemProps } from 'Shared';
import { MasterCardConditions } from './MasterCardConditions';
import styles from './MasterCardSummary.module.scss';

export interface MasterCardSummaryProps {
	callbackPrevious: () => void;
	callbackContinue: () => void;
	conditionsHeading: string;
	conditionsText: string;
	governmentInsurance: string;
	masterCardAttentionIcon?: Image;
	paymentProviders: FactboxItemProps[];
	priceDetailsList: DescriptionListProps;
	travelDetailsList: DescriptionListProps;
	userDetailsList: DescriptionListProps;
}

export const MasterCardSummary: React.FC<MasterCardSummaryProps> = ({
	callbackPrevious,
	callbackContinue,
	conditionsHeading,
	conditionsText,
	governmentInsurance,
	masterCardAttentionIcon,
	paymentProviders,
	priceDetailsList,
	travelDetailsList,
	userDetailsList,
}) => {
	const dictionary = useContext(DictionaryContext);
	const [conditionsAccepted, setConditionsAccepted] = useState(false);

	return (
		<div className={classNames(styles.MasterCardSummary)}>
			<Container width="Medium">
				{userDetailsList && (
					<DescriptionList className={styles.MasterCardSummary_topList} {...userDetailsList} />
				)}
				{travelDetailsList && <DescriptionList {...travelDetailsList} />}
				{priceDetailsList && <DescriptionList {...priceDetailsList} />}
			</Container>

			<AttentionCard
				className={styles.MasterCardSummary_attentioncard}
				text={
					_MasterCard(dictionary, 'PriceIncludesInsuranceToGovernment') +
					` ${governmentInsurance} ` +
					_Units(dictionary, 'DanishCrowns')
				}
				icon={masterCardAttentionIcon}
				settings={{ properties: { type: 'disclaimer', size: 'small' } }}
			/>
			<MasterCardConditions
				conditionsAccepted={(accepted) => setConditionsAccepted(accepted)}
				heading={conditionsHeading}
				text={conditionsText}
				paymentProviders={paymentProviders}
			/>

			<AttentionCard
				className={styles.MasterCardSummary_attentioncard}
				text={_MasterCard(dictionary, 'RightOfWithdrawalExpirationText')}
				icon={masterCardAttentionIcon}
				settings={{ properties: { type: 'disclaimer', size: 'medium' } }}
			/>
			<Container width="Medium">
				<p className={styles.MasterCardSummary_explainerText}>
					{_MasterCard(dictionary, 'RightOfWithdrawalExplainer')}
				</p>
				<MasterCardCta
					className={styles.MasterCardSummary_cta}
					callbackPrevious={callbackPrevious}
					callbackContinue={callbackContinue}
					continueText={_Actions(dictionary, 'GoToPayment')}
					continueDisabled={!conditionsAccepted}
				/>
			</Container>
		</div>
	);
};
